import FormComponent from '../../Form'
import Api from '../../Api'
import { ROLE_ROOT } from '../../../constants/roles'
import labels from '../../Common/translations'

export default {
   fields: {
      name: {
         label: labels.fields.name,
         mandatory: true,
         searchable: true,
      },
      createdBy: {
         label: labels.fields.created_by,
         accessor: s => s.createdBy.label,
         searchable: true,
      },
      company: {
         label: labels.fields.company,
         type: FormComponent.SELECT,
         values: {
            from: 'state',
            prop: 'companyValues',
            collection: 'companies',
            source: () => Api.selectValues('companies', { fsort: 'name' }),
         },
         return: FormComponent.FORMAT_OBJECT,
         mandatory: true,
         disabled: ({ authUser }) => authUser.role !== ROLE_ROOT,
         options: {
            defaultValue: ({ authUser }) =>
               authUser.role !== ROLE_ROOT ? authUser.company : '',
         },
      },
   },
   buttons: {
      cancel: true,
      extras: [],
   },
   actions: {
      read: uid => Api.instance().get(`environments/${uid}`),
   },
   extras: [],
}
