import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import {
   Container,
   Header,
   Button,
   Icon,
   Divider,
   Tab,
} from 'semantic-ui-react'
import { withFirebase } from '../Firebase'
import jobForm from './config/job-definition-form'
import { AutoLabel } from '../Common'
import { TabWidget } from '../Widgets'
import TableComponent from '../Table'
import labels from '../Common/translations'
import list from './config/jobs-list'
import { labelHelper } from '../Helpers'
import GridComponent from '../Grid'
import mediasGrid from './config/medias-grid'

class InspectionShowPage extends React.Component {
   constructor(props) {
      super(props)
      this.state = { open: false }
      this.goBack = this.goBack.bind(this)
      this.modalRef = React.createRef()
   }

   lb = new labelHelper(
      labels,
      this.props.authUser ? this.props.authUser.lang : labelHelper.lang
   )

   async componentDidMount() {
      this.setState({ loading: true })
      const { data: item } = await jobForm.actions.read(
         this.props.match.params.id
      )

      this.setState({
         item,
         loading: false,
      })
   }

   goBack() {
      this.props.history.goBack()
   }

   panes = [
      {
         key: 'jobs',
         menuItem: this.lb._(labels.screens.workflow),
         render: () => (
            <Tab.Pane key="jobs">
               <TableComponent
                  list={list}
                  baseRef={`inspections/${this.state.item.uid}`}
                  {...this.props}
               />
            </Tab.Pane>
         ),
      },
      {
         key: 'medias',
         menuItem: this.lb._(labels.screens.medias),
         render: () => (
            <Tab.Pane key="medias">
               {this.displayInpsectionContent(this.state.item)}
            </Tab.Pane>
         ),
      },
   ]

   displayInpsectionContent(insp) {
      switch (insp.mode) {
         case 'manual':
            return insp.medias ? (
               <GridComponent
                  list={mediasGrid}
                  {...this.props}
                  baseRef={`inspections/${this.state.item.uid}`}
               />
            ) : (
               <div>No medias</div>
            )

         default:
            return <div>Unsupported mode : {insp.mode}</div>
      }
   }

   render() {
      if (!this.state.item) {
         return <div />
      }

      const {
         item: { name },
      } = this.state

      return (
         <Container>
            <Container>
               <Header as="h1">{name}</Header>
               <Button primary icon labelPosition="left" onClick={this.goBack}>
                  <Icon name="left arrow" />
                  <AutoLabel labelKey="form.back" />
               </Button>
               <Divider />
               <Container>
                  <TabWidget panes={this.panes} />
               </Container>
            </Container>
         </Container>
      )
   }
}

export default compose(withRouter, withFirebase)(InspectionShowPage)
