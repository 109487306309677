import labels from '../Common/translations'
import * as ROUTES from '../../constants/routes'

export default {
   fields: {
      email: {
         label: {
            fr: "confirmer l'adresse email",
            en: 'Confirm the email address',
         },
         mandatory: true,
         options: {
            autoComplete: 'username',
         },
      },
   },
   buttons: {
      submit: {
         label: {
            fr: 'envoyer un email',
            en: 'Send email',
         },
         icon: 'sign in',
      },
      delete: false,
      cancel: true,
      extras: [],
   },
   actions: {
      redirect: ROUTES.USER,
   },
   extras: {
      mode: 'login',
      dimmerMessage: labels.form.password.reset,
   },
}
