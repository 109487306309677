import styled from 'styled-components'

const MainAppContainer = styled.div`
   max-width: 1127px;
   margin: 0 auto;
   margin-top: 50px;
   margin-bottom: 75px;

   .ui.container {
      width: 100%;
   }

   input::-webkit-outer-spin-button,
   input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
   }

   input[type='number'] {
      -moz-appearance: textfield;
   }
`
export default MainAppContainer
