import React from 'react'
import { withAuthentication } from '../Session'
import {
   Container,
   List,
   Icon,
   Button,
   Divider,
   Dimmer,
   Segment,
   Loader,
} from 'semantic-ui-react'
import Api from '../Api'
import { compose } from 'recompose'
import { AutoHeader, AutoLabel } from '../Common'
import { dlLink } from '../Helpers/downloadUrl'
import TableComponent from '../Table'
import invitationsList from './config/invitations-list'

class HomePage extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         items: null,
         invitations: null,
         loading: true,
      }
   }

   componentDidUpdate() {
      if (this.props.authUser && this.state.items === null) {
         this.getData()
      }
   }

   static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.authUser !== prevState.authUser) {
         return { authUser: nextProps.authUser }
      }

      return null
   }

   getData = async () => {
      const queryParams = {
         fsort: 'createdAt',
         osort: 'desc',
         q: {
            accessible: true,
            status: ['active'],
         },
      }
      Api.instance()
         .get('downloads', queryParams)
         .then(({ data: items }) =>
            this.setState({
               items,
               loading: false,
            })
         )
   }

   render() {
      if (this.state.loading === true) {
         return (
            <Segment>
               <Dimmer active inverted>
                  <Loader size="large">Activation de l'API en cours...</Loader>
               </Dimmer>
            </Segment>
         )
      }

      const { items } = this.state

      return (
         <Container>
            <TableComponent
               list={invitationsList}
               title={
                  <AutoLabel labelKey="screens.onboarding.pending_invites" />
               }
               pagination={false}
               compact={true}
               hideIfEmpty={true}
               {...this.props}
            />

            <AutoHeader labelKey="home_welcome" />

            <Container>
               <Divider />
               <List animated divided celled verticalAlign="middle">
                  {Array.isArray(items) &&
                     items.map((file, uid) => (
                        <List.Item key={uid}>
                           <List.Content floated="right" verticalAlign="middle">
                              <Button
                                 primary
                                 icon
                                 labelPosition="right"
                                 onClick={() =>
                                    dlLink(file.file, `downloads/${file.uid}`)
                                 }
                              >
                                 <Icon name="download" />
                                 <AutoLabel labelKey="form.download" />
                              </Button>
                           </List.Content>
                           <Icon name="file archive outline" size="huge" />
                           <List.Content>{file.name}</List.Content>
                        </List.Item>
                     ))}
               </List>
            </Container>
         </Container>
      )
   }
}

export default compose(withAuthentication)(HomePage)
