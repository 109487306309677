import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { compose } from 'recompose'
import * as ROUTES from '../../constants/routes'
import {
   Container,
   Embed,
   Header,
   Button,
   Icon,
   Divider,
   Modal,
   Tab,
} from 'semantic-ui-react'
import { ref as RefFireBase, getDownloadURL } from 'firebase/storage'
import { download, labelHelper } from '../Helpers'
import { withFirebase } from '../Firebase'
import TableComponent from '../Table'
import scenesList from './config/video-scenes-list'
import videoForm from './config/video-form'
import { AutoLabel } from '../Common'
import { BaaS } from '../..'
import { Storage } from '../Common/OnPremise'
import labels from '../Common/translations'
import dlstatsList from '../Common/dlstats/list-dlstats'
import { TabWidget } from '../Widgets'

// import Api from '../Api'

class VideoShowPage extends React.Component {
   constructor(props) {
      super(props)
      this.state = { open: false }
      this.goBack = this.goBack.bind(this)
      this.modalRef = React.createRef()
   }

   lb = new labelHelper(
      labels,
      this.props.authUser ? this.props.authUser.lang : labelHelper.lang
   )
   async componentDidMount() {
      this.setState({ loading: true })
      let url
      const { data: item } = await videoForm.actions.read(
         this.props.match.params.id
      )

      if (BaaS === 'firebase') {
         const ref = RefFireBase(
            this.props.firebase.storage,
            item.lowFile ? item.lowFile.ref : item.file.ref
         )
         url = await getDownloadURL(ref)
      } else {
         const storage = new Storage()
         url = await storage.ref(item.file.ref).getDownloadURL()
      }

      const newState = {
         item,
         url,
         loading: false,
      }

      if (item.deleteInsv !== undefined && item.deleteInsv === false) {
         this.setState({
            ...newState,
            downloadInsv: true,
            insv1Ref: item.insv1,
            insv2Ref: item.insv2,
         })
      } else {
         this.setState({ ...newState, downloadInsv: false })
      }
   }

   goBack() {
      this.props.history.goBack()
   }

   panes = [
      {
         key: 'event',
         menuItem: this.lb._(labels.fields.video),
         render: () => (
            <Tab.Pane key="event">
               <TableComponent
                  title={<AutoLabel labelKey="screens.video360_show.scenes" />}
                  list={scenesList}
                  baseRef={`videos/${this.state.item.uid}`}
                  parent={this} // give access to page context so button can change state and display modal
                  {...this.props}
               />
            </Tab.Pane>
         ),
      },
      {
         key: 'dlstats',
         menuItem: this.lb._(labels.fields.dlstats),
         render: () => (
            <Tab.Pane key="dlstats">
               <TableComponent
                  title={
                     <AutoLabel labelKey="screens.environment_show.dlstats" />
                  }
                  list={dlstatsList}
                  baseRef={`videos360/${this.state.item.uid}`}

                  {...this.props}
                  pagination={false}

               />
            </Tab.Pane>
         ),
      },
   ]

   render() {
      if (!this.state.item) {
         return <div />
      }

      const {
         item: { name, uid },
         url,
         downloadInsv,
      } = this.state

      return (
         <Container>
            <Container>
               <Header as="h1">{name}</Header>
               <Button primary icon labelPosition="left" onClick={this.goBack}>
                  <Icon name="left arrow" />
                  <AutoLabel labelKey="form.back" />
               </Button>
               <Modal
                  trigger={
                     <Button positive icon labelPosition="left">
                        <Icon name="video play" />
                        <AutoLabel labelKey="screens.video360_show.watch" />
                     </Button>
                  }
               >
                  <Divider />
                  <Modal.Header>{name}</Modal.Header>
                  <Modal.Content>
                     <Embed icon="right circle arrow" url={url} />
                     <Modal.Description></Modal.Description>
                  </Modal.Content>
               </Modal>
               <Link to={ROUTES.VIDEO_VECTO.replace(':id', uid)}>
                  <Button primary icon labelPosition="left">
                     <Icon name="microchip" />
                     Vecto
                  </Button>
               </Link>
               {downloadInsv && (
                  <span>
                     <Button
                        floated="right"
                        primary
                        icon
                        labelPosition="left"
                        onClick={() => download(this.state.insv2Ref)}
                     >
                        <Icon name="download" />
                        <AutoLabel labelKey="screens.downloads" /> insv2
                     </Button>
                     <Button
                        floated="right"
                        primary
                        icon
                        labelPosition="left"
                        onClick={() => download(this.state.insv1Ref)}
                     >
                        <Icon name="download" />
                        <AutoLabel labelKey="screens.downloads" /> insv1
                     </Button>
                  </span>
               )}
               <Divider />
               <Container>
                  <Modal
                     open={this.state.open}
                     ref={this.modalRef}
                     closeIcon
                     content={this.state.content}
                     onClose={() => this.setState({ open: false })}
                     closeOnEscape={false}
                     header="Access List Management"
                  />

                  <TabWidget panes={this.panes} />
               </Container>
            </Container>
         </Container>
      )
   }
}

export default compose(withRouter, withFirebase)(VideoShowPage)
