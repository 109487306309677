import FormComponent from '../../Form'
import microsoftAuth from '../microsoft'
import labels from '../../Common/translations'

export default {
   fields: {
      email: {
         label: labels.fields.login,
         mandatory: true,
         options: {
            autoComplete: 'username',
         },
      },
      password: {
         label: labels.fields.password,
         type: FormComponent.PASSWORD,
         mandatory: true,
         login: true,
         options: {
            autoComplete: 'current-password',
         },
      },
   },
   buttons: {
      submit: { label: labels.fields.log_in, icon: 'sign in' },
      delete: false,
      cancel: false,
      extras: [
         {
            label: labels.fields.microsoft_login,
            options: {
               icon: 'microsoft',
               positive: true,
            },
            callback: microsoftAuth,
            hidden: ({ firebase }) =>
               firebase.config('enable_microsoft_auth').asBoolean() !== true,
         },
      ],
   },
   actions: {},
   extras: {
      mode: 'login',
      dimmerMessage: labels.notices.login,
   },
}
