import React from 'react'
import { withAuthentication, withAuthorization } from '../Session'
import { compose } from 'recompose'
import { ROUTES } from '../../constants'
import { Container, Grid, Icon, Menu, Tab } from 'semantic-ui-react'
import TableComponent from '../Table'
import downloadsList from '../Downloads/config/downloads-list'
import templatesList from '../Templates/config/templates-list'
import { AutoHeader, AutoLabel, TranslatedButton } from '../Common'
import SearchComponent from '../Form/search'
import search from './config/search'
import labels from '../Common/translations'

import sessionsList from './config/sessions-list'
import companiesDlStatsSearch from './config/companies-dl-stats-search'

import companiesDlStatsList from './config/companies-dl-stats'

import usersDlStatsSearch from './config/users-dl-stats-search'

import SearchableList from '../Common/searchableList'
import { labelHelper } from '../Helpers'
import { TabWidget } from '../Widgets'
import UserDlStasListTable from './config/users-dl-stats'

class AdministrationMenu extends SearchableList {
   state = {
      activeItem:
         sessionStorage.getItem('activeSubItemNavigation') || 'downloads',
      loading: false,
   }

   lb = new labelHelper(
      labels,
      this.props.authUser ? this.props.authUser.lang : labelHelper.lang
   )
   componentDidMount() {
      this.setState({ loading: true })
   }

   handleItemClick = (e: any, data: any) => {
      this.setState({ activeItem: data.name })
      sessionStorage.setItem('activeSubItemNavigation', data.name)
   }

   panes = [
      {
         key: 'CompaniesDlstats',
         menuItem: this.lb._(labels.fields.company),
         render: () => (
            <Tab.Pane key="CompaniesDlstats">
               <Container>
                  <SearchComponent
                     form={companiesDlStatsSearch}
                     data={this.filters()}
                     {...this.props}
                     propagate={(filters: any) => {
                        this.filters(filters)
                     }}
                  />
                  <TableComponent
                     list={companiesDlStatsList}
                     {...this.props}
                     filters={this.filters()}
                     pagination={false}
                  />
               </Container>
            </Tab.Pane>
         ),
      },
      {
         key: 'UsersDlstats',
         menuItem: this.lb._(labels.fields.user),
         render: () => (
            <Tab.Pane key="CompanUsersDlstatsiesdlstats">
               <Container>
                  <SearchComponent
                     form={usersDlStatsSearch}
                     data={this.filters()}
                     {...this.props}
                     propagate={(filters: any) => {
                        this.filters(filters)
                     }}
                  />
                  <UserDlStasListTable
                     {...this.props}
                     filters={this.filters()}
                  />
               </Container>
            </Tab.Pane>
         ),
      },
   ]

   render() {
      const { activeItem } = this.state

      return (
         <Container>
            <Container>
               <Grid columns="equal">
                  <Grid.Column>
                     <Menu vertical inverted color="grey">
                        <Menu.Item
                           name="downloads"
                           active={activeItem === 'downloads'}
                           onClick={this.handleItemClick}
                        >
                           <Icon name="cloud download" />
                           <AutoLabel labelKey="screens.downloads" />
                        </Menu.Item>

                        <Menu.Item
                           name="templates"
                           active={activeItem === 'templates'}
                           onClick={this.handleItemClick}
                        >
                           <Icon name="file alternate outline" />
                           <AutoLabel labelKey="screens.templates" />
                        </Menu.Item>
                        <Menu.Item
                           name="sessions"
                           active={activeItem === 'sessions'}
                           onClick={this.handleItemClick}
                        >
                           <Icon name="address card outline" />
                           <AutoLabel labelKey="screens.sessions" />
                        </Menu.Item>
                        <Menu.Item
                           name="dl_stats"
                           active={activeItem === 'dl_stats'}
                           onClick={this.handleItemClick}
                        >
                           <Icon name="address card outline" />
                           <AutoLabel labelKey="dlstats.title" />
                        </Menu.Item>
                     </Menu>
                  </Grid.Column>
                  <Grid.Column>
                     <AutoHeader />
                  </Grid.Column>
                  <Grid.Column textAlign="right"></Grid.Column>
               </Grid>
            </Container>
            {activeItem === 'downloads' && (
               <Container>
                  <Grid>
                     <Grid.Column textAlign="right">
                        <TranslatedButton
                           link={ROUTES.DOWNLOAD_CREATE}
                           icon="file"
                           label="screens.downloads_create"
                        />
                     </Grid.Column>
                  </Grid>
                  <TableComponent list={downloadsList} {...this.props} />
               </Container>
            )}
            {activeItem === 'templates' && (
               <Container>
                  <Grid>
                     <Grid.Column textAlign="right">
                        <TranslatedButton
                           link={ROUTES.TEMPLATES_CREATE}
                           icon="file alternate outline"
                           label="screens.templates_create"
                        />
                     </Grid.Column>
                  </Grid>
                  <TableComponent list={templatesList} {...this.props} />
               </Container>
            )}
            {activeItem === 'sessions' && (
               <Container>
                  <Container>
                     <AutoHeader />
                  </Container>
                  <SearchComponent
                     form={search}
                     data={this.filters()}
                     {...this.props}
                     propagate={(filters: any) => this.filters(filters)}
                  />
                  <TableComponent
                     list={sessionsList}
                     {...this.props}
                     filters={this.filters()}
                  />
               </Container>
            )}

            {activeItem === 'dl_stats' && <TabWidget panes={this.panes} />}
         </Container>
      )
   }
}

//export default compose(withAuthentication)(AdministrationPage)

const condition = (authUser: any) => authUser // && !!authUser.roles[ROLES.ADMIN]

const AdministrationList = withAuthentication(AdministrationMenu)

export default compose(withAuthorization(condition))(AdministrationList)
