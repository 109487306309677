import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { Container, Modal, Grid, Divider } from 'semantic-ui-react'
import companyForm from './config/company-form'
import { withAuthentication } from '../Session'
import { AutoHeader, AutoLabel, TranslatedButton } from '../Common'
import TableComponent from '../Table'
import companyGroupsList from './config/group/list'
import { ROUTES } from '../../constants'

/**
 * Group list page for root & partner
 * (bound company is the selected one on previous screen)
 */
class CompanyGroupsPage extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         loading: true,
         item: null,
         open: false,
         modalHeader: '',
      }
      this.baseRef = `companies/${this.props.match.params.id}`
   }

   async componentDidMount() {
      const { data: item } = await companyForm.actions.read(this.baseRef)
      this.setState({
         item,
         loading: false,
      })
   }

   // @fix auto header label key detection
   render() {
      if (this.state.loading === true) {
         return <div />
      }

      return (
         <Container>
            <Grid columns="equal">
               <Grid.Column>
                  <AutoHeader labelKey="company_groups" />
               </Grid.Column>
               <Grid.Column></Grid.Column>
               <Grid.Column textAlign="right">
                  <TranslatedButton
                     link={ROUTES.COMPANY_GROUP_CREATE.replace(
                        ':id',
                        this.state.item.uid
                     )}
                     icon="group"
                     label="screens.company_group_create"
                  />
               </Grid.Column>
            </Grid>
            <Divider hidden />
            <Container>
               <Modal
                  open={this.state.open}
                  ref={this.modalRef}
                  closeIcon
                  content={this.state.content}
                  onClose={() => this.setState({ open: false })}
                  closeOnEscape={false}
                  header={this.state.modalHeader}
               />
               <TableComponent
                  title="labels.fields.groups"
                  list={companyGroupsList}
                  baseRef={this.baseRef}
                  company={this.state.item}
                  parent={this}
                  modalHeader={<AutoLabel labelKey="screens.guests_invite" />}
                  {...this.props}
               />
            </Container>
         </Container>
      )
   }
}

export default compose(withRouter, withAuthentication)(CompanyGroupsPage)
