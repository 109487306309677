import React from 'react'
import labels from './translations'
import { labelHelper } from '../Helpers'
import { Message } from 'semantic-ui-react'
import { withAuthentication } from '../Session'

class MandatoryWarning extends React.Component {
   constructor(props) {
      super(props)
      this.props = props
      this.lb = new labelHelper(
         labels,
         this.props.authUser ? this.props.authUser.lang : labelHelper.lang,
      )
   }

   render() {
      return (
         <Message
            warning
            icon="warning circle"
            header={this.lb._('form.warnings.required_param_header')}
            content={this.lb._('form.warnings.required_param_content')}
         />
      )
   }
}

export default withAuthentication(MandatoryWarning)
