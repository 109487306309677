import FormComponent from '../../Form'
import Api from '../../Api'
import labels from '../../Common/translations'

export default {
   fields: {
      core: {
         type: FormComponent.GROUP,
         fields: {
            user: {
               label: labels.fields.user,
            },
            company: {
               label: labels.fields.company,
            },
            role: {
               label: labels.fields.role,
               type: FormComponent.SELECT,
               values: {
                  from: 'state',
                  prop: 'roleValues',
                  collection: 'roles',
                  source: ({ authUser }) =>
                     Api.selectValues('roles', {
                        fsort: 'order',
                        q: {
                           access: [authUser.role, 'array-contains'],
                        },
                     }),
               },
            },
         },
      },
      times: {
         type: FormComponent.GROUP,
         fields: {
            createdAt: {
               label: labels.fields.start,
               type: FormComponent.DATE,
            },
            endedAt: {
               label: labels.fields.end,
               type: FormComponent.DATE,
            },
            duration: {
               label: labels.fields.duration,
               type: FormComponent.TIME,
            },
         },
      },
      params: {
         type: FormComponent.GROUP,
         fields: {
            offlineRequested: {
               label: labels.fields.offline_data_requested,
               type: FormComponent.CHECKBOX,
               options: {
                  defaultValue: false,
               },
            },
            offlineGranted: {
               label: labels.fields.offline_data_transmitted,
               type: FormComponent.CHECKBOX,
               options: {
                  defaultValue: false,
               },
            },
         },
      },
      apps: {
         type: FormComponent.GROUP,
         fields: {
            agent: {
               label: labels.fields.client,
               //formatter: val => userAgentFormat(val),
            },
            unityVersion: {
               label: labels.fields.unity_version,
            },
            buildVesion: {
               label: labels.fields.totalymage_version,
            },
         },
      },
      hw: {
         type: FormComponent.GROUP,
         fields: {
            deviceModel: {
               label: labels.fields.device,
            },
            operatingSystem: {
               label: labels.fields.operating_system,
            },
            systemMemSize: {
               label: labels.fields.ram,
               formatter: val => `${Math.round(val / 1024)} GB`,
            },
         },
      },
      location: {
         type: FormComponent.GROUP,
         fields: {
            city: {
               label: labels.fields.city,
            },
            country: {
               label: labels.fields.country,
            },
         },
      },
   },
   buttons: {
      submit: true,
      delete: true,
      cancel: true,
      extras: [],
   },
   actions: {
      read: uid =>
         Api.instance()
            .get(`sessions/${uid}`)
            .then(res => res.data),
      update: ({ uid, ...data }) =>
         Api.instance().patch('sessions/' + uid, data),
      delete: ({ uid }) => Api.instance().delete('sessions/' + uid),
   },
   extras: [],
}
