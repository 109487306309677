import React from 'react'
import { Tab } from 'semantic-ui-react'

export default class TabWidget extends React.Component {
   constructor(props) {
      super(props)
      this.state = { currentTab: null }
      this.panes = this.props.panes
   }

   componentDidMount() {
      this.setState({ currentTab: this.getInitialHash() })
   }

   getInitialHash = () => {
      if (!document.location.hash) {
         return 0
      }
      let locationHash = document.location.hash.split('#')
      let initialHash = null
      let index = 0
      this.panes.forEach(pane => {
         if (locationHash[1] === pane.key) {
            initialHash = index
         }
         ++index
      })
      if (!initialHash) {
         return 0
      }
      return initialHash
   }

   tabChangeHandler = (event, data) => {
      let url = document.location.toString()
      let hash = document.location.hash.toString()
      if (!hash) {
         url = url + `#${this.panes[data.activeIndex].key}`
      } else {
         url = url.replace(hash, `#${this.panes[data.activeIndex].key}`)
      }
      document.location.replace(url)
   }

   render() {
      if (this.state.currentTab === null) {
         return <div></div>
      }

      return (
         <Tab
            defaultActiveIndex={
               this.state.currentTab ? this.state.currentTab : 0
            }
            onTabChange={this.tabChangeHandler}
            panes={this.panes}
         />
      )
   }
}
