import React from 'react'
import { withRouter } from 'react-router-dom'
import { withAuthentication } from '../Session'
import { compose } from 'recompose'
import FormComponent from '../Form'
import { Container } from 'semantic-ui-react'
import videoForm from './config/video-form'
import { AutoHeader } from '../Common'

class VideoCreatePage extends React.Component {
   render() {
      return (
         <Container>
            <AutoHeader />
            <Container>
               <FormComponent form={videoForm} {...this.props} />
            </Container>
         </Container>
      )
   }
}

export default compose(withRouter, withAuthentication)(VideoCreatePage)
