import Api from '../../Api'
import labels from '../../Common/translations'
import { dateFormat, labelFormat } from '../../Helpers'
import vectoStatus from '../../../models/vecto-status'

export default {
   endpoint: 'inspections/*/jobs',
   pid: 'inspection.ref',
   pagesize: 10,
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: {
      name: {
         label: labels.fields.name,
         accessor: s => `${s.name}`,
      },
      workflow: {
         label: labels.fields.workflow,
         accessor: s => `${s.workflow ? s.workflow.label : ''}`,
      },
      environment: {
         label: labels.fields.environment,
         accessor: s => `${s.environment ? s.environment.label : ''}`,
      },
      version: {
         label: labels.fields.version,
         accessor: s => `${s.version ? s.version.label : ''}`,
      },
      status: {
         label: labels.fields.status,
         accessor: s => labelFormat(s.status, vectoStatus),
      },
      createdAt: {
         label: labels.fields.created_at,
         format: row => dateFormat(row.createdAt),
         sortable: true,
         options: {
            maxWidth: '150px',
         },
      },
   },
   buttons: {
      show: {
         label: labels.fields.trigger,
         action: (data, props) =>
            Api.instance().patch(`${props.baseRef}/jobs/${data.uid}/trigger`),
         options: {
            icon: 'cog',
            negative: true,
         },
         disabled: data => data.pending === true,
      },
   },
   actions: {
      list: (params, props) =>
         Api.instance().get(`${props.baseRef}/jobs`, params),
   },
}
