import React from 'react'
import FormComponent from '../index'
import { labelHelper } from '../../Helpers'
import labels from '../../Common/translations'

export default class AbstractField extends React.Component {
   constructor(props) {
      super(props)
      this.props = props

      this.state = {
         dimmerType: FormComponent.DIMMER_TYPE_LOADER,
         dimmerMessage: FormComponent.DEFAULT_DIMMER_MESSAGE,
         error: null,
         errors: {},
         observers: [],
      }
      this.lb = new labelHelper(labels, this.props.lang)
   }
}
