import FormComponent from '../../Form'
import Api from '../../Api'
import labels from '../../Common/translations'

export default {
   fields: {
      core: {
         type: FormComponent.GROUP,
         fields: {
            name: {
               label: labels.fields.name,
               mandatory: true,
               searchable: true,
            },
            inspection: {
               label: labels.fields.inspection,
               mandatory: true,
               disabled: true,
               options: {
                  defaultValue: ({ inspection }) => ({
                     ref: `inspections/${inspection.uid}`,
                     label: inspection.name,
                  }),

                  tabIndex: -1,
               },
            },
            company: {
               label: labels.fields.company,
               mandatory: true,
               disabled: true,
               options: {
                  defaultValue: (props, state) =>
                     props.inspection.company || state.inspection.company,
                  tabIndex: -1,
               },
            },
         },
      },
      job: {
         type: FormComponent.GROUP,
         fields: {
            workflow: {
               label: labels.fields.workflow,
               type: FormComponent.SELECT,
               values: {
                  from: 'state',
                  prop: 'workflowValues',
                  collection: 'workflows',
                  source: () =>
                     Api.selectValues('workflows', { fsort: 'name' }),
               },
               return: FormComponent.FORMAT_OBJECT,
               mandatory: true,
            },
         },
      },
      target: {
         type: FormComponent.GROUP,
         fields: {
            newEnv: {
               label: labels.fields.new_environment,
               type: FormComponent.CHECKBOX,
            },
            newEnvName: {
               label: labels.fields.name,
               searchable: true,
               hidden: (_, state) => state.data.newEnv !== true,
               mandatory: (_, state) => state.data.newEnv === true,
            },
            environment: {
               label: labels.fields.environment,
               type: FormComponent.SELECT,
               values: {
                  from: 'state',
                  prop: 'environmentValues',
                  collection: 'environments',
                  source: () =>
                     Api.selectValues('environments', { fsort: 'name' }),
               },
               return: FormComponent.FORMAT_OBJECT,
               mandatory: (_, state) => state.data.newEnv !== true,
               hidden: (_, state) => state.data.newEnv === true,
            },
            version: {
               label: labels.fields.version,
               type: FormComponent.SELECT,
               values: {
                  from: 'state',
                  prop: 'versionValues',
                  source: () => Api.selectValues('versions', { fsort: 'name' }),
               },
               return: FormComponent.FORMAT_OBJECT,
               mandatory: (_, state) => state.data.newEnv !== true,
               hidden: (_, state) => state.data.newEnv === true,
               disabled: (_, state) =>
                  !state.data.environment ||
                  state.data.environment.ref === undefined,
               observers: [
                  {
                     /**
                      * This observer loads the versions list of the active environment
                      * acquired from the 'environment' field
                      */
                     observable: 'environment',
                     action: async data => {
                        if (!data.environment || !data.environment.ref) {
                           return { versionValues: [] }
                        } else {
                           const res = await Api.instance().get(
                              `${data.environment.ref}/versions`,
                              {
                                 fsort: 'createdAt',
                                 osort: 'desc',
                                 batch: 1000,
                              }
                           )
                           return {
                              versionValues: Object.keys(res.data).map(key => ({
                                 key: `${data.environment.ref}/members/${res.data[key].uid}`,
                                 label: `${res.data[key].createdBy.label} (v${res.data[key].appVersion})`,
                              })),
                           }
                        }
                     },
                  },
               ],
            },
         },
      },
   },
   buttons: {
      cancel: true,
      submit: true,
      extras: [],
   },
   actions: {
      create: data => {
         return Api.instance().post(`${data.inspection.ref}/jobs`, data)
      },
      read: uid => Api.instance().get(`inspections/${uid}`),
   },
   extras: [],
}
