import React from 'react'
import labels from './translations'
import { labelHelper } from '../Helpers'
import { Button, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { withAuthentication } from '../Session'

class TranslatedButton extends React.Component {
   constructor(props) {
      super(props)
      this.lb = new labelHelper(labels, this.props.authUser.lang)
   }

   render() {
      const { label, icon, disabled } = this.props
      let button = (
         <Button primary icon labelPosition="left" disabled={disabled}>
            <Icon name={icon} />
            {this.lb._(label)}
         </Button>
      )
      if (disabled !== true) {
         button = <Link to={this.props.link}>{button}</Link>
      }
      return button
   }
}

export default withAuthentication(TranslatedButton)
