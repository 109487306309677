import { dateFormat, dureeFormat } from '../../Helpers'
import * as ROUTES from '../../../constants/routes'
import Api from '../../Api'
import labels from '../../Common/translations'

export default {
   endpoint: 'sessions',
   pagesize: 15,
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: {
      user: {
         label: labels.fields.user,
         accessor: s => s.user.label,
      },
      debut: {
         label: labels.fields.debut,
         accessor: row => dateFormat(row.createdAt),
      },
      duration: {
         label: labels.fields.duration,
         accessor: row => dureeFormat(row.duration),
      },
   },
   buttons: {
      show: {
         label: labels.fields.show,
         url: ROUTES.SESSIONS_SHOW,
         options: {
            icon: 'eye',
            positive: true,
         },
      },
   },
   actions: {
      list: (params, { authUser }) => {
         params.q = params.q || {}
         if (authUser.role !== 'root') {
            params.q.company = [authUser.company.ref, '==', 'ref']
         }
         return Api.instance().get('sessions', params)
      },
   },
}
