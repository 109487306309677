import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import FormComponent from '../Form'
import {
   Container,
   Grid,
   Button,
   Icon,
   Confirm,
   Segment,
   Dropdown,
} from 'semantic-ui-react'
import userRecord from './config/user-record'
import { withAuthentication } from '../Session'
import { AutoHeader, AutoLabel } from '../Common'
import * as ROUTES from '../../constants/routes'
import { labelHelper } from '../Helpers'
import labels from '../Common/translations'
import Api from '../Api'
import styled from 'styled-components'

const StyledForm = styled.div`
   .dropdown {
      margin-left: 3%;
      width: 50%;
   }
`

class UserRecordPage extends React.Component {
   state = {
      openClearLocalConfirm: false,
      openClearSessionStorage: false,
      openKeywordsRefreshConfirm: false,
      openResetCounter: false,
      dropdownKeywordsOptions: [],
      dropdownCounterOptions: [],
      value: '',
   }

   lb = new labelHelper(
      labels,
      this.props.authUser ? this.props.authUser.lang : labelHelper.lang,
   )

   async componentDidMount() {
      await this.keywordsCollection()
      await this.counterCollection()
   }

   clickClearLocalStorage = () => {
      this.setState({ openClearLocalConfirm: true })
   }

   clickClearSessionStorage = () => {
      this.setState({ openClearSessionStorage: true })
   }

   clickKeywordsRefresh = () => {
      this.setState({ openKeywordsRefreshConfirm: true })
   }

   clickResetCounter = () => {
      this.setState({ openResetCounter: true })
   }

   localCancel = () => {
      this.setState({ openClearLocalConfirm: false })
   }

   sessionCancel = () => {
      this.setState({ openClearSessionStorage: false })
   }

   keywordsCancel = () => {
      this.setState({ openKeywordsRefreshConfirm: false, value: '' })
   }

   resetCounterCancel = () => {
      this.setState({ openResetCounter: false })
   }

   localConfirmation = () => {
      this.setState({ openClearLocalConfirm: false })
      localStorage.clear()
      sessionStorage.clear()
      this.props.history.push(ROUTES.SIGN_OUT)
   }

   sessionConfirmation = () => {
      this.setState({ openClearSessionStorage: false })
      sessionStorage.clear()
   }

   keywordsConfirmation = () => {
      this.setState({ openKeywordsRefreshConfirm: false })
      Api.instance().get('/utils/keywords/' + this.state.value)
      this.setState({ value: '' })
   }

   resetCounterConfirmation = async () => {
      this.setState({ openResetCounter: false })
      await Api.instance().get(`/utils/resetcounter/${this.state.value}`)
      this.setState({ value: '' })
   }

   dropdownChange = (e, { value }) => {
      this.setState({ value })
   }

   keywordsCollection = async () => {
      const { data } = await Api.instance().get('/utils/keywords')
      let collections = Object.keys(data)
      collections.splice(collections.indexOf('uid'), 1)

      let dropdownKeywordsOptions = []

      let i = 1

      collections.forEach(collection => {
         dropdownKeywordsOptions.push({
            key: i,
            text: collection,
            value: collection,
         })
         ++i
      })

      this.setState({ dropdownKeywordsOptions: dropdownKeywordsOptions })
   }

   counterCollection = async () => {
      const { data } = await Api.instance().get('/utils/resetcounter')

      let collections = Object.keys(data)
      collections.splice(collections.indexOf('uid'), 1)

      let dropdownCounterOptions = []

      let i = 1

      collections.forEach(collection => {
         dropdownCounterOptions.push({
            key: i,
            text: collection,
            value: collection,
         })
         ++i
      })

      this.setState({ dropdownCounterOptions: dropdownCounterOptions })
   }

   renderDropdown = () => {
      const {
         value,
         dropdownKeywordsOptions,
         dropdownCounterOptions,
      } = this.state
      let dropdownOptions = []

      if (this.state.openKeywordsRefreshConfirm === true) {
         dropdownOptions = dropdownKeywordsOptions
      } else if (this.state.openResetCounter === true) {
         dropdownOptions = dropdownCounterOptions
      }

      return (
         <StyledForm>
            <Dropdown
               className="dropdown"
               options={dropdownOptions.map(collection => {
                  return {
                     key: collection.key,
                     text: collection.text,
                     value: collection.value,
                  }
               })}
               placeholder={this.lb._(
                  'screens.user_keywords_selection_collection',
               )}
               selection
               value={value}
               onChange={this.dropdownChange}
            ></Dropdown>
         </StyledForm>
      )
   }

   renderRootButton = () => {
      return (
         <Segment>
            <Grid>
               <Grid.Column width={8}>
                  <Button
                     icon
                     labelPosition="left"
                     positive
                     size="large"
                     onClick={this.clickKeywordsRefresh}
                  >
                     <Icon name="refresh" />
                     <AutoLabel labelKey="screens.user_keywords_refresh" />
                  </Button>
                  <Confirm
                     open={this.state.openKeywordsRefreshConfirm}
                     header={this.lb._('keywords_refresh.header')}
                     content={this.renderDropdown}
                     cancelButton={this.lb._('form.cancel')}
                     confirmButton={this.lb._('form.confirm')}
                     onCancel={this.keywordsCancel}
                     onConfirm={this.keywordsConfirmation}
                  ></Confirm>
               </Grid.Column>
               <Grid.Column width={8}>
                  <Button
                     icon
                     labelPosition="left"
                     positive
                     size="large"
                     onClick={this.clickResetCounter}
                  >
                     <Icon name="refresh" />
                     <AutoLabel labelKey="screens.user_reset_counter" />
                  </Button>
                  <Confirm
                     open={this.state.openResetCounter}
                     header={this.lb._('reset_counter.header')}
                     content={this.renderDropdown}
                     cancelButton={this.lb._('form.cancel')}
                     confirmButton={this.lb._('form.confirm')}
                     onCancel={this.resetCounterCancel}
                     onConfirm={this.resetCounterConfirmation}
                  ></Confirm>
               </Grid.Column>
            </Grid>
         </Segment>
      )
   }

   render() {
      return (
         <Container>
            <AutoHeader />
            <FormComponent
               form={userRecord}
               data={this.props.authUser}
               {...this.props}
            />
            <Container className="segment">
               <Grid>
                  <Grid.Column width={8}>
                     <i></i>
                     <Button
                        icon
                        labelPosition="left"
                        onClick={this.clickClearSessionStorage}
                        size="large"
                        positive
                     >
                        <Icon name="trash alternate" />
                        <AutoLabel labelKey="screens.user_clear_session_storage" />
                     </Button>
                     <Confirm
                        open={this.state.openClearSessionStorage}
                        header={this.lb._('clear_session_storage.header')}
                        content={this.lb._('clear_session_storage.content')}
                        cancelButton={this.lb._('form.cancel')}
                        confirmButton={this.lb._('form.confirm')}
                        onCancel={this.sessionCancel}
                        onConfirm={this.sessionConfirmation}
                     />
                  </Grid.Column>
                  <Grid.Column width={8}>
                     <Button
                        icon
                        labelPosition="left"
                        onClick={this.clickClearLocalStorage}
                        size="large"
                        negative
                     >
                        <Icon name="trash alternate" />
                        <AutoLabel labelKey="screens.user_clear_local_storage" />
                     </Button>
                     <Confirm
                        open={this.state.openClearLocalConfirm}
                        header={this.lb._('clear_local_storage.header')}
                        content={this.lb._('clear_local_storage.content')}
                        cancelButton={this.lb._('form.cancel')}
                        confirmButton={this.lb._('form.confirm')}
                        onCancel={this.localCancel}
                        onConfirm={this.localConfirmation}
                     />
                  </Grid.Column>
               </Grid>
            </Container>
            {this.props.authUser.role === 'root'
               ? this.renderRootButton()
               : null}
         </Container>
      )
   }
}

export default compose(withRouter, withAuthentication)(UserRecordPage)
