import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import FormComponent from '../Form'
import { Container, Button, Icon, Divider } from 'semantic-ui-react'
import { AutoHeader, EventsList } from '../Common'
import downloadForm from './config/download-form'

class DownloadShowPage extends FormComponent {
   constructor(props) {
      super(props)
      this.state = { item: undefined, loading: true }
      this.goBack = this.goBack.bind(this)
   }

   goBack() {
      this.props.history.goBack()
   }

   componentDidMount() {
      downloadForm.actions.read(this.props.match.params.id).then(res => {
         this.setState({
            item: res.data,
            loading: false,
         })
      })
   }

   render() {
      if (typeof this.state.item !== 'object') {
         return <div />
      }
      const { uid, name } = this.state.item
      return (
         <Container>
            <Container>
               <AutoHeader params={[name]} />
               <Button primary icon labelPosition="left" onClick={this.goBack}>
                  <Icon name="left arrow" />
                  Retour
               </Button>
            </Container>
            <Divider />
            <Container>
               <EventsList
                  labelKey="screens.downloads_list"
                  hideObjectColumn={true}
                  condition={{
                     object: [`downloads/${uid}`, '==', 'ref'],
                  }}
                  {...this.props}
               />
            </Container>
         </Container>
      )
   }
}

export default compose(withRouter)(DownloadShowPage)
