import React from 'react'
import { dateFormat, labelFormat } from '../../Helpers'
import * as ROUTES from '../../../constants/routes'
import Api from '../../Api'
import labels from '../../Common/translations'

export default {
   endpoint: 'users',
   pagesize: 10,
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: {
      lastname: {
         label: labels.fields.lastname,
         accessor: s => `${s.lastname}`,
      },
      firstname: {
         label: labels.fields.firstname,
         accessor: s => `${s.firstname}`,
      },
      email: {
         label: labels.fields.email,
      },
      lastLoginAt: {
         label: labels.fields.last_login,
         accessor: row => dateFormat(row.lastLoginAt),
      },
      role: {
         label: labels.fields.role,
         Cell: props => <span>{labelFormat(props.value, props.roles)}</span>,
         values: {
            source: () => Api.selectValues('roles'),
         },
      },
      status: {
         label: labels.fields.disabled_access,
         accessor: ({ status }) =>
            status === 'disabled' ? '\u{1F6AB}' : '\u{2714}',
         options: {
            center: true,
            width: '120px',
            allowOverflow: true,
         },
      },
   },
   buttons: {
      show: {
         label: labels.fields.show,
         url: ROUTES.USER_SHOW,
         options: {
            icon: 'eye',
            positive: true,
         },
      },
      modify: {
         label: labels.fields.modify,
         url: ROUTES.USER_MODIFY,
         options: {
            icon: 'edit',
            primary: true,
         },
      },
   },
   actions: {
      list: params => {
         params.q = params.q || {}
         params.q.status = [['active', 'disabled']]
         return Api.instance().get('users', params)
      },
   },
}
