import FormComponent from '../../Form'
import Api from '../../Api'
import labels from '../../Common/translations'
import * as modes from '../../Inspection/config/constants'
import TextEditor from '../../Form/fields/textEditor'

export default {
   fields: {
      name: {
         label: labels.fields.name,
         mandatory: true,
         searchable: true,
      },
      key: {
         label: labels.fields.key,
         mandatory: true,
         searchable: true,
         protected: true,
      },
      cloudKey: {
         label: labels.fields.cloud_key,
         mandatory: true,
         protected: true,
      },
      mode: {
         label: labels.fields.type,
         mandatory: true,
         type: FormComponent.SELECT,
         values: [
            { key: '', label: '' },
            {
               key: modes.MANUAL,
               label: 'Manual',
            },
            {
               key: modes.AIRBORNE,
               label: 'Drone video',
            },
            {
               key: modes.VIDEO360,
               label: '360 Video',
            },
         ],
         options: {
            defaultValue: modes.MANUAL,
         },
      },
      engine: {
         label: labels.fields.engine,
         mandatory: true,
         type: FormComponent.SELECT,
         values: [
            { key: '', label: '' },
            {
               key: 'aws.stepfunctions',
               label: 'AWS Step Functions',
            },
         ],
         options: {
            defaultValue: 'aws.stepfunctions',
         },
      },
      version: {
         label: labels.fields.version,
         mandatory: false,
         options: {
            prefix: 'v',
         },
      },
      notes: {
         type: FormComponent.TEXTEDITOR,
         label: labels.fields.content,
         options: {
            languages: {
               fr: labels.fields.fr,
               en: labels.fields.en,
               es: labels.fields.es,
            },
            toolbar: TextEditor.TOOLBAR_MEDIUM,
         },
      },
   },
   buttons: {
      delete: true,
      cancel: true,
      submit: true,
      extras: [],
   },
   actions: {
      create: data => Api.instance().post('workflows/', data),
      read: uid => Api.instance().get(`workflows/${uid}`),
      delete: ({ uid }) => Api.instance().delete('workflows/' + uid),
   },
   extras: [],
}
