import React from 'react'
import { withAuthorization, withAuthentication } from '../Session'
import { compose } from 'recompose'
import * as ROUTES from '../../constants/routes'
import { Switch, Route } from 'react-router-dom'
import showPage from './show'
import createPage from './create'
import modifyPage from './modify'
import { Container, Grid } from 'semantic-ui-react'
import TableComponent from '../Table'
import templatesList from './config/templates-list'
import { AutoHeader, TranslatedButton } from '../Common'

const TemplatePage = () => (
   <div>
      <Switch>
         <Route exact path={ROUTES.TEMPLATES} component={TemplateList} />
         <Route path={ROUTES.TEMPLATES_SHOW} component={showPage} />
         <Route path={ROUTES.TEMPLATES_CREATE} component={createPage} />
         <Route path={ROUTES.TEMPLATES_MODIFY} component={modifyPage} />
      </Switch>
   </div>
)

class TemplateBase extends React.Component {
   constructor(props: any) {
      super(props)
      this.state = {
         loading: false,
      }
   }

   componentDidMount() {
      this.setState({ loading: true })
   }

   render() {
      return (
         <Container>
            <Container>
               <Grid columns="equal">
                  <Grid.Column>
                     <AutoHeader />
                  </Grid.Column>
                  <Grid.Column textAlign="right">
                     <TranslatedButton
                        link={ROUTES.TEMPLATES_CREATE}
                        icon="file"
                        label="screens.templates_create"
                     />
                  </Grid.Column>
               </Grid>
            </Container>

            <TableComponent list={templatesList} {...this.props} />
         </Container>
      )
   }
}

const condition = (authUser: any) => authUser // && !!authUser.roles[ROLES.ADMIN]

const TemplateList = withAuthentication(TemplateBase)

export default compose(withAuthorization(condition))(TemplatePage)
