import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import FormComponent from '../Form'
import { Container } from 'semantic-ui-react'
import companyForm from './config/company-form'
import { withAuthentication } from '../Session'
import { AutoHeader } from '../Common'

class CompanyModifyPage extends React.Component {
   constructor(props) {
      super(props)
      this.state = {}
   }

   async componentDidMount() {
      this.setState({ loading: true })
      const { data: item } = await companyForm.actions.read(
         `companies/${this.props.match.params.id}`
      )
      this.setState({
         item,
         loading: false,
      })
   }

   render() {
      if (!this.state.item) {
         return <div />
      }
      return (
         <Container>
            <AutoHeader />
            <Container>
               <FormComponent
                  form={companyForm}
                  data={this.state.item}
                  {...this.props}
               />
            </Container>
         </Container>
      )
   }
}

export default compose(withRouter, withAuthentication)(CompanyModifyPage)
