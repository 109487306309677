import FormComponent from '../../Form'
import { ROLE_PARTNER, ROLE_ROOT } from '../../../constants/roles'
import Api from '../../Api'
import labels from '../../Common/translations'

export default {
   fields: {
      core: {
         type: FormComponent.GROUP,
         fields: {
            email: {
               type: FormComponent.EMAIL,
               label: labels.fields.email_login,
               mandatory: true,
               searchable: true,
               options: {
                  autoComplete: 'email',
               },
            },
            lang: {
               label: labels.fields.language,
               type: FormComponent.SELECT,
               values: {
                  from: 'state',
                  prop: 'langValues',
                  collection: 'languages',
                  source: () => Api.selectValues('languages', {}),
               },
               return: FormComponent.FORMAT_DEFAULT,
               mandatory: true,
            },
         },
      },
      password: {
         label: labels.fields.password,
         type: FormComponent.PASSWORD,
         mandatory: FormComponent.MODE_CREATE,
         options: {
            minLength: 6,
         },
      },
      name: {
         type: FormComponent.GROUP,
         fields: {
            lastname: {
               label: labels.fields.lastname,
               mandatory: true,
               searchable: true,
               options: {
                  autoComplete: 'family-name',
               },
            },
            firstname: {
               label: labels.fields.firstname,
               mandatory: true,
               searchable: true,
               options: {
                  autoComplete: 'given-name',
               },
            },
         },
      },
      pro: {
         type: FormComponent.GROUP,
         fields: {
            company: {
               label: labels.fields.company,
               type: FormComponent.SELECT,
               values: {
                  from: 'state',
                  prop: 'companyValues',
                  collection: 'companies',
                  source: () =>
                     Api.selectValues('companies', {
                        fsort: 'name',
                     }),
               },
               return: FormComponent.FORMAT_OBJECT,
               mandatory: true,
               disabled: ({ authUser }) =>
                  authUser.role !== ROLE_ROOT && authUser.role !== ROLE_PARTNER,
               options: {
                  defaultValue: ({ authUser }) =>
                     authUser.role !== ROLE_ROOT &&
                     authUser.role !== ROLE_PARTNER
                        ? authUser.company
                        : '',
               },
            },
            role: {
               label: labels.fields.role,
               type: FormComponent.SELECT,
               values: {
                  from: 'state',
                  prop: 'roleValues',
                  collection: 'roles',
                  source: ({ authUser }) =>
                     Api.selectValues('roles', {
                        fsort: 'order',
                        q: {
                           access: [authUser.role, 'array-contains'],
                        },
                     }),
               },
               mandatory: true,
            },
            groups: {
               label: labels.fields.groups,
               type: FormComponent.SELECTMANY,
               mandatory: false,
               options: {
                  defaultValue: [],
                  // don't display the loading icon
                  loading: false,
               },
               observers: [
                  {
                     /**
                      * This observer loads the groups list of the active company
                      * acquired from the 'company' field
                      */
                     observable: 'company',
                     action: async data => {
                        if (!data.company || !data.company.ref) {
                           return { groupsValues: [] }
                        } else {
                           const items = await Api.selectValues(
                              `${data.company.ref}/groups`,
                              {
                                 fsort: 'name',
                              }
                           )
                           return {
                              groupsValues: items.map(
                                 ({ key, label: text }) => ({
                                    key,
                                    text,
                                    value: `groups/${key}`,
                                 })
                              ),
                           }
                        }
                     },
                  },
               ],
            },
         },
      },
      params: {
         type: FormComponent.GROUP,
         fields: {
            offlineEnabled: {
               label: labels.fields.offline_access,
               type: FormComponent.CHECKBOX,
               options: {
                  defaultValue: false,
               },
            },
            isActive: {
               label: labels.fields.disabled_access,
               type: FormComponent.CHECKBOX,
               options: {
                  defaultValue: ({ data }) => data.status === 'active',
               },
            },
            notifications: {
               label: labels.fields.notifications_recipient,
               type: FormComponent.CHECKBOX,
               options: {
                  defaultValue: false,
                  resetIfDisabled: true,
               },
               disabled: (_props, { data }) => data.role !== ROLE_ROOT,
               hidden: ({ authUser }) => authUser.role !== ROLE_ROOT,
               resetOnDisable: true,
            },
         },
      },
   },
   buttons: {
      submit: true,
      delete: true,
      cancel: true,
      extras: [],
   },
   actions: {
      create: data =>
         Api.instance().post('users', { origin: 'creation', ...data }),
      read: uid => Api.instance().get(`users/${uid}`),
      update: ({ uid, ...data }) => Api.instance().patch(`users/${uid}`, data),
      delete: ({ uid }) => Api.instance().delete(`users/${uid}`),
   },
   extras: [],
}
