import React from 'react'
import { withAuthorization, withAuthentication } from '../Session'
import { compose } from 'recompose'
import * as ROUTES from '../../constants/routes'
import { Switch } from 'react-router-dom'
import DownloadShowPage from './show'
import DownloadCreatePage from './create'
import DownloadModifyPage from './modify'
import { Container, Grid } from 'semantic-ui-react'
import TableComponent from '../Table'
import downloadsList from './config/downloads-list'
import { AutoHeader, TranslatedButton, Page } from '../Common'

const DownloadPage = () => (
   <div>
      <Switch>
         <Page
            exact
            path={ROUTES.DOWNLOADS}
            component={DownloadList}
            title="screens.downloads"
         />
         <Page
            path={ROUTES.DOWNLOAD_SHOW}
            component={DownloadShowPage}
            title="screens.downloads_page_show"
         />
         <Page
            path={ROUTES.DOWNLOAD_CREATE}
            component={DownloadCreatePage}
            title="screens.downloads_create"
         />
         <Page
            path={ROUTES.DOWNLOAD_MODIFY}
            component={DownloadModifyPage}
            title="screens.downloads_modify"
         />
      </Switch>
   </div>
)

class DownloadListBase extends React.Component {
   constructor(props: any) {
      super(props)
      this.state = {
         loading: false,
      }
   }

   componentDidMount() {
      this.setState({ loading: true })
   }

   render() {
      return (
         <Container>
            <Container>
               <Grid columns="equal">
                  <Grid.Column>
                     <AutoHeader />
                  </Grid.Column>
                  <Grid.Column textAlign="right">
                     <TranslatedButton
                        link={ROUTES.DOWNLOAD_CREATE}
                        icon="file"
                        label="screens.downloads_create"
                     />
                  </Grid.Column>
               </Grid>
            </Container>

            <TableComponent list={downloadsList} {...this.props} />
         </Container>
      )
   }
}

const condition = (authUser: any) => authUser // && !!authUser.roles[ROLES.ADMIN]

const DownloadList = withAuthentication(DownloadListBase)

export default compose(withAuthorization(condition))(DownloadPage)
