import { dateFormat, dureeFormat } from '../../Helpers'
import * as ROUTES from '../../../constants/routes'
import Api from '../../Api'
import labels from '../../Common/translations'

export default {
   endpoint: 'videos',
   pagesize: 10,
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: {
      name: {
         label: labels.fields.name,
         accessor: s => `${s.name}`,
      },
      duration: {
         label: labels.fields.duration,
         accessor: s => (s.duration ? dureeFormat(s.duration * 1000) : ''),
      },
      creationDate: {
         label: labels.fields.creation,
         accessor: s => dateFormat(s.createdAt),
      },
   },
   buttons: {
      show: {
         label: labels.fields.show,
         url: ROUTES.VIDEO_SHOW,
         options: {
            icon: 'eye',
            positive: true,
         },
      },
      modify: {
         label: labels.fields.modify,
         url: ROUTES.VIDEO_MODIFY,
         options: {
            icon: 'edit',
            primary: true,
         },
      },
   },
   actions: {
      list: params => Api.instance().get('videos360', params),
   },
}
