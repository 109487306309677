// inspection created with a human with videos and images
export const MANUAL = 'manual'

// inspection created from an airbone device
export const AIRBORNE = 'airborne'

// inspection done with a 360 video
export const VIDEO360 = '360video'

// case 4 ?
