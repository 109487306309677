import React from 'react'
import { withAuthorization, withAuthentication } from '../Session'
import { compose } from 'recompose'
import { ROUTES } from '../../constants'
import { Switch } from 'react-router-dom'
import WorkflowShowPage from './show'
import WorkflowCreatePage from './create'
import { Container, Grid } from 'semantic-ui-react'
//import SearchComponent from '../Form/search'
import TableComponent from '../Table'
import list from './config/workflow-list'
//import search from './config/workflow-search'
import { AutoHeader, TranslatedButton, Page } from '../Common'
import { pageKey } from '../Helpers'
import { labelHelper } from '../Helpers'
import labels from '../Common/translations'

const WorkflowPage = () => (
   <div>
      <Switch>
         <Page
            exact
            path={ROUTES.WORKFLOW}
            component={WorkflowList}
            title="screens.workflow"
         />
         <Page
            path={ROUTES.WORKFLOW_SHOW}
            component={WorkflowShowPage}
            title="screens.workflow_details"
         />
         <Page
            path={ROUTES.WORKFLOW_CREATE}
            component={WorkflowCreatePage}
            title="screens.workflow_create"
         />
      </Switch>
   </div>
)

class WorkflowListBase extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         loading: false,
         data: [],
         reset: false,
         perPage: 10,
      }
      this.storeKey = `${pageKey()}_filters`
   }

   lb = new labelHelper(
      labels,
      this.props.authUser ? this.props.authUser.lang : labelHelper.lang
   )

   /**
    * Set or get page shared components filters
    */
   filters = (filters = null) => {
      if (filters) {
         sessionStorage.setItem(this.storeKey, JSON.stringify(filters))
         this.setState({ filters })
      } else {
         return sessionStorage.getItem(this.storeKey)
            ? JSON.parse(sessionStorage.getItem(this.storeKey))
            : undefined
      }
   }

   handleItemClick = (e, data) => {
      this.setState({ activeItem: data.name })
      sessionStorage.setItem('activeSubItemNavigation', data.name)
   }

   render() {
      return (
         <Container>
            <Container>
               <Grid columns="equal">
                  <Grid.Column>
                     <AutoHeader />
                  </Grid.Column>
                  <Grid.Column textAlign="right">
                     <TranslatedButton
                        link={ROUTES.WORKFLOW_CREATE}
                        icon="file"
                        label="screens.workflow_create"
                     />
                  </Grid.Column>
               </Grid>
            </Container>

            <TableComponent list={list} {...this.props} />
         </Container>
      )
   }
}

const condition = authUser => authUser // && !!authUser.roles[ROLES.ADMIN]

const WorkflowList = withAuthentication(WorkflowListBase)

export default compose(withAuthorization(condition))(WorkflowPage)
