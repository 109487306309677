import { dateFormat, labelHelper } from '../../Helpers'
import Api from '../../Api'
import { translations } from '../../Common'
import labels from '../../Common/translations'

export default {
   pagesize: 15,
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: {
      host: {
         label: labels.fields.host,
         accessor: ({ createdBy }) => `${createdBy.label}`,
      },
      hostCompany: {
         label: labels.fields.company,
         accessor: ({ hostCompany }) => `${hostCompany.label}`,
      },
      group: {
         label: labels.fields.group,
         accessor: s => s.group.label,
      },
      createdAt: {
         label: labels.fields.created_at,
         accessor: s => dateFormat(s.createdAt),
      },
   },
   buttons: {
      accept: {
         action: async (
            { uid, createdBy, group, hostCompany: company },
            { authUser },
            rowInfo,
            e,
            table
         ) => {
            const lb = new labelHelper(translations.screens, authUser.lang)
            table.openModal(
               lb._('onboarding.accept_header'),
               lb._('onboarding.accept_message', [
                  createdBy.label,
                  group.label,
                  company.label,
               ]),

               () => Api.instance().patch(`me/invitations/${uid}`, { company }),
               true // refresh table after validation
            )
         },
         label: labels.fields.accept,
         options: {
            icon: 'check',
            primary: true,
         },
      },
   },
   actions: {
      list: () => Api.instance().get(`me/invitations`),
   },
}
