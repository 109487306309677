import React from 'react'
import FormComponent from '../Form'
import onboardingForm from './config/user-onboarding-form'
import { Container, Dimmer, Loader } from 'semantic-ui-react'
import { AutoHeader } from '../Common'
import { withRouter } from 'react-router-dom'
import { ErrorMessage } from '../Common'
import { compose } from 'recompose'
import { withFirebase } from '../Firebase'

class UserOnboarding extends React.Component {
   constructor(props) {
      super(props)
      this.props = props
      this.state = { item: {}, loading: true, invalid: false, reason: null }
   }

   async componentDidMount() {
      await this.props.firebase.doSignOut()
      if (
         this.props.firebase.auth.isSignInWithEmailLink(window.location.href)
      ) {
         const keys = window.location.pathname
            .split('/')
            .pop()
            .split('-')
         // key[0] should contain the user record uid and key[1] the onboarding code
         if (!keys[1]) {
            this.raiseError('OB1')
         } else {
            this.props.firebase
               .user(keys[0])
               .then(data => {
                  const user = data.data()
                  if (!user.onboardingKey || user.onboardingKey !== keys[1]) {
                     this.raiseError('OB2')
                  } else {
                     this.props.firebase.auth
                        .signInWithEmailLink(user.email, window.location.href)
                        .then(res => {
                           this.setState({
                              item: { uid: keys[0], ...user },
                              loading: false,
                           })
                        })
                        .catch(err => {
                           console.log(err)
                           this.raiseError('OB3')
                        })
                  }
               })
               .catch(err => {
                  console.log(err, keys)
                  this.raiseError('OB4')
               })
         }
      }
   }

   raiseError(reason) {
      this.setState({
         loading: false,
         invalid: true,
         reason,
      })
   }

   onSubmit = data => {
      const { uid, password, onboardingKey, ...others } = data
      this.props.firebase
         .doPasswordUpdate(password)
         .then(() => {
            this.props.firebase.db
               .collection('users')
               .doc(uid)
               .update({
                  status: 'active',
                  onboardingKey: this.props.firebase.FieldValue.delete(),
                  ...others,
               })
               .then(user =>
                  this.props.history.push(onboardingForm.actions.redirect),
               )
               .catch(err => {
                  console.log(err)
                  this.raiseError('OB5')
               })
         })
         .catch(err => {
            console.log(err)
            this.raiseError('OB6')
         })
   }

   render() {
      if (this.state.loading === true) {
         return (
            <Dimmer>
               <Loader />
            </Dimmer>
         )
      } else if (this.state.invalid === true) {
         return (
            <Container>
               <AutoHeader label="onboarding" info={false} />
               <ErrorMessage
                  labelKey="errors.data"
                  params={[this.state.reason]}
               />
            </Container>
         )
      } else {
         return (
            <Container>
               <AutoHeader labelKey="onboarding" />
               <FormComponent
                  form={onboardingForm}
                  data={this.state.item}
                  onSubmit={this.onSubmit}
                  {...this.props}
               />
            </Container>
         )
      }
   }
}

export default compose(withFirebase, withRouter)(UserOnboarding)
