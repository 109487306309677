import { dateFormat } from '../../Helpers'
import * as ROUTES from '../../../constants/routes'
import { ROLES_WITH_COMPANIES } from '../../../constants/roles'
import Api from '../../Api'
import delayForm from './delay-form'
import FormComponent from '../../Form'
import React from 'react'
import { Button, Container, Header, Icon } from 'semantic-ui-react'
import { ROLES } from '../../../constants'
import labels from '../../Common/translations'
import { AutoLabel } from '../../Common'

export default {
   endpoint: 'medias',
   defaultSorting: { field: 'createdAt', order: 'desc' },

   columns: {
      name: {
         label: labels.fields.name,
         accessor: s => <p className="tablebreakSpaces">{s.name}</p>,
         options: {
            minWidth: '20%',
         },
      },
      company: {
         label: labels.fields.company,
         accessor: s => (
            <p className="tablebreakSpaces">{`${
               s.company ? s.company.label : ''
            }`}</p>
         ),
         hidden: ({ authUser }) =>
            !ROLES_WITH_COMPANIES.includes(authUser.role),
         options: {
            minWidth: '13%',
         },
      },
      specialUsage: {
         label: labels.fields.specialUsage,
         values: [
            { key: '', label: '' },
            {
               key: 'icon',
               label: labels.fields.specialUsageValues.icon,
            },
            {
               key: 'preview',
               label: labels.fields.specialUsageValues.preview,
            },
            {
               key: 'overlayIcon',
               label: labels.fields.specialUsageValues.overlayIcon,
            },
         ],
      },
      createdBy: {
         label: labels.fields.created_by,
         accessor: s => s.createdBy.label,
         options: {
            maxWidth: '20%',
         },
      },
      createdAt: {
         label: labels.fields.created_at,
         format: row => dateFormat(row.createdAt, true),
         options: {
            maxWidth: '15%',
         },
      },
      versions: {
         label: labels.fields.versions,
         options: {
            maxWidth: '5%',
         },
      },
      // updatedAt: {
      //    label: labels.fields.latest_version_at,
      //    format: row => (row.revision > 0 ? dateFormat(row.updatedAt) : ''),
      //    options: {
      //       maxWidth: '15%',
      //    },
      // },
      shares: {
         label: labels.fields.shares,
         options: {
            maxWidth: '5%',
         },
      },
   },
   buttons: {
      show: {
         label: labels.fields.show,
         url: ROUTES.MEDIAS_SHOW,
         options: {
            icon: 'eye',
            positive: true,
         },
      },
      modify: {
         label: labels.fields.modify,
         url: ROUTES.MEDIAS_MODIFY,
         options: {
            icon: 'edit',
            primary: true,
         },
      },
      delete: {
         hidden: (data, { authUser }) =>
            !ROLES.ROLES_WITH_COMPANIES.includes(authUser.role),
         action: async (data, props) => {
            // form is designed for an object update even if we want to perform a delete
            delayForm.actions.update = ({ uid, ...data }) =>
               Api.instance().delete(`/medias/${uid}`, data)
            props.parent.setState({
               open: true,
               content: (
                  <Container>
                     <Header as="h4" block>
                        <AutoLabel
                           labelKey={
                              data.shares > 0
                                 ? 'form.warnings.confirmDeleteMedia'
                                 : 'form.warnings.confirmDeleteMedia2'
                           }
                           params={[data.name, data.shares]}
                        />
                     </Header>
                     <FormComponent form={delayForm} data={data} {...props} />
                  </Container>
               ),
            })
         },
         label: labels.fields.delete,
         options: {
            icon: 'trash alternate',
            negative: true,
         },
      },
      thumbnail: {
         hidden: data => data.status !== 'completed',
         action: async (data, props) =>
            Api.instance().get(`/medias/${data.uid}/regenerate`),
         label: labels.fields.regenerate_thumbnail,
         options: {
            icon: 'refresh',
         },
      },
      restore: {
         hidden: (data, { authUser }) =>
            data.status !== 'deletable' || authUser.role !== ROLES.ROLE_ROOT,
         action: async ({ uid, name }, props) => {
            props.parent.setState({
               open: true,
               modalHeader: 'Media restore',
               content: (
                  <Container style={{ padding: '15px', margin: '15px' }}>
                     <Header>{`Please confirm media '${name}' immediate restoration`}</Header>
                     <Button
                        icon
                        labelPosition="left"
                        onClick={() =>
                           Api.instance()
                              .patch(`/medias/${uid}/restore`)
                              .then(() => {
                                 props.parent.setState({ open: false })
                                 window.location.reload(false)
                              })
                        }
                        positive
                     >
                        <Icon name="backward" />
                        Restore
                     </Button>
                     <Button
                        icon
                        labelPosition="left"
                        onClick={() => props.parent.setState({ open: false })}
                     >
                        <Icon name="cancel" />
                        Cancel
                     </Button>
                  </Container>
               ),
            })
         },
         label: labels.fields.restore,
         options: {
            icon: 'backward',
            color: 'orange',
         },
      },
   },
   actions: {
      list: params => {
         return Api.instance().get('medias', params)
      },
   },
}
