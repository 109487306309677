import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { Container, Divider, Grid } from 'semantic-ui-react'
import { withAuthentication } from '../Session'
import { AutoHeader, TranslatedButton } from '../Common'
import TableComponent from '../Table'
import companyCreditsList from './config/purchases/list'
import companyForm from './config/company-form'
import { ROUTES } from '../../constants'

class CompanySeatsAdd extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         loading: true,
         item: null,
      }

      this.baseRef = `companies/${this.props.match.params.id}`
   }

   async componentDidMount() {
      const { data: item } = await companyForm.actions.read(this.baseRef)
      this.setState({
         item,
         loading: false,
      })
      this.baseRef = `companies/${this.props.match.params.id}`
   }

   // @fix auto header label key detection
   render() {
      if (this.state.loading === true) {
         return <div />
      }

      return (
         <Container>
            <Grid columns="equal">
               <Grid.Column>
                  <AutoHeader labelKey="company_purchases" />
               </Grid.Column>
               <Grid.Column></Grid.Column>
               <Grid.Column textAlign="right">
                  <TranslatedButton
                     link={ROUTES.COMPANY_PURCHASE_CREATE.replace(
                        ':id',
                        this.state.item.uid
                     )}
                     icon="group"
                     label="screens.company_purchase_create"
                  />
               </Grid.Column>
            </Grid>
            <Divider hidden />
            <Container>
               <TableComponent
                  title="labels.fields.purchases"
                  list={companyCreditsList}
                  baseRef={this.baseRef}
                  {...this.props}
               />
            </Container>
         </Container>
      )
   }
}

export default compose(withRouter, withAuthentication)(CompanySeatsAdd)
