export default [
   {
      key: 'download',
      label: {
         fr: 'Téléchargement',
         en: 'Download',
      },
   },
   {
      key: 'create',
      label: {
         fr: 'Création',
         en: 'Creation',
      },
   },
   {
      key: 'update',
      label: {
         fr: 'Modification',
         en: 'Change',
      },
   },
   {
      key: 'delete',
      label: {
         fr: 'Suppression',
         en: 'Deletion',
      },
   },
   {
      key: 'manifest',
      label: {
         fr: 'Envoi du manifeste',
         en: 'Manifest Upload',
      },
   },
]
