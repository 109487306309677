import FormComponent from '../../Form'
import Api from '../../Api'
import * as ROLES from '../../../constants/roles'
import labels from '../../Common/translations'

export default {
   fields: {
      name: {
         label: labels.fields.name,
         mandatory: true,
         searchable: true,
      },
      company: {
         label: labels.fields.company,
         type: FormComponent.SELECT,
         values: {
            from: 'state',
            prop: 'companyValues',
            collection: 'companies',
            source: () => Api.selectValues('companies', { fsort: 'name' }),
         },
         return: FormComponent.FORMAT_OBJECT,
         mandatory: true,
         disabled: ({ authUser }, { data }) =>
            (data.uid !== undefined && data.company !== '') ||
            !ROLES.ROLES_WITH_COMPANIES.includes(authUser.role),
         options: {
            defaultValue: ({ authUser }) =>
               !ROLES.ROLES_WITH_COMPANIES.includes(authUser.role)
                  ? authUser.company
                  : '',
         },
      },
      file: {
         type: FormComponent.FILE,
         label: labels.fields.file,
         mandatory: true,
         options: {
            folder: ({ authUser }, { data }) =>
               `${(data.company && data.company.ref) ||
                  authUser.company.ref}/videos`,
            mimes: ['video/mp4'],
         },
      },
   },
   buttons: {
      delete: true,
      cancel: true,
      submit: true,
      extras: [],
   },
   actions: {
      create: data => Api.instance().post('videos360/', data),
      read: uid => Api.instance().get(`videos360/${uid}`),
      update: ({ uid, ...data }) => Api.instance().patch('videos/' + uid, data),
      delete: ({ uid }) => Api.instance().delete('videos360/' + uid),
   },
   extras: [],
}
