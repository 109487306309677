import React from "react";
import FormComponent from "../../Form";
import Api from "../../Api";
import labels from "../../Common/translations";

export default {
   fields: {
      core: {
         type: FormComponent.GROUP,
         fields: {
            type: {
               label: labels.fields.type,
               mandatory: true,
               type: FormComponent.SELECT,
               values: [
                  { key: "", label: "" },
                  {
                     key: "detection",
                     label: `Détection d'objets`,
                  },
               ],
               options: {
                  defaultValue: "detection",
               },
            },
            source: {
               label: labels.fields.vecto,
               mandatory: true,
               options: {
                defaultValue: ({ vecto, vectoPath }) => ({
                   ref: vectoPath,
                   label: vecto.name,
                }),
                tabIndex: -1,
             },
            },
            company: {
               label: labels.fields.company,
               return: FormComponent.FORMAT_OBJECT,
               mandatory: true,
               disabled: true,
               options: {
                defaultValue: ({ vecto }) => vecto.company,
             },
            },
         },
      },
      target: {
         type: FormComponent.GROUP,
         fields: {
          add2Env: {
            type: FormComponent.CHECKBOX,
            label: labels.fields.append_data,
            labelVariables: ["->"],
         },
            newEnv: {
               label: labels.fields.new_environment,
               type: FormComponent.CHECKBOX,
               disabled: (_, state) => state.data.add2Env !== true
            },
            newEnvName: {
               label: labels.fields.name,
               searchable: true,
               hidden: (_, state) => state.data.newEnv !== true,
               mandatory: (_, state) => state.data.newEnv === true,
            },
            environment: {
               label: labels.fields.environment,
               type: FormComponent.SELECT,
               values: {
                  from: "state",
                  prop: "environmentValues",
                  collection: "environments",
                  source: () =>
                     Api.selectValues("environments", { fsort: "name" }),
               },
               return: FormComponent.FORMAT_OBJECT,
               mandatory: (_, state) => state.data.newEnv !== true,
               hidden: (_, state) => state.data.newEnv === true,
            },
            version: {
               label: labels.fields.version,
               type: FormComponent.SELECT,
               values: {
                  from: "state",
                  prop: "versionValues",
                  source: () => Api.selectValues("versions", { fsort: "name" }),
               },
               return: FormComponent.FORMAT_OBJECT,
               mandatory: (_, state) => state.data.newEnv !== true,
               hidden: (_, state) => state.data.newEnv === true,
               disabled: (_, state) =>
                  !state.data.environment ||
                  state.data.environment.ref === undefined,
               observers: [
                  {
                     /**
                      * This observer loads the versions list of the active environment
                      * acquired from the 'environment' field
                      */
                     observable: "environment",
                     action: async (data) => {
                        if (!data.environment || !data.environment.ref) {
                           return { versionValues: [] };
                        } else {
                           const res = await Api.instance().get(
                              `${data.environment.ref}/versions`,
                              {
                                 fsort: "createdAt",
                                 osort: "desc",
                                 batch: 1000,
                              }
                           );
                           return {
                              versionValues: Object.keys(res.data).map(
                                 (key) => ({
                                    key: `${data.environment.ref}/members/${res.data[key].uid}`,
                                    label: `${res.data[key].createdBy.label} (v${res.data[key].appVersion})`,
                                 })
                              ),
                           };
                        }
                     },
                  },
               ],
            },
         },
      },
      speed: {
         label: labels.fields.speed,
         type: FormComponent.RANGE_SLIDE,
         mandatory: true,
         options: {
            defaultValue: 8,
            max: 8,
            step: null,
            min: 1,
            marks: {
               1: "1X",
               2: "2X",
               4: "4X",
               8: {
                  style: {
                     color: "green",
                  },
                  label: <strong>8X</strong>,
               },
            },
         },
      },
   },
   buttons: {
      delete: true,
      cancel: true,
      submit: true,
      extras: [],
   },
   actions: {
      create: (data) => Api.instance().post("videos3d/job", data),
   },
   extras: [],
};
