import FormComponent from '../../Form'
import SearchComponent from '../../Form/search'
import labels from '../../Common/translations'

export default {
   mode: FormComponent.MODE_SEARCH,
   fields: {
      search: {
         type: FormComponent.GROUP,
         fields: {
            keywords: {
               label: labels.fields.user,
               search: {
                  mode: SearchComponent.BEGINS_WITH,
               },
               options: {
                  autoComplete: 'off',
               },
            },
         },
      },
   },
   buttons: {
      submit: {
         label: labels.fields.search,
         icon: 'search',
      },
      cancel: {
         label: labels.fields.reset,
         icon: 'undo',
      },
      extras: [],
   },
   actions: {},
   extras: [],
}
