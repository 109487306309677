import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { PasswordForgetLink } from '../PasswordForget'
import { withFirebase } from '../Firebase'
import * as ROUTES from '../../constants/routes'
import { Container } from 'semantic-ui-react'
import FormComponent from '../Form'
import loginForm from './config/login-form'
import { AutoHeader } from '../Common'
import labels from '../Common/translations'
import { labelHelper } from '../Helpers'
import { ErrorMessage } from '../Common'
import { BaaS } from '../..'
import { withAuthentication } from '../Session'

class SignInPage extends React.Component {
   constructor(props) {
      super(props)
      this.state = { loginError: false, errorCode: null }
      this.form = React.createRef()
      this.lb = new labelHelper(
         labels,
         this.props.authUser ? this.props.authUser.lang : labelHelper.lang,
      )
      localStorage.removeItem('authUser')
      const params = new URLSearchParams(window.location.search)
      if (params.get('redirect')) {
         this.redirect = params.get('redirect')
      }
   }

   componentDidUpdate() {
      if (this.props.authUser) {
         // Redirect user if already logged in
         this.props.history.push(this.redirect || ROUTES.HOME)
      }
   }

   // onSubmitParse = async ({ email, password }) => {
   //    this.setState({ loginError: false, errorCode: '' })
   //    const Parse = require('parse')
   //    Parse.initialize('backoffice', 'totoro', 'totoro')
   //    Parse.serverURL = 'http://localhost:1337/parse'
   //    try {
   //       Parse.User.logIn(email, password).then(() =>
   //          this.props.history.push(ROUTES.HOME),
   //       )
   //    } catch (err) {
   //       this.setState({ loginError: true, errorCode: err.message })
   //       this.form.current.setState({ dimmed: false })
   //    }
   // }

   onSubmit = ({ email, password }) => {
      this.setState({ loginError: false, errorCode: '' })
      this.props.firebase
         .doSignInWithEmailAndPassword(email, password)
         .then(() => {
            this.props.history.push(this.redirect || ROUTES.HOME)
         })
         .catch(error => {
            if (error.code === 'auth/user-not-found') {
               error = this.lb._(labels.errors.userNotFound)
            } else if (error.code === 'auth/wrong-password') {
               error = this.lb._(labels.errors.wrongPassword)
            }
            this.setState({ loginError: true, errorCode: error })
            this.form.current.setState({ dimmed: false })
         })
   }

   resetPassword = data => {
      const { email } = data
      this.props.firebase
         .doPasswordReset(email)
         .then(() => this.history.back(ROUTES.SIGN_IN))
   }

   render() {
      return (
         <Container>
            <AutoHeader />
            {this.state.loginError && (
               <ErrorMessage content={this.state.errorCode} />
            )}
            <FormComponent
               ref={this.form}
               form={loginForm}
               onSubmit={
                  BaaS === 'onpremise' ? this.onSubmitParse : this.onSubmit
               }
               isDimmed={this.state.dimmed}
               {...this.props}
            />
            <Container>
               <PasswordForgetLink />
            </Container>
         </Container>
      )
   }
}

export default compose(withRouter, withFirebase, withAuthentication)(SignInPage)
