import { dateFormat } from '../../Helpers'
import Api from '../../Api'
import { ROUTES } from '../../../constants'
import labels from '../../Common/translations'
import React from 'react'

export default {
   endpoint: 'environments',
   pagesize: 10,
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: {
      vecto: {
         label: labels.fields.video_3d,
         accessor: ({ vecto }) => (
            <p className="tablebreakSpaces">
               {`${vecto.name} (${vecto.company.label})`}
            </p>
         ),
         options: {
            maxWidth: '30%',
         },
      },
      creator: {
         label: labels.fields.version,
         accessor: ({ version }) => `${version.createdBy.label}`,
      },
      revision: {
         label: labels.fields.revision,
         accessor: ({ version }) => version.revision,
         options: {
            maxWidth: '5%',
         },
      },
      createdAt: {
         label: labels.fields.latest_updated,
         accessor: s => dateFormat(s.createdAt),
         options: {
            maxWidth: '20%',
         },
      },
   },
   buttons: {
      show: {
         label: labels.fields.show,
         url: ({ vecto }) => {
            const parts = vecto.ref.split('/')
            return ROUTES.SCENE_SHOW_TAB_SHARES.replace(
               ':pid',
               `videos.${parts[1]}`
            ).replace(':id', parts[3])
         },
         options: {
            icon: 'eye',
            positive: true,
         },
      },
   },
   actions: {
      list: (params, props) =>
         Api.instance().get(`${props.baseRef}/vectos`, params),
   },
}
