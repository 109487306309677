import Api from '../../../Api'
import FormComponent from '../../../Form'
import labels from '../../../Common/translations'
import { Stakeholders } from '../../../Common'
import { ROLES } from '../../../../constants'

export default {
   fields: {
      company: {
         label: labels.fields.company,
         type: FormComponent.SELECT,
         mandatory: true,
         disabled: ({ authUser }) =>
            ROLES.ROLES_WITH_COMPANIES.includes(authUser.role),
         hidden: ({ authUser }) =>
            !ROLES.ROLES_WITH_COMPANIES.includes(authUser.role),
         values: {
            from: 'state',
            prop: 'companyValues',
            collection: 'companies',
            source: () =>
               Api.selectValues('companies', {
                  fsort: 'name',
               }),
         },
         return: FormComponent.FORMAT_OBJECT,
         options: {
            defaultValue: ({ company }) =>
               company.ref
                  ? company
                  : {
                       ref: `companies/${company.uid}`,
                       label: company.name,
                    },
         },
      },
      name: {
         label: labels.fields.name,
         mandatory: true,
      },
      members: {
         label: labels.fields.members,
         type: FormComponent.SELECTMANY,
         options: {
            defaultValue: [],
         },
         values: {
            from: 'state',
            prop: 'membersValues',
            collection: 'roles',
            source: ({ authUser, company }) =>
               Stakeholders(authUser.company.ref, ['user'], [], authUser.lang, [
                  company.ref,
               ]),
         },
      },
      guests: {
         label: labels.fields.guests,
         type: FormComponent.SELECTMANY,
         options: {
            defaultValue: [],
         },
         values: {
            from: 'state',
            prop: 'guestsValues',
            source: ({ authUser, company }) =>
               Stakeholders(
                  authUser.company.ref,
                  ['guest'],
                  [],
                  authUser.lang,
                  [company.ref]
               ),
         },
      },
      comment: {
         label: labels.fields.comment,
      },
   },
   buttons: {
      delete: false,
      submit: true,
      cancel: true,
      extras: [],
   },
   actions: {
      create: data => Api.instance().post(`${data.company.ref}/groups`, data),
      read: uid => Api.instance().get(`companies/${uid}`),
      //redirect: ROUTES.COMPANY,
   },
   extras: [],
}
