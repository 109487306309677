import Api from "../../Api";
import labels from "../../Common/translations";
import moment from "moment";
import { formatBytes } from "./utils";

export default {
   endpoint: "dlstats",
   defaultSorting: { field: "time", order: "desc" },

   columns: {
      company: {
         label: labels.fields.company,
         accessor: (s) => `${s.company ? s.company.label : ""}`,
         //  hidden: ({ authUser }) => authUser.role === 'admin',
      },
      downloadedBy: {
         label: labels.fields.downloaded_by,
         accessor: (s) => s.downloadedBy,
      },

      size: {
         label: labels.fields.size,
         format: (row) => formatBytes(row["measure_value::double"]),
      },

      createdAt: {
         label: labels.fields.created_at,
         format: (row) => moment(row.time).format("YYYY-MM-DD HH:mm:ss"),
         sortable: true,
      },
   },

   actions: {
      list: (_, props) => Api.instance().get(`${props.baseRef}/dlstats`),
   },
};
