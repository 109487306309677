import { dateFormat } from '../../Helpers'
import Api from '../../Api'
import labels from '../../Common/translations'

export default {
   pagesize: 15,
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: {
      guest: {
         label: labels.fields.guest,
         accessor: ({ guest }) => `${guest.label} (${guest.email}`,
         options: {
            maxWidth: '40%',
            allowOverflow: 'true',
         },
      },
      company: {
         label: labels.fields.company,
         accessor: s => s.company && s.company.label,
         options: {
            maxWidth: '5%',
         },
      },
      createdBy: {
         label: labels.fields.invited_by,
         accessor: s => s.createdBy.label,
         options: {
            maxWidth: '5%',
         },
      },
      group: {
         label: labels.fields.group,
         accessor: s => s.group.label,
         options: {
            maxWidth: '5%',
         },
      },
      createdAt: {
         label: labels.fields.created_at,
         accessor: s => dateFormat(s.createdAt),
         options: {
            maxWidth: '125px',
         },
      },
      status: {
         label: labels.fields.status,
         options: {
            maxWidth: '25px',
         },
      },
   },
   actions: {
      list: (params, props) =>
         Api.instance().get(`${props.baseRef}/guests`, params),
   },
}
