import React from 'react'
import { withRouter } from 'react-router-dom'
import { withAuthentication } from '../Session'
import { compose } from 'recompose'
import FormComponent from '../Form'
import { Container } from 'semantic-ui-react'
import form from './config/job-form'
import { AutoHeader } from '../Common'

class InspectionJobPage extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         loading: true,
         item: undefined,
      }
   }

   async componentDidMount() {
      this.setState({ loading: true })
      const { data: item } = await form.actions.read(this.props.match.params.id)

      this.setState({
         item,
         loading: false,
      })
   }

   render() {
      if (!this.state.item) {
         return <div />
      }
      return (
         <Container>
            <AutoHeader params={[this.state.item.name]} />
            <Container>
               <FormComponent
                  form={form}
                  {...this.props}
                  inspection={this.state.item}
               />
            </Container>
         </Container>
      )
   }
}

export default compose(withRouter, withAuthentication)(InspectionJobPage)
