import { dateFormat, dureeFormat } from '../../Helpers'
import * as ROUTES from '../../../constants/routes'
import Api from '../../Api'
import labels from '../../Common/translations'

export default {
   endpoint: 'sessions',
   pagesize: 5,
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: {
      debut: {
         label: labels.fields.debut,
         accessor: row => dateFormat(row.createdAt),
      },
      duration: {
         label: labels.fields.duration,
         accessor: row => dureeFormat(row.duration),
      },
      agent: {
         label: labels.fields.agent,
         accessor: s => s.agent.browser.name || s.agent.platform.raw,
      },
      os: {
         label: labels.fields.os,
         accessor: s => s.env.operatingSystem,
      },
      location: {
         label: labels.fields.location,
         accessor: s =>
            `${s.city.charAt(0).toUpperCase() + s.city.slice(1)} (${
               s.country
            })`,
      },
   },
   buttons: {
      show: {
         label: labels.fields.show,
         url: ROUTES.SESSIONS_SHOW,
         options: {
            icon: 'eye',
            positive: true,
         },
      },
   },
   actions: {
      list: (params, { filters }) => {
         console.log(filters)
         params.q = filters || {}
         return Api.instance().get('sessions', params)
      },
   },
}
