import React from 'react'
import { Segment, Container, List, Image } from 'semantic-ui-react'

export default class Footer extends React.Component {

   state = { version: '-.-.-' }

   componentDidMount() {
      fetch('/version.json').then(response => {
         if (response.ok) {
            response.json().then(async ({ version }) => {
               this.setState({ version })
            })
         } else {
            console.log('Unable version file /version.txt')
         }
      })
   }

   render() {
      return (
         <Segment
            inverted
            vertical
            color="grey"
            style={{
               margin: '3em 0em 0em',
               padding: '1em 0em',
               position: 'fixed',
               bottom: 0,
               left: 0,
               width: '100%',
            }}
         >
            <Container textAlign="center">
               <List horizontal inverted divided link size="small">
                  <List.Item
                     as="a"
                     href="https://www.totalymage.com"
                     target="_blank"
                  >
                     <Image centered size="small" src="/logo.jpg" />
                  </List.Item>
                  <List.Item>Backoffice v{this.state.version}</List.Item>
                  <List.Item
                     as="a"
                     href="https://www.totalymage.com/contact-europe"
                  >
                     Contact Us
                  </List.Item>
                  {/* <List.Item as="a" href="#">
                     Terms and Conditions
                  </List.Item>
                  <List.Item as="a" href="#">
                     Privacy Policy
                  </List.Item> */}
               </List>
            </Container>
         </Segment>
      )
   }
}
