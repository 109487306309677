import FormComponent from '../../Form'
import Api from '../../Api'
import labels from '../../Common/translations'
import { speedSelector } from '../../Videos/config/speedSelector'
import * as ROLES from '../../../constants/roles'

export default {
   fields: {
      core: {
         type: FormComponent.GROUP,
         fields: {
            name: {
               label: labels.fields.name,
               mandatory: true,
               searchable: true,
            },
            company: {
               label: labels.fields.company,
               type: FormComponent.SELECT,
               values: {
                  from: 'state',
                  prop: 'companyValues',
                  collection: 'companies',
                  source: () =>
                     Api.selectValues('companies', { fsort: 'name' }),
               },
               return: FormComponent.FORMAT_OBJECT,
               mandatory: true,
               disabled: ({ authUser }, { data }) =>
                  (data.uid !== undefined && data.company !== '') ||
                  !ROLES.ROLES_WITH_COMPANIES.includes(authUser.role),
               options: {
                  defaultValue: ({ authUser }) => authUser.company,
               },
            },
         },
      },
      cloud1: {
         type: FormComponent.GROUP,
         fields: {
            cloudProvider: {
               label: labels.fields.cloud_provider,
               type: FormComponent.SELECT,
               values: [
                  {
                     key: 'aws',
                     label: 'Amazon Web Services',
                  },
                  {
                     key: 'gcp',
                     label: 'Google Cloud Platform',
                  },
               ],
               return: FormComponent.FORMAT_OBJECT,
               mandatory: true,
            },
            messageQueue: {
               label: labels.fields.message_queue,
               mandatory: true,
               searchable: true,
            },
         },
      },
      cloud2: {
         type: FormComponent.GROUP,
         fields: {
            containerImage: {
               label: labels.fields.container_image,
               mandatory: true,
               searchable: true,
            },
            containerImageVersion: {
               label: labels.fields.version,
               mandatory: true,
               searchable: true,
            },
         },
      },
      minSpeed: speedSelector(labels.fields.speed, [1, 2, 4, 8, 16, 24], 1, [
         'Min.',
      ]),
      maxSpeed: speedSelector(labels.fields.speed, [1, 2, 4, 8, 16, 24], 24, [
         'Max.',
      ]),
      target: {
         type: FormComponent.GROUP,
         fields: {
            gpuAccess: {
               label: labels.fields.gpu_access,
               type: FormComponent.CHECKBOX,
            },
            minRam: {
               label: labels.fields.ram,
               labelSuffix: 'Min.',
               mandatory: true,
               options: {
                  defaultValue: 2,
                  label: { basic: true, content: 'MB' },
                  labelPosition: 'right',
               },
            },
            maxRam: {
               label: labels.fields.ram,
               labelSuffix: 'Max.',
               searchable: true,
               mandatory: true,
               options: {
                  defaultValue: 4,
                  label: { basic: true, content: 'MB' },
                  labelPosition: 'right',
               },
            },
         },
      },
   },
   buttons: {
      cancel: true,
      submit: true,
      extras: [],
   },
   actions: {
      create: data => {
         return Api.instance().post(`jobs/definition`, data)
      },
      read: uid => Api.instance().get(`jobs/definitions/${uid}`),
   },
   extras: [],
}
