import React from 'react'
import { withAuthorization, withAuthentication } from '../Session'
import { compose } from 'recompose'
import { ROLES, ROUTES } from '../../constants'
import { Switch } from 'react-router-dom'
import SessionShowPage from './show'
import { Container } from 'semantic-ui-react'
import TableComponent from '../Table'
import SearchComponent from '../Form/search'
import search from './config/search'
import sessionsList from './config/sessions-list'
import { AutoHeader, MandatoryWarning, Page } from '../Common'
import SearchableList from '../Common/searchableList'

const SessionPage = () => (
   <div>
      <Switch>
         <Page
            exact
            path={ROUTES.SESSIONS}
            component={SessionList}
            title="screens.sessions"
         />
         <Page
            path={ROUTES.SESSIONS_SHOW}
            component={SessionShowPage}
            title="screens.sessions_show"
         />
      </Switch>
   </div>
)

class SessionListBase extends SearchableList {
   constructor(props) {
      super(props)
      this.state = {
         loading: true,
      }
   }

   render() {
      return (
         <Container>
            <Container>
               <AutoHeader />
            </Container>
            <SearchComponent
               form={search}
               data={this.filters()}
               {...this.props}
               propagate={filters => this.filters(filters)}
            />
            {this.shouldRender() && (
               <TableComponent
                  list={sessionsList}
                  {...this.props}
                  filters={this.filters()}
               />
            )}
            {!this.shouldRender() && <MandatoryWarning />}
         </Container>
      )
   }

   /**
    * Should the content be rendered ?
    * if user is partner, they must choose a company
    */
   shouldRender() {
      return (
         (this.filters() && this.filters().company) ||
         [ROLES.ROLE_ROOT, ROLES.ROLE_ADMIN].includes(this.props.authUser.role)
      )
   }
}

const condition = authUser => authUser // && !!authUser.roles[ROLES.ADMIN]

const SessionList = withAuthentication(SessionListBase)

export default compose(withAuthorization(condition))(SessionPage)
