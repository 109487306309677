import React from 'react'
import {
   Button,
   Popup,
   Dimmer,
   Segment,
   Loader,
   Message,
   Icon,
   Confirm,
} from 'semantic-ui-react'
import DataTable from 'react-data-table-component'
import _ from 'lodash'
import { AutoLabel, ErrorMessage } from '../Common'
import labels from '../Common/translations'
import { labelHelper, labelFormat } from '../Helpers'
import { Link } from 'react-router-dom'
import hash from 'object-hash'

export default class TableComponent extends React.Component {
   static PID_PLACEHOLDER = '-'
   columns = []
   constructor(props) {
      super(props)
      this.state = {
         loading: true,
         error: null,
         data: [],
         reset: false,
         perPage: this.props.list.pagesize || 20,
         modalOpen: false,
         modalRefresh: false,
      }
      this.storeKey = `${this.props.list.endpoint}-${hash(
         JSON.stringify(this.props.list)
      )}`
      this.lb = new labelHelper(
         labels,
         this.props.authUser ? this.props.authUser.lang : labelHelper.lang
      )
      this.paginationOptions = this.props.paginationOptions || [
         20,
         50,
         100,
         500,
      ]
   }

   storedPage = (page = null) => {
      if (page) {
         sessionStorage.setItem(`${this.storeKey}-page`, page)
      }

      return parseInt(sessionStorage.getItem(`${this.storeKey}-page`)) || 1
   }

   setColumns = async () => {
      this.columns = []
      // Remove hidden columns
      Object.keys(this.props.list.columns).forEach(key => {
         const val = this.props.list.columns[key]
         if (
            val.hidden !== undefined &&
            typeof val.hidden === 'function' &&
            val.hidden(this.props) === true
         ) {
            delete this.props.list.columns[key]
         }
      })

      const values = {}
      const colKeys = Object.keys(this.props.list.columns)

      // prepare potential async retrieval of values
      const valuesPromises = colKeys.map(async key => {
         const val = this.props.list.columns[key]
         if (val.values && val.values.source) {
            return val.values.source().then(res => (values[key] = res))
         }
      })

      await Promise.all(valuesPromises)

      // eslint-disable-next-line no-unused-expressions
      colKeys.forEach(key => {
         const val = this.props.list.columns[key]
         return this.columns.push({
            grow: 2,
            name: this.lb._(val.label),
            selector: val.accessor || key,
            ...(val.format && { format: val.format }),
            sortable: val.sortable || false,
            ...(val.align && { [val.align]: true }),
            ...(val.values && {
               values: values[key] || val.values,
            }),
            ...val.options,
         })
      })
      this.columns.push({
         name: '',
         minWidth: '10%',
         maxWidth: '15%',
         right: true,
         cell: row =>
            _.map(this.props.list.buttons, (button, key) => {
               let { icon, ...purgedOptions } = button.options
               let urlPattern = null

               // check if button should be displayed
               if (button.hidden) {
                  if (
                     button.hidden === true ||
                     (button.hidden instanceof Function &&
                        button.hidden(row, this.props, this.state) === true)
                  ) {
                     return
                  }
               }
               const disabled =
                  button.disabled instanceof Function
                     ? button.disabled(row, this.props, this.state)
                     : false
               if (button.url) {
                  if (button.url instanceof Function) {
                     urlPattern = button.url(row, this.props, this.state)
                  } else {
                     urlPattern = button.url.replace(':id', row.uid)
                     if (this.props.list.pid) {
                        // is there a parent path to include (pid) ?
                        const parts = this.props.list.pid.split('.')
                        if (row[parts[0]]) {
                           let record = row
                           parts.map(part => (record = record[part] || []))
                           urlPattern = urlPattern.replace(
                              ':pid',
                              record.replace('/', '.')
                           )
                        } else {
                           // no replacement found, use a placeholder
                           urlPattern = urlPattern.replace(
                              ':pid',
                              TableComponent.PID_PLACEHOLDER
                           )
                        }
                     }
                  }
               }
               return (
                  <span key={key}>
                     {urlPattern && (
                        <Link
                           to={{
                              pathname: disabled ? '' : urlPattern,
                              state: { item: row },
                           }}
                        >
                           <Popup
                              trigger={
                                 <Button
                                    size="mini"
                                    icon={icon}
                                    {...purgedOptions}
                                    disabled={disabled}
                                 />
                              }
                              content={this.lb._(button.label)}
                              size="mini"
                              position="top right"
                           />
                        </Link>
                     )}
                     {button.action && (
                        <Popup
                           trigger={
                              <Button
                                 size="mini"
                                 icon={icon}
                                 disabled={disabled}
                                 onClick={(e, rowInfo) =>
                                    button.action(
                                       row,
                                       this.props,
                                       rowInfo,
                                       e,
                                       this
                                    )
                                 }
                                 {...purgedOptions}
                              />
                           }
                           content={this.lb._(button.label)}
                           size="mini"
                           position="top right"
                        />
                     )}
                  </span>
               )
            }),
      })
   }

   async componentDidMount() {
      await this.setColumns()
      // refresh stored query
      this.query(this.storedPage(), this.props.filters)
   }

   /**
    * Refresh query if filters are different from previous
    * @param {Object} filters previous filters object
    */
   componentDidUpdate({ filters }) {
      if (!_.isEqual(filters, this.props.filters)) {
         this.setState({ reset: true })
         this.query(1, this.props.filters)
      }
   }

   query = async (page = 1, filters = {}, sorting = []) => {
      if (this.props.data) {
         // quick and dirty way to display a list from a field
         console.log(`Table was populated with data from Component`)
         return this.setState({
            data: this.props.data,
            max: this.props.data.length,
            reset: false,
            loading: false,
         })
      }

      this.setState({ loading: true, error: null })
      sessionStorage.setItem(`${this.storeKey}-page`, page)
      const q = {}
      _.map(filters, (val, key) => {
         if (val) {
            q[key] = val.ref ? [val.ref, '==', 'ref'] : val
         }
      })

      const params = {
         offset: (page - 1) * this.state.perPage,
         batch: this.state.perPage,
         q,
         fsort: sorting[0]
            ? sorting[0].selector
            : this.props.list.defaultSorting.field,
         osort: sorting[1] || this.props.list.defaultSorting.order,
      }
      try {
         this.props.list.actions
            .list(params, this.props)
            .then(res => {
               // Convert key to label for columns having a 'values' key
               _.map(_.filter(this.columns, 'values'), column => {
                  _.map(res.data, row => {
                     row[`_${column.selector}`] = row[column.selector]
                     row[column.selector] = labelFormat(
                        row[column.selector],
                        column.values
                     )
                  })
               })
               this.setState({
                  data: res.data,
                  max: res.meta.count,
                  reset: false,
                  loading: false,
               })
            })
            .catch(err => this.renderError(err))
      } catch (err) {
         this.renderError(err)
      }
   }

   renderError = err => {
      const error = err.message
         ? `${this.lb._('form.errors.server_error')} ${err.message}`
         : err
      this.setState({ loading: false, error, data: [] })
   }

   /**
    * Set or get page shared components filters
    */
   filters = (filters = null) => {
      if (filters) {
         console.log('Setting new filters', filters)
         sessionStorage.setItem(this.storeKey, JSON.stringify(filters))
         this.setState({ filters, reset: true })
         this.query(1, filters)
      } else {
         return sessionStorage.getItem(this.storeKey)
            ? JSON.parse(sessionStorage.getItem(this.storeKey))
            : undefined
      }
   }

   render() {
      if (this.columns.length === 0) {
         // don't display if columns are not available
         return (
            <Dimmer.Dimmable as={Segment} blurring dimmed={this.state.loading}>
               <Dimmer active={this.state.loading} size="mini" inverted>
                  <Loader indeterminate>
                     {this.lb._(labels.form.loading)}
                  </Loader>
               </Dimmer>
            </Dimmer.Dimmable>
         )
      }

      if (this.state.data.length === 0 && this.props.hideIfEmpty === true) {
         // Don't diplay if data is empty and hideIfEmpty flag is true
         return <div />
      }

      return (
         <Dimmer.Dimmable as={Segment} blurring dimmed={this.state.loading}>
            <Dimmer active={this.state.loading} inverted>
               <Loader size="massive" indeterminate>
                  {this.lb._(labels.form.loading)}
               </Loader>
            </Dimmer>
            {this.state.error && <ErrorMessage content={this.state.error} />}
            <DataTable
               style={{ width: '100%' }}
               keyField="uid"
               title={
                  typeof this.props.title === 'string' &&
                  this.props.title.indexOf('.') > -1 ? (
                     <AutoLabel labelKey={this.props.title} />
                  ) : (
                     this.props.title
                  )
               }
               columns={this.columns}
               data={this.state.data}
               pagination={
                  this.props.pagination !== undefined
                     ? this.props.pagination
                     : true
               }
               paginationDefaultPage={this.storedPage()}
               paginationRowsPerPageOptions={this.paginationOptions}
               paginationTotalRows={
                  this.state.max === 1001 ? '1000+' : this.state.max
               }
               paginationPerPage={this.state.perPage}
               paginationResetDefaultPage={this.state.reset}
               paginationComponentOptions={{
                  rowsPerPageText: this.lb._('table.rowsPerPage'),
                  rangeSeparatorText: this.lb._('table.rangeSeparator'),
               }}
               paginationServer
               onChangePage={val => this.query(val, this.props.filters)}
               onChangeRowsPerPage={perPage => {
                  this.setState({ perPage, reset: true }, () =>
                     this.query(1, this.props.filters)
                  )
               }}
               striped={true}
               highlightOnHover={true}
               sortServer={true}
               onSort={(col, direction) =>
                  this.query(1, this.filters(), [col, direction])
               }
               defaultSortField={
                  this.props.list.defaultSorting
                     ? this.props.list.defaultSorting.field
                     : null
               }
               defaultSortAsc={
                  this.props.list.defaultSorting
                     ? this.props.list.defaultSorting.order === 'asc'
                     : null
               }
               dense={this.props.compact}
               noDataComponent={
                  <Message warning icon>
                     <Icon name="exclamation circle" />
                     <Message.Content>
                        {this.lb._('errors.noData')}
                     </Message.Content>
                  </Message>
               }
               customStyles={{
                  TableComponent: {
                     style: {
                        width: '100%', // override the row height
                        maxWidth: '100%',
                        paddingBottom: '30px',
                     },
                  },
                  headCells: {
                     style: {
                        paddingLeft: '8px', // override the cell padding for head cells
                        paddingRight: '8px',
                        fontWeight: 'bold',
                     },
                  },
                  cells: {
                     style: {
                        paddingLeft: '8px', // override the cell padding for data cells
                        paddingRight: '8px',
                     },
                  },
                  header: {
                     style: {
                        minHeight: 30,
                     },
                  },
               }}
            />
            <Confirm
               open={this.state.modalOpen}
               header={this.state.modalTitle}
               content={this.state.modalContent}
               cancelButton={this.lb._('form.cancel')}
               confirmButton={this.lb._('form.confirm')}
               onCancel={() => this.setState({ modalOpen: false })}
               onConfirm={() =>
                  this.state.modalConfirm instanceof Function &&
                  this.state.modalConfirm().then(() => {
                     this.setState({ modalOpen: false, reset: true }, () => {
                        if (this.state.modalRefresh === true) {
                           this.query(this.storedPage(), this.props.filters)
                        }
                     })
                  })
               }
            />
         </Dimmer.Dimmable>
      )
   }

   openModal(modalTitle, modalContent, modalConfirm, modalRefresh = false) {
      this.setState({
         modalOpen: true,
         modalTitle,
         modalContent,
         modalConfirm,
         modalRefresh,
      })
   }
}
