import React from 'react'
import { Link } from 'react-router-dom'
import { withFirebase } from '../Firebase'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import * as ROUTES from '../../constants/routes'
import FormComponent from '../Form'
import { Container } from 'semantic-ui-react'
import { AutoLabel, AutoHeader } from '../Common'
import passwordForm from './password-form-list'

const PasswordForgetPage = () => (
   <div>
      <PasswordForgetForm />
   </div>
)

class PasswordForgetFormBase extends React.Component {
   constructor(props) {
      super(props)
      this.state = { ...props.location.state }
      this.userRef = `users/${this.props.match.params.id}`
   }

   componentDidMount() {
      if(this.state.item) {
         this.setState({ ...this.props.location.state.item })
      } else {
         this.setState({ loading: true })
         passwordForm.actions.read(this.userRef).then(item => {
            this.setState({
               item,
               loading: false,
            })
         })
      }
   }

   onSubmit = data => {
      this.props.firebase
         .doPasswordReset(data.email)
         .then(() => {
            this.props.history.push(ROUTES.USER)
         })
         .catch(error => {
            return error.message
         })
   }

   render() {
      return (
         <Container>
            <AutoHeader />
            <FormComponent
               form={passwordForm}
               onSubmit={this.onSubmit}
               data={this.state.item}
               {...this.props}
            />
         </Container>
      )
   }
}

const PasswordForgetLink = () => (
   <p>
      <Link to={ROUTES.PASSWORD_FORGET}>
         <AutoLabel labelKey="form.password.forget" />
      </Link>
   </p>
)

export default PasswordForgetPage

const PasswordForgetForm = compose(
   withFirebase,
   withRouter,
)(PasswordForgetFormBase)

export { PasswordForgetForm, PasswordForgetLink }
