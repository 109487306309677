import FormComponent from "../../Form";
import Api from "../../Api";
import { canListCompanies } from "../../Helpers";
import SearchComponent from "../../Form/search";
import labels from "../../Common/translations";

export default {
  mode: FormComponent.MODE_SEARCH,
  fields: {
    search: {
      type: FormComponent.GROUP,
      fields: {
        keywords: {
          label: labels.fields.name_creator,
          search: {
            mode: SearchComponent.BEGINS_WITH,
          },
          options: {
            autoComplete: "off",
          },
        },
        company: {
          label: labels.fields.company,
          hidden: ({ authUser }) => !canListCompanies(authUser),
          type: FormComponent.SELECT,
          values: {
            from: "state",
            prop: "companyValues",
            collection: "companies",
            source: () =>
              Api.selectValues("companies", {
                fsort: "name",
              }),
          },
          return: FormComponent.FORMAT_OBJECT,
        },
      },
    },
  },
  buttons: {
    submit: {
      label: labels.fields.search,
      icon: "search",
    },
    cancel: {
      label: labels.fields.reset,
      icon: "undo",
    },
    extras: [],
  },
  actions: {},
  extras: [],
};
