import React from 'react'
import { Route } from 'react-router-dom'
import { labelHelper } from '../Helpers'
import labels from './translations'

class Page extends Route {
   constructor(props) {
      super(props)
      this.props = props
      this.lb = new labelHelper(
         labels,
         this.props.authUser ? this.props.authUser.lang : labelHelper.lang,
      )
   }
   componentDidMount() {
      document.title = this.lb._(this.props.title) + ' | TotalYmage Admin Panel'
   }

   componentDidUpdate() {
      document.title = this.lb._(this.props.title) + ' | TotalYmage Admin Panel'
   }

   render() {
      const { title, ...rest } = this.props
      return <Route {...rest} />
   }
}

export default Page
