const hamburgerStyle = {
   bmBurgerButton: {
      position: 'fixed',
      width: '60px',
      height: '50px',
      left: '0px',
      top: '0px',
      padding: '10px',
      display: 'block',
      borderRadius: '0 0 10px 0',
      backgroundColor: 'grey',
      zIndex: '99999999',
   },
   bmBurgerBars: {
      background: '#373a47',
      width: '30px',
      height: '3px',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'table-cell',
      verticalAlign: 'middle',
   },
   bmBurgerBarsHover: {},
   bmCrossButton: {
      height: '0',
      width: '0',
   },
   bmCross: {
      display: 'none',
   },
   bmMenuWrap: {
      position: 'fixed',
      height: '100%',
      left: '0',
      top: '0',
      alignItems: 'left',
      textAlign: 'left',
      zIndex: '999999999',
   },
   bmMenu: {
      width: 'auto',
      height: '100%',
      alignItems: 'left',
      textAlign: 'left',
      backgroundColor: 'grey',
   },
   bmMorphShape: {
      height: '100%',
   },
   bmItemList: {
      color: '#b8b7ad',
      padding: '0',
      width: 'auto',
      height: 'auto',
      alignItems: 'left',
      textAlign: 'left',
      margin: '0',
   },
   bmItem: {
      display: 'inline-block',
      width: '100%',
      height: '100%',
      textAlign: 'left',
   },

   item: {
      textAlign: 'left',
   },

   bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)',
      alignItems: 'left',
      height: '100%',
      marginBottom: '20px',
      marginTop: '0',
      zIndex: '999999999',
   },

   navigationBar: {
      height: '45px',
      paddingBottom: '20px',
   },
}

export default hamburgerStyle
