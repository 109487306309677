import FormComponent from '../../Form'
import * as ROUTES from '../../../constants/routes'
import Api from '../../Api'
import labels from '../../Common/translations'

export default {
   fields: {
      core: {
         type: FormComponent.GROUP,
         fields: {
            email: {
               type: FormComponent.EMAIL,
               label: labels.fields.email_login,
               mandatory: true,
               options: {
                  autoComplete: 'email',
                  disabled: true,
               },
            },
            lang: {
               label: labels.fields.language,
               type: FormComponent.SELECT,
               values: {
                  from: 'state',
                  prop: 'langValues',
                  collection: 'languages',
                  source: () => Api.selectValues('languages', {}),
               },
               return: FormComponent.FORMAT_DEFAULT,
               mandatory: true,
            },
         },
      },
      password: {
         label: labels.fields.password,
         type: FormComponent.PASSWORD,
         mandatory: FormComponent.MODE_CREATE,
         options: {
            minLength: 6,
         },
      },
      name: {
         type: FormComponent.GROUP,
         fields: {
            lastname: {
               label: labels.fields.lastname,
               mandatory: true,
               options: {
                  autoComplete: 'family-name',
               },
            },
            firstname: {
               label: labels.fields.firstname,
               mandatory: true,
               options: {
                  autoComplete: 'given-name',
               },
            },
         },
      },
   },
   buttons: {
      submit: true,
      cancel: true,
      extras: [],
   },
   actions: {
      create: data =>
         Api.instance().post('users/', { origin: 'creation', ...data }),
      read: ({ uid, ...args }) => Api.instance().get('users/' + uid, args),
      update: ({ uid, ...data }) => Api.instance().patch('users/' + uid, data),
      redirect: ROUTES.HOME,
   },
   extras: [],
}
