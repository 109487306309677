import React from 'react'
import Icon from 'react-multi-date-picker/components/icon'
import { Dimmer, Loader, Message, Segment } from 'semantic-ui-react'
import { AutoLabel, ErrorMessage } from '../../../Common'
import DataTable from 'react-data-table-component'
import labels from '../../../Common/translations'
import { formatBytes } from '../../../Common/dlstats/utils'
import useFetch from '../../../Common/hooks/fetch'
import { labelHelper } from '../../../Helpers'

export default function UserDlStasListTable({ filters, ...props }) {
   const { data = [], error, loading } = useFetch({
      api_path: '/dl/listUsers',
   })
   const { keywords = '' } = filters || {}

   const lb = new labelHelper(
      labels,
      props.authUser ? props.authUser.lang : labelHelper.lang
   )
   const columns = [
      {
         name: lb._(labels.fields.user),
         selector: row => row.user,
      },
      {
         name: lb._(labels.dlstats.fields.video3d),
         selector: row => formatBytes(row.video3d),
      },

      {
         name: lb._(labels.dlstats.fields.video360),
         selector: row => formatBytes(row.video360),
      },
      {
         name: lb._(labels.dlstats.fields.medias),
         selector: row => formatBytes(row.medias),
      },
      {
         name: lb._(labels.dlstats.fields.environments),
         selector: row => formatBytes(row.environments),
      },
      {
         name: lb._(labels.dlstats.fields.downloads),
         selector: row => formatBytes(row.telechargeable),
      },
      {
         name: lb._(labels.dlstats.fields.total),
         selector: row => formatBytes(row.total),
      },
  
      
   ]

   return (
      <Dimmer.Dimmable as={Segment} blurring dimmed={loading}>
         <Dimmer active={loading} inverted>
            <Loader size="massive" indeterminate>
               {lb._(labels.form.loading)}
            </Loader>
         </Dimmer>
         {error && <ErrorMessage content={error} />}
         <DataTable
            style={{ width: '100%' }}
            keyField="uid"
            title={<AutoLabel labelKey={labels.dlstats.title} />}
            columns={columns}
            data={
               data
                  ? keywords !== ''
                     ? data.data.filter(e =>
                          e?.user
                             ?.toLowerCase()
                             ?.includes(keywords.toLowerCase())
                       )
                     : data.data || []
                  : []
            }
            pagination={true}
            paginationPerPage={10}
            noDataComponent={
               <Message warning icon>
                  <Icon name="exclamation circle" />
                  <Message.Content>{lb._('errors.noData')}</Message.Content>
               </Message>
            }
            customStyles={{
               TableComponent: {
                  style: {
                     width: '100%', // override the row height
                     maxWidth: '100%',
                     paddingBottom: '30px',
                  },
               },
               headCells: {
                  style: {
                     paddingLeft: '8px', // override the cell padding for head cells
                     paddingRight: '8px',
                     fontWeight: 'bold',
                  },
               },
               cells: {
                  style: {
                     paddingLeft: '8px', // override the cell padding for data cells
                     paddingRight: '8px',
                  },
               },
               header: {
                  style: {
                     minHeight: 30,
                  },
               },
            }}
         />
      </Dimmer.Dimmable>
   )
}
