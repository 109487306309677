import { ROLES } from '../../../constants'
import Api from '../../Api'
import labels from '../../Common/translations'
import { formatBytes } from '../../Common/dlstats/utils'
export default {
   endpoint: 'list',
   defaultSorting: { field: 'time', order: 'desc' },
   columns: {
      company: {
         label: labels.fields.company,
         accessor: s => s.companyName,
         hidden: ({ authUser }) =>
            !ROLES.ROLES_WITH_COMPANIES.includes(authUser.role),
      },
      video3d: {
         label: labels.dlstats.fields.video3d,
         accessor: row => formatBytes(row.video3d),
      },

      video360: {
         label: labels.dlstats.fields.video360,
         accessor: row => formatBytes(row.video360),
      },
      medias: {
         label: labels.dlstats.fields.medias,
         accessor: row => formatBytes(row.medias),
      },
      environments: {
         label: labels.dlstats.fields.environments,
         accessor: row => formatBytes(row.environments),
      },
      
      downloads: {
         label: labels.dlstats.fields.downloads,
         accessor: row => formatBytes(row.telechargeable),
      },
      
      dlstats: {
         label: labels.dlstats.fields.total,
         accessor: row => formatBytes(row.total),
      },
   },

   actions: {
      list: params => {
         return Api.instance().get('/dl/listCompanies', params)
      },
   },
}
