import { dateFormat, labelFormat } from '../../Helpers'
import * as ROUTES from '../../../constants/routes'
import { ROLES_WITH_COMPANIES } from '../../../constants/roles'
import Api from '../../Api'
import delayForm from './delay-form'
import FormComponent from '../../Form'
import React from 'react'
import { Button, Container, Header, Icon } from 'semantic-ui-react'
import { ROLES } from '../../../constants'
import labels from '../../Common/translations'

export default {
   endpoint: 'environments',
   defaultSorting: { field: 'latestVersionAt', order: 'desc' },

   columns: {
      name: {
         label: labels.fields.name,
         accessor: s => s.name,
         format: row => <p className="tablebreakSpaces">{row.name}</p>,

         options: {
            maxWidth: '35%',
         },
      },
      company: {
         label: labels.fields.company,
         accessor: s => `${s.company ? s.company.label : ''}`,
         hidden: ({ authUser }) =>
            !ROLES_WITH_COMPANIES.includes(authUser.role),

         format: row => (
            <p className="tablebreakSpaces">{`${
               row.company ? row.company.label : ''
            }`}</p>
         ),
         options: {
            maxWidth: '15%',
         },
      },
      createdBy: {
         label: labels.fields.created_by,
         accessor: s => s.createdBy.label,
         options: {
            maxWidth: '18%',
         },
      },
      createdAt: {
         label: labels.fields.created_at,
         format: row => dateFormat(row.createdAt, true),
         options: {
            maxWidth: '5%',
         },
      },
      versions: {
         label: labels.fields.versions,
         options: {
            maxWidth: '5%',
         },
      },
      latestVersionAt: {
         label: labels.fields.latest_version_at,
         format: row =>
            row.versions > 0 ? dateFormat(row.latestVersionAt, true) : '',
         options: {
            maxWidth: '5%',
         },
      },
      status: {
         label: labels.fields.status,
         accessor: s => labelFormat(s.status, labels.statuses.environment),
         options: {
            maxWidth: '5%',
         },
      },
   },
   buttons: {
      show: {
         label: labels.fields.show,
         url: ROUTES.ENVIRONMENTS_SHOW,
         options: {
            icon: 'eye',
            positive: true,
         },
      },
      delete: {
         hidden: (data, { authUser }) =>
            data.status === 'deletable' || authUser.role !== ROLES.ROLE_ROOT,
         action: async (data, props) => {
            // form is designed for an object update even if we want to perform a delete
            delayForm.actions.update = ({ uid, ...data }) =>
               Api.instance().delete(`/environments/${uid}`, data)
            props.parent.setState({
               open: true,
               modalHeader: 'Environment deletion',
               content: (
                  <Container>
                     <Header as="h4" block>
                        {`Environment ${data.name} (${data.company.label})`}
                     </Header>
                     <FormComponent
                        onCancel={props => {
                           props.parent.setState({ open: false })
                        }}
                        form={delayForm}
                        data={data}
                        {...props}
                     />
                  </Container>
               ),
            })
         },
         label: labels.fields.delete,
         options: {
            icon: 'trash alternate',
            negative: true,
         },
      },
      restore: {
         hidden: (data, { authUser }) =>
            data.status !== 'deletable' || authUser.role !== ROLES.ROLE_ROOT,
         action: async ({ uid, name }, props) => {
            props.parent.setState({
               open: true,
               modalHeader: 'Environment restore',
               content: (
                  <Container style={{ padding: '15px', margin: '15px' }}>
                     <Header>{`Please confirm environment '${name}' immediate restoration`}</Header>
                     <Button
                        icon
                        labelPosition="left"
                        onClick={() =>
                           Api.instance()
                              .patch(`/environments/${uid}/restore`)
                              .then(() => {
                                 props.parent.setState({ open: false })
                                 window.location.reload(false)
                              })
                        }
                        positive
                     >
                        <Icon name="backward" />
                        Restore
                     </Button>
                     <Button
                        icon
                        labelPosition="left"
                        onClick={() => props.parent.setState({ open: false })}
                     >
                        <Icon name="cancel" />
                        Cancel
                     </Button>
                  </Container>
               ),
            })
         },
         label: labels.fields.restore,
         options: {
            icon: 'backward',
            color: 'orange',
         },
      },
   },
   actions: {
      list: params => {
         return Api.instance().get('environments', params)
      },
   },
}
