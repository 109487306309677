import React from "react";
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import Navigation from "../Navigation";
import SignInPage from "../SignIn";
import SignOut from "../SignOut";
import PasswordForgetPage from "../PasswordForget";
import PasswordEmailPage from "../PasswordEmail";
import HomePage from "../Home";
import CompanyPage from "../Company";
import CompanyAdminPage from "../Company/index-admin";
import UserPage from "../User";
import UserOnboarding from "../User/onboarding";
import GuestOnboarding from "../Company/guestOnboarding";
import SessionPage from "../Sessions";
import EnvironmentPage from "../Environments";
import JobsPage from "../jobs";

import VideoPage from "../Videos";
import InspectionPage from "../Inspection";
import ScenesPage from "../Scenes";
import MediasPage from "../Medias";
import AdministrationPage from "../Administration";
import DownloadPage from "../Downloads";
import TemplatePage from "../Templates";
import WorkflowPage from "../Workflow";
import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../Session";
import MainAppContainer from "./MainAppContainer.style";
import Notifications from "../Common/Notifications";
import Footer from "../Common/Footer";
import { Page } from "../Common";

class Router extends BrowserRouter {
  constructor(props) {
    super(props);
    console.debug(
      "initial history is: ",
      JSON.stringify(this.history, null, 2)
    );
    this.history.listen((location, action) => {
      console.debug(
        `The current URL is ${location.pathname}${location.search}${location.hash}`
      );
      console.debug(
        `The last navigation action was ${action}`,
        JSON.stringify(this.history, null, 2)
      );
    });
  }
}

const App = () => {
  return (
    <Router>
      <div>
        <Notifications />
        <Navigation />
        <MainAppContainer>
          <Route exact path={ROUTES.LANDING}>
            <Redirect to={ROUTES.HOME} />
          </Route>
          <Route path={ROUTES.SIGN_IN} component={SignInPage} />
          <Route
            exact
            path={ROUTES.PASSWORD_FORGET}
            component={PasswordForgetPage}
          />
          <Route path={ROUTES.PASSWORD_EMAIL} component={PasswordEmailPage} />
          <Page
            path={ROUTES.ONBOARDING}
            component={UserOnboarding}
            title="screens.onboarding"
          />
          <Page
            exact
            path={ROUTES.ONBOARDING_GUEST}
            component={GuestOnboarding}
            title="screens.onboarding"
          />
          <Page path={ROUTES.HOME} component={HomePage} title="screens.home" />
          <Route path={ROUTES.COMPANY} component={CompanyPage} />
          <Route path={ROUTES.COMPANY_ADMIN} component={CompanyAdminPage} />
          <Route path={ROUTES.USER} component={UserPage} />
          <Route path={ROUTES.SESSIONS} component={SessionPage} />
          <Route path={ROUTES.ENVIRONMENTS} component={EnvironmentPage} />
          <Route path={ROUTES.JOBS} component={JobsPage} />

          <Route path={ROUTES.INSPECTION} component={InspectionPage} />
          <Route path={ROUTES.VIDEO} component={VideoPage} />
          <Route path={ROUTES.SCENES} component={ScenesPage} />
          <Route path={ROUTES.MEDIAS} component={MediasPage} />
          <Route path={ROUTES.ADMINISTRATION} component={AdministrationPage} />
          <Route path={ROUTES.DOWNLOADS} component={DownloadPage} />
          <Route path={ROUTES.TEMPLATES} component={TemplatePage} />
          <Route path={ROUTES.WORKFLOW} component={WorkflowPage} />
          <Route path={ROUTES.SIGN_OUT} component={SignOut} />
        </MainAppContainer>
        <Footer />
      </div>
    </Router>
  );
};

export default withAuthentication(App);
