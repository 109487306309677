import ReactDOM from 'react-dom'
import 'semantic-ui-css/semantic.min.css'
import './index.css'
import App from './components/App'
import Firebase, { FirebaseContext } from './components/Firebase'
import { FirebaseConfig } from './types'
import Api from './components/Api'
import * as serviceWorker from './serviceWorker'

//require('dotenv').config()

console.log(process.env);

export const BaaS = process.env.REACT_APP_MODE || 'firebase'

const load = async () => {
   if (BaaS === 'onpremise') {
      const apiHost = process.env.REACT_APP_APIHOST || 'localhost'
      const apiPort = process.env.REACT_APP_APIPORT || '1337'
      Api.DEFAULT_URL = `http://${apiHost}:${apiPort}/parse/classes/`
      render2()
   } else {
      const response = await fetch('/__/firebase/init.json', {
         headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
         },
      })
      const config: FirebaseConfig = await response.json()
      sessionStorage.setItem(
         'vapidkey',
         config.projectId !== 'totalymage-admin'
            ? 'BBG3thFiiACyEA0SUCiZEzLQgYHdshpDZa9dJt_tAcFkbioXiqaBDLxJ6ReYK6KHZI-pSn50p-QeK8BPW12zlbE'
            : 'BFH3n4BFQmpwWGtzfZppRcmzMaQHzt8MmGhEjOxWraSIkJwa9vgNqJ67vmt5wvPDepk7H7Hrs4pfmv_RnwJoIVA'
      )

      const useEmulator =
         window.location.hostname === 'localhost' &&
         process.env.REACT_APP_EMULATOR === '1'

      if (useEmulator) {
         Api.DEFAULT_URL = `http://localhost:5002/${config.projectId}/us-central1/`
         console.log('API configured for local emulator')
      } else {
         Api.DEFAULT_URL = `https://europe-west1-${config.projectId}.cloudfunctions.net/`
      }
      render(config, useEmulator)
   }
}

const render = (config: FirebaseConfig, useEmulator = false) => {
   ReactDOM.render(
      <FirebaseContext.Provider value={new Firebase(config, useEmulator)}>
         <App />
      </FirebaseContext.Provider>,
      document.getElementById('root')
   )

   serviceWorker.unregister()
}

const render2 = () => {
   ReactDOM.render(<App />, document.getElementById('root'))
}

load()
