import React from 'react'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Container, Tab } from 'semantic-ui-react'

export default class TextEditor extends React.Component {
   static DEFAULT_LANGUAGE = {
      __: { fr: 'Toutes langues', en: 'All Languages' },
   }
   static TOOLBAR_MINIMAL = 'minimal'
   static TOOLBAR_MEDIUM = 'medium'
   static TOOLBAR_FULL = 'full'

   constructor(props) {
      // @see https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp
      super(props)
      this.toolbar =
         this.props.toolbar && this.props.toolbar !== TextEditor.TOOLBAR_FULL
            ? require(`./editor-${this.props.toolbar}.json`)
            : {}
      this.panes = []
      const editorState = {}
      // set up editors for each language
      Object.keys(this.props.languages || TextEditor.DEFAULT_LANGUAGE).map(
         language => {
            if (props.defaultValue[language]) {
               // create editor state from content
               const contentBlock = htmlToDraft(props.defaultValue[language])
               const contentState = ContentState.createFromBlockArray(
                  contentBlock.contentBlocks,
               )
               editorState[language] = EditorState.createWithContent(
                  contentState,
               )
            } else {
               // or empty state
               editorState[language] = EditorState.createEmpty()
            }

            this.panes.push({
               menuItem: this.props.languages[language][
                  this.props.lang || 'en'
               ],
               render: () => (
                  <Tab.Pane>
                     <Container className="main-form">
                        <Editor
                           key={`${this.props.name}_${language}`}
                           editorState={editorState[language]}
                           onEditorStateChange={editorState =>
                              this.onEditorStateChange(editorState, language)
                           }
                           localization={{
                              locale: this.props.lang || 'en',
                           }}
                           toolbar={this.toolbar}
                        />
                     </Container>
                  </Tab.Pane>
               ),
            })
            return null
         },
      )
      this.state = {
         editorState,
      }
   }

   onEditorStateChange = (state, language) => {
      const editorState = this.state.editorState
      editorState[language] = state
      this.setState({
         editorState,
      })
      if (this.props.onChange) {
         const content = {}
         Object.keys(this.props.languages || TextEditor.DEFAULT_LANGUAGE).map(
            language =>
               (content[language] = draftToHtml(
                  convertToRaw(editorState[language].getCurrentContent()),
               )),
         )
         this.props.onChange(content)
      }
   }

   render() {
      return (
         <Tab menu={{ secondary: true, pointing: true }} panes={this.panes} />
      )
   }
}
