import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import FormComponent from '../Form'
import { Container, Grid, Divider, Button, Icon, Tab } from 'semantic-ui-react'
import userForm from './config/user-form'
import { withAuthentication } from '../Session'
import TableComponent from '../Table'
import userSessionList from './config/user-session-list'
import { AutoHeader, AutoLabel, EventsList } from '../Common'
import { TabWidget } from '../Widgets'
import { labelHelper } from '../Helpers'
import labels from '../Common/translations'
import DlStats from '../Common/dlstats'

class UserShowPage extends React.Component {
   constructor(props) {
      super(props)
      this.state = {}
      this.userRef = `${this.props.match.params.id}`
      this.goBack = this.goBack.bind(this)
   }

   lb = new labelHelper(
      labels,
      this.props.authUser ? this.props.authUser.lang : labelHelper.lang
   )

   goBack() {
      this.props.history.goBack()
   }

   componentDidMount() {
      this.setState({ loading: true })
      userForm.actions.read(this.userRef).then(res => {
         this.setState({
            item: res.data,
            loading: false,
         })
      })
   }

   panes = [
      {
         key: 'details',
         menuItem: this.lb._('screens.detail'),
         render: () => (
            <Tab.Pane key="details">
               <FormComponent
                  form={userForm}
                  readOnly={true}
                  data={this.state.item}
                  {...this.props}
               />
            </Tab.Pane>
         ),
      },
      {
         key: 'sessions',
         menuItem: this.lb._('screens.sessions'),
         render: () => (
            <Tab.Pane key="sessions">
               <Container className="sessions-list">
                  <TableComponent
                     title={<AutoLabel labelKey="screens.sessions" />}
                     list={userSessionList}
                     filters={{
                        'user.ref': [`users/${this.userRef}`],
                     }}
                     {...this.props}
                  />
               </Container>
            </Tab.Pane>
         ),
      },
      {
         key: 'event',
         menuItem: this.lb._(labels.fields.event),
         render: () => (
            <Tab.Pane key="event">
               <EventsList
                  labelKey="screens.relatedEvents"
                  hideUserColumn={true}
                  condition={{
                     user: [`users/${this.state.item.uid}`, '==', 'ref'],
                  }}
                  {...this.props}
               />
            </Tab.Pane>
         ),
      },
      {
         key: 'dlstats',
         menuItem: this.lb._(labels.fields.dlstats),
         render: () => (
            <Tab.Pane key="scenes">
               <Container className="dl-stats-list">
                  <DlStats
                     ref_id={`users/${this.state.item.uid}`}
                     api_path={'/dl/user-stats'}
                     {...this.props}
                  />
               </Container>
            </Tab.Pane>
         ),
      },
   ]

   render() {
      if (!this.state.item) {
         return <div />
      }
      return (
         <Container>
            <Grid columns="equal">
               <Grid.Column>
                  <AutoHeader />
               </Grid.Column>
               <Grid.Column textAlign="right">
                  <Button
                     primary
                     icon
                     labelPosition="left"
                     onClick={this.goBack}
                  >
                     <Icon name="left arrow" />
                     <AutoLabel labelKey="form.back" />
                  </Button>
               </Grid.Column>
            </Grid>
            <Divider hidden />
            <Fragment>
               <TabWidget panes={this.panes} />
            </Fragment>
         </Container>
      )
   }
}

export default compose(withRouter, withAuthentication)(UserShowPage)
