export type JSONValue = string | number | boolean | JSONObject | JSONArray

export interface JSONObject {
   [x: string]: JSONValue
}

export interface JSONArray extends Array<JSONValue> {}

export const load = async (name: string): Promise<JSONObject> => {
   try {
      const schema = await fetch(`/schemas/${name}.json`, {
         headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
         },
      })

      return await schema.json()
   } catch (e) {
      throw Error(`Unable to load JSON schema for ${name}`)
   }
}

export const validate = async (
   json: JSONObject,
   schema: string | JSONObject
): Promise<boolean | string[]> => {
   try {
      if (typeof schema === 'string') {
         schema = await load(schema)
      }

      const Ajv2019 = require('ajv/dist/2019')
      const ajv = new Ajv2019() // options can be passed, e.g. {allErrors: true}
      const validate = ajv.compile(schema)

      const valid = validate(json)

      if (!valid) {
         console.log('validation', validate.errors)
         const errors: string[] = []
         validate.errors.forEach((error: any) =>
            errors.push(`${error.keyword} ${error.message}`)
         )
         return errors
      }
      return true
   } catch (e) {
      console.log(e)
      throw e
   }
}
