import React from 'react'
import { Icon, Label } from 'semantic-ui-react'
import Api from '../Api'

/**
 * Retrieve the list of stakeholders matching given company ref or uid
 * and format them to be used in a SelectMany form field
 */
export default (
   company,
   onlyTypes = [],
   extraStakeholders = [],
   lang = 'en',
   onlyCompanies = []
) => {
   try {
      const uri = company.includes('/') ? company : `companies/${company}`
      return Api.instance()
         .get(`${uri}/stakeholders/enhanced`)
         .then(({ data }) => {
            let list = buildList(data.extras || data, onlyTypes)
            let offset = data.extras.length + 1

            // add stakeholders from each managed company
            data.managedCompanies.forEach((mc, index) => {
               if (
                  onlyCompanies.length === 0 ||
                  onlyCompanies.includes(mc.company.ref)
               ) {
                  list = list.concat(
                     buildList(
                        mc.items,
                        onlyTypes,
                        mc.company.label,
                        list.length,
                        index + offset
                     )
                  )
               }
            })

            // add optional guest groups that user is member of
            list = list.concat(buildList(data.guestGroups, onlyTypes, '', 1000))

            const slashed = {
               companies: {
                  en: 'Other Company',
                  fr: 'Autre Société',
               },
               users: {
                  en: 'Other User',
                  fr: 'Autre Utilisateur',
               },
               groups: {
                  en: 'Other Group',
                  fr: 'Autre Groupe',
               },
               guests: {
                  en: 'Other Guest',
                  fr: 'Autre Invité',
               },
               guestGroup: {
                  en: 'Other Guest Group',
                  fr: `Autre Groupe Accessible`,
               },
               others: {
                  en: 'Unknown',
                  fr: 'Inconnu',
               },
            }

            // display external stakeholders (to preserve list upon save)
            // eslint-disable-next-line array-callback-return
            extraStakeholders.map(es => {
               let type =
                  typeof es == 'string' ? es.split('/').shift() : es.type
               if (type == null) {
                  type = 'others'
               }
               const slashedType = slashed[type]
               if (slashedType !== undefined) {
                  const text = `${slashedType[lang]} #${list.length + 1}`
                  return list.push({
                     key: list.length + 1,
                     text,
                     value: es,
                     content: (
                        <Label as="a" size="medium" color="grey">
                           <Icon name="eye slash" />
                           {text}
                           <Label.Detail content="Outside Stakeholder" />
                        </Label>
                     ),
                  })
               }
            })

            return list
         })
   } catch (e) {
      console.error(e.message)
      return []
   }
}

const buildList = (items, onlyTypes = [], prefix = '', keyOffset = 0) => {
   if (onlyTypes.length > 0) {
      items = items.filter(sh => onlyTypes.includes(sh.type))
   }
   const map = {
      company: { icon: 'factory', color: 'blue' },
      group: { icon: 'users', color: 'brown' },
      guestGroup: { icon: 'users', color: 'orange' },
      guest: { icon: 'user outline', color: 'teal' },
      user: { icon: 'user', color: 'olive' },
   }
   return items.map(({ id: value, label, type, comment }, key) => ({
      key: key + keyOffset,
      // display either label or value
      text:
         prefix && prefix !== label && type !== 'guest'
            ? `${prefix}: ${label}`
            : label
            ? `${comment}: ${label}`
            : value,
      value,
      content: (
         <Label as="a" size="medium" color={map[type].color}>
            <Icon name={map[type].icon} />
            {label || value}
            <Label.Detail content={comment || prefix} />
         </Label>
      ),
   }))
}
