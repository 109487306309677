import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import userRecord from '../User/config/user-record'
import { withFirebase } from '../Firebase'
import { withAuthentication } from '../Session'
import { compose } from 'recompose'
import 'react-toastify/dist/ReactToastify.css'

class Notifications extends React.Component {
   componentDidMount() {
      try {
         if (
            this.props.firebase.messaging &&
            sessionStorage.getItem('vapidkey')
         ) {
            this.props.firebase.messaging.usePublicVapidKey(
               sessionStorage.getItem('vapidkey')
            )
            this.props.firebase.messaging
               .requestPermission()
               .then(() => {
                  console.log('[Notifications] Permission granted')
                  this.props.firebase.messaging
                     .getToken()
                     .then(messageToken => {
                        if (
                           this.props.authUser &&
                           messageToken !== this.props.authUser.messageToken
                        ) {
                           userRecord.actions.update({
                              uid: this.props.authUser.uid,
                              messageToken,
                           })
                        }
                     })
                     .catch(err => console.log(err))
               })
               .catch(err => console.log(err))
            // @todo monitor refresh token
            // @see https://firebase.google.com/docs/cloud-messaging/js/client?authuser=0

            this.props.firebase.messaging.onMessage(payload => {
               console.log('[Notifications] Message received', payload)
               toast.info(payload.notification.title)
            })
         } else {
            console.error(
               '[Notifications] Unable to enable notifications: Missing VapidKey'
            )
         }
      } catch (err) {
         console.error(
            `[Notifications] Unable to enable notifications: ${err.message}`
         )
      }
   }

   render() {
      return <ToastContainer newestOnTop closeOnClick pauseOnHover />
   }
}

export default compose(withFirebase, withAuthentication)(Notifications)
