import { dateFormat } from '../../Helpers'
import Api from '../../Api'
import { ROUTES } from '../../../constants'
import labels from '../../Common/translations'

export default {
   // where to find the parent id
   pid: 'video.ref',
   defaultSorting: { field: 'createdAt', order: 'desc' },
   pagesize: 20,
   columns: {
      creator: {
         label: labels.fields.shared_by,
         accessor: ({ version }) =>
            `${version.createdBy.label} (${version.company.label})`,
      },
      env: {
         label: labels.fields.Environment,
         accessor: ({ environment }) =>
            `${environment.label} (${environment.company.label})`,
      },
      revision: {
         label: labels.fields.revision,
         accessor: ({ version }) => version.revision,
      },
      createdAt: {
         label: labels.fields.created_at,
         accessor: ({ createdAt }) => dateFormat(createdAt),
      },
   },
   buttons: {
      show: {
         label: labels.fields.show,
         url: row =>
            ROUTES.ENVIRONMENTS_SHOW.replace(
               ':id',
               row.environment.ref.split('/').pop(),
            ),
         options: {
            icon: 'eye',
            positive: true,
         },
      },
   },
   actions: {
      list: (params, props) =>
         Api.instance().get(`${props.baseRef}/shares`, params),
   },
}
