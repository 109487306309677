import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import FormComponent from '../Form'
import { Container } from 'semantic-ui-react'
import form from './config/video3d-rename'
import { withAuthentication } from '../Session'
import { AutoHeader } from '../Common'

class Video3dRenamePage extends React.Component {
   constructor(props) {
      super(props)
      this.state = { item: null, loading: true }
   }

   componentDidMount() {
      const { id, pid } = this.props.match.params
      const uid =
         pid === ':pid'
            ? `vectos/${id}`
            : `${pid.replace('.', '/')}/vectos/${id}`
      form.actions.read(uid).then(res => {
         this.setState({
            item: { ...res.data, uid },
            loading: false,
         })
      })
   }

   onSubmit = async data => {
      await form.actions.update({ ...data, status: 'renaming' })
      this.props.history.push(form.actions.redirect)
   }

   render() {
      if (!this.state.item) {
         return <div />
      }
      console.log(this.state.item)
      return (
         <Container>
            <AutoHeader
               labelKey="video3d_rename"
               params={[this.state.item.name]}
            />
            <FormComponent
               form={form}
               data={this.state.item}
               onSubmit={this.onSubmit}
               {...this.props}
            />
         </Container>
      )
   }
}

export default compose(withRouter, withAuthentication)(Video3dRenamePage)
