import React from "react";
import { Link } from "react-router-dom";
import { AuthUserContext } from "../Session";
import { ROLES, ROUTES } from "../../constants";
import { Menu, Icon } from "semantic-ui-react";
import labels from "../Common/translations";
import { labelHelper } from "../Helpers";
import { slide as Burger } from "react-burger-menu";
import hamburgerStyle from "./resources/hamburgerStyle";

const NAVBAR_MIN_SIZE = 1150;

class NavigationBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: "home",
      viewportWidth: window.innerWidth,
    };

    this.superRoles = [ROLES.ROLE_ROOT, ROLES.ROLE_PARTNER];
    this.adminRoles = [ROLES.ROLE_ROOT, ROLES.ROLE_ADMIN, ROLES.ROLE_PARTNER];
    this.lb = new labelHelper(
      labels.screens,
      this.props.authUser ? this.props.authUser.lang : labelHelper.lang
    );
  }

  componentDidMount() {
    this.getInitialState();

    window.addEventListener("resize", () =>
      this.setState({ viewportWidth: window.innerWidth })
    );
  }

  getInitialState = () => {
    let activeItem = sessionStorage.getItem("activeItemNavigation") || "home";
    this.setState({ activeItem: activeItem });
  };

  isGranted(roles = []) {
    return roles.length === 0 || roles.includes(this.props.authUser.role);
  }

  menuElement(name, route, label, grantedTo = [], options = {}) {
    if (!this.isGranted(grantedTo)) {
      return null;
    }

    const extra = {};
    if (typeof route === "function") {
      extra.onClick = route;
    } else {
      extra.as = Link;
      extra.to = route;
    }

    return (
      <Menu.Item
        name={name}
        active={this.state.activeItem === name}
        onClick={this.elementClicked}
        {...extra}
      >
        <Icon name={options.icon || "stop"} />
        {this.lb._(label)}
      </Menu.Item>
    );
  }

  elementClicked = (_e, { name }) => {
    sessionStorage.setItem("activeItemNavigation", name);
    this.setState({ activeItem: name });
  };
}

const Navigation = () => (
  <div>
    <AuthUserContext.Consumer>
      {(authUser) =>
        authUser && !authUser.onboardingKey ? (
          <NavigationAuth authUser={authUser} />
        ) : (
          <NavigationNonAuth />
        )
      }
    </AuthUserContext.Consumer>
  </div>
);

class NavigationAuth extends NavigationBase {
  renderNavbar() {
    return (
      <Menu tabular icon="labeled" inverted color="grey">
        {this.menuElement("home", ROUTES.HOME, "home", [], {
          icon: "home",
        })}
        {this.menuElement(
          "company",
          ROUTES.COMPANY_ADMIN,
          "mycompany",
          ROLES.ROLE_ADMIN,
          { icon: "factory" }
        )}
        {this.menuElement(
          "companies",
          ROUTES.COMPANY,
          "companies",
          this.superRoles,
          { icon: "factory" }
        )}
        {this.menuElement("users", ROUTES.USER, "users", this.adminRoles, {
          icon: "user outline",
        })}
        {/* {this.menuElement(
               'sessions',
               ROUTES.SESSIONS,
               'sessions',
               this.adminRoles,
               { icon: 'address card outline' }
            )} */}

        {this.menuElement("jobs", ROUTES.JOBS, "jobs", this.adminRoles, {
          icon: "cogs",
        })}
        {this.menuElement(
          "inspections",
          ROUTES.INSPECTION,
          "inspection",
          this.adminRoles,
          { icon: "searchengin" }
        )}
        {this.menuElement(
          "environments",
          ROUTES.ENVIRONMENTS,
          "environments",
          this.adminRoles,
          { icon: "file video outline" }
        )}
        {this.menuElement("videos", ROUTES.VIDEO, "video360", this.adminRoles, {
          icon: "video",
        })}
        {this.menuElement("scenes", ROUTES.SCENES, "video3d", this.adminRoles, {
          icon: "microchip",
        })}
        {this.menuElement("medias", ROUTES.MEDIAS, "medias", this.adminRoles, {
          icon: "copy",
        })}
        {this.menuElement(
          "administration",
          ROUTES.ADMINISTRATION,
          "administration",
          ROLES.ROLE_ROOT,
          { icon: "cogs" }
        )}
        {this.menuElement("signout", ROUTES.SIGN_OUT, "logout", [], {
          icon: "log out",
        })}

        <Menu.Item
          name="record"
          position="right"
          active={this.state.activeItem === "record"}
          onClick={this.elementClicked}
          as={Link}
          to={ROUTES.USER_RECORD}
        >
          <Icon name="user circle" />
          {`${this.props.authUser.firstname} ${this.props.authUser.lastname}`}
          <br />
          {`${this.props.authUser.company.label}`}
          <br />
          <i>{`${this.lb._(this.props.authUser.roleLabel)}`}</i>
        </Menu.Item>
      </Menu>
    );
  }

  render() {
    const navbarFitsInViewport = this.state.viewportWidth > NAVBAR_MIN_SIZE;

    if (navbarFitsInViewport) {
      return this.renderNavbar();
    }

    return (
      <Burger
        isOpen={this.state.openMenu}
        onStateChange={(state) => this.setState({ openMenu: state.isOpen })}
        styles={hamburgerStyle}
        customBurgerIcon={
          <Icon
            name="bars"
            size="big"
            onClick={() => this.setState({ openMenu: true })}
          />
        }
      >
        {this.renderNavbar()}
      </Burger>
    );
  }
}

class NavigationNonAuth extends NavigationBase {
  render() {
    return (
      <Menu tabular icon="labeled" inverted color="grey">
        {this.menuElement("home", ROUTES.SIGN_IN, "home", [], {
          icon: "home",
        })}
      </Menu>
    );
  }
}

export default Navigation;
