import labels from '../../Common/translations'

export default [
   { key: '', label: '' },
   {
      key: 'templateMedia',
      label: labels.fields.specialUsageValues.templateMedia,
   },
   {
      key: 'icon',
      label: labels.fields.specialUsageValues.icon,
   },
   {
      key: 'preview',
      label: labels.fields.specialUsageValues.preview,
   },
   {
      key: 'overlayIcon',
      label: labels.fields.specialUsageValues.overlayIcon,
   },
]
