import React from 'react'
import { BackendService } from '../../types'

const FirebaseContext = React.createContext<BackendService | null>(null)

export const withFirebase = (
   Component: React.ComponentType<{ firebase: BackendService }>,
) => (props: any) => (
   <FirebaseContext.Consumer>
      {firebase => <Component {...props} firebase={firebase} />}
   </FirebaseContext.Consumer>
)

export default FirebaseContext
