import FormComponent from '../../Form'

export const speedSelector = (
   label,
   steps = [1, 2, 4, 8],
   defaultValue = 1,
   labelVariables = []
) => ({
   label,
   labelVariables,
   type: FormComponent.RANGE_SLIDE,
   mandatory: true,
   options: {
      defaultValue,
      max: steps.slice(-1),
      step: null,
      min: steps[0],
      marks: renderSteps(steps),
   },
})

const renderSteps = (steps = []) => {
   const obj = {}
   steps.forEach(step => (obj[step] = `${step}X`))
   return obj
}
