export const labelRenderer = (label, lang) => {
   if (typeof label === 'object') {
      return label[lang || labelHelper.lang]
   } else {
      return label
   }
}

export class labelHelper {
   static lang = 'en'
   constructor(labels, lang) {
      this.labels = labels
      if (lang) {
         labelHelper.lang = lang
      }
   }

   _(key, params = []) {
      if (params.length > 0) {
         const parts = this.part(key)
         let label = parts[labelHelper.lang] || 'error.missingLabel'
         params.map((val, i) => (label = label.replace(`%${i}`, val)))
         return label
      } else {
         const labels =
            key instanceof Object || typeof key === 'number'
               ? key
               : this.part(key)
         return this.renderer(labels)
      }
   }

   part(key = '') {
      const parts = key.split('.')
      let labels = this.labels
      parts.map(part => (labels = labels[part] || labels))
      return labels
   }

   renderer = (label, lang = labelHelper.lang) => {
      if (label instanceof Object) {
         return label[lang] || label.en
      } else {
         return label
      }
   }
}
