import FormComponent from '../../Form'
import Api from '../../Api'
import * as ROLES from '../../../constants/roles'
import labels from '../../Common/translations'
import { load, validate } from '../../Common/Schemas'
import { DateTime } from 'luxon'
import { dateFormat } from '../../Helpers'
import * as modes from './constants'

const hideAdvancedFields = data =>
   data.mode === '' || data.mode === modes.MANUAL

export default {
   fields: {
      mode: {
         label: labels.fields.type,
         mandatory: true,
         type: FormComponent.SELECT,
         values: [
            { key: '', label: '' },
            {
               key: modes.MANUAL,
               label: 'Manual',
            },
            {
               key: modes.AIRBORNE,
               label: 'Drone video',
            },
            {
               key: modes.VIDEO360,
               label: '360 Video',
            },
         ],
         options: {
            defaultValue: modes.MANUAL,
         },
      },
      name: {
         label: labels.fields.name,
         mandatory: true,
         searchable: true,
      },
      company: {
         label: labels.fields.company,
         type: FormComponent.SELECT,
         values: {
            from: 'state',
            prop: 'companyValues',
            collection: 'companies',
            source: () => Api.selectValues('companies', { fsort: 'name' }),
         },
         return: FormComponent.FORMAT_OBJECT,
         mandatory: true,
         disabled: ({ authUser }, { data }) =>
            (data.uid !== undefined && data.company !== '') ||
            !ROLES.ROLES_WITH_COMPANIES.includes(authUser.role),
         options: {
            defaultValue: ({ authUser }) =>
               !ROLES.ROLES_WITH_COMPANIES.includes(authUser.role)
                  ? authUser.company
                  : '',
         },
      },
      json: {
         type: FormComponent.FILE,
         label: labels.fields.json,
         mandatory: (_, { data }) => !hideAdvancedFields(data),
         hidden: (_, { data }) => hideAdvancedFields(data),
         options: {
            host: 'aws',
            folder: ({ authUser }, { data }) =>
               `${(data.company && data.company.ref) ||
                  authUser.company.ref}/inspections`,
            mimes: ['application/json'],
            onFinish: (e, d, form) => {
               try {
                  const reader = new FileReader()
                  reader.onload = async e => {
                     const schema = await load('inspection')
                     const json = JSON.parse(e.target.result)
                     validate(json, schema)
                     const { meta, medias, measurePoints } = json
                     const info = [
                        `Le fichier d'inspection est conforme et peut être importé`,
                     ] // first element is header
                     if (meta.localName) {
                        info.push(`L'inspection est nommée "${meta.localName}"`)
                     }
                     if (meta.timestamp) {
                        info.push(
                           `L'inspection a été réalisée le ${dateFormat(
                              meta.timestamp * 1000,
                              DateTime.DATETIME_MED_WITH_SECONDS
                           )} ${meta.operator && `par ${meta.operator}`}`
                        )
                     }
                     info.push(`${medias.length} medias déclarés à uploader`)
                     if (measurePoints) {
                        info.push(
                           `${measurePoints.length} mesure${
                              measurePoints.length > 1 ? 's' : ''
                           } à importer`
                        )
                     }
                     info.push(
                        `Si toutes ces informations vous semblent correctes, veuillez uploader les fichiers requis`
                     )
                     medias.forEach(media => {
                        Object.keys(media).forEach(filekey =>
                           info.push(media[filekey])
                        )
                     })
                     form.setState({ info })
                  }
                  reader.readAsText(d[0])
               } catch (error) {
                  alert(error)
               }
            },
         },
      },
      insv: {
         type: FormComponent.MULTIFILE,
         minFiles: 2,
         maxFiles: 2,
         label: labels.fields.files,
         labelSuffix: '.insv',
         mandatory: (_, { data }) => !hideAdvancedFields(data),
         hidden: (_, { data }) => hideAdvancedFields(data),
         options: {
            folder: ({ authUser }) => `${authUser.company.ref}/inspections`,
            mimes: ['application/octet-stream', ''],
            storage: 'aws',
         },
      },
      medias: {
         type: FormComponent.MULTIFILE,
         minFiles: 1,
         label: labels.fields.files,
         labelSuffix: '.mp4 / .jpg / .png',
         mandatory: (_, { data }) => hideAdvancedFields(data),
         hidden: (_, { data }) => !hideAdvancedFields(data),
         options: {
            folder: ({ authUser }) => `${authUser.company.ref}/inspections`,
            mimes: ['image/jpeg', 'image/png', 'video/mp4'],
            //storage: 'aws',
         },
      },
   },
   buttons: {
      delete: true,
      cancel: true,
      submit: true,
      extras: [],
   },
   actions: {
      create: data => Api.instance().post('inspections/', data),
      read: uid => Api.instance().get(`inspections/${uid}`),
      delete: ({ uid }) => Api.instance().delete('inspections/' + uid),
   },
   extras: [],
}
