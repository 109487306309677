import React from 'react'
import FormComponent from './index'
import _ from 'lodash'
import styled from 'styled-components'
import { Container, Form } from 'semantic-ui-react'

const StyledSearchForm = styled.div`
   margin-top: 10px;
   z-index: 1000;
   position: relative;
`

export default class SearchComponent extends FormComponent {
   static BEGINS_WITH = 'bw'
   static ENDS_WITH = 'ew'

   onSubmit = () => {
      this.props.propagate &&
         this.props.propagate({
            ...this.getFormData(),
            meta: this.getSearchOperators(this.props.form.fields),
         })
   }

   getSearchOperators = (fields, modes = {}) => {
      _.each(fields, (field, name) => {
         if (field.type === FormComponent.GROUP) {
            this.getSearchOperators(field.fields, modes)
         } else if (field.search) {
            modes[name] = field.search
         }
      })

      return modes
   }

   onCancel = () => {
      this.setState(
         {
            data: SearchComponent.initFieldsValues(
               this.props,
               this.state,
               false
            ),
            keyPrefix: `search_${Date.now()}`, // rerender form to avoid defaultValue stickiness
         },
         // careful, setState() is asynchronous
         () => {
            this.onSubmit()
         }
      )
   }

   getFilters = () => {
      return this.props.persistAs
         ? sessionStorage.getItem(this.props.persistAs)
         : this.getFormData()
   }

   render() {
      //      console.log(this.state)

      return (
         <Container>
            <StyledSearchForm>
               <Form>
                  {this.renderFields(this.props.form.fields)}
                  {this.renderButtons(this.props.form.buttons)}
               </Form>
            </StyledSearchForm>
         </Container>
      )
   }
}
