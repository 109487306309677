import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { Container } from 'semantic-ui-react'
import Api from '../Api'
import { AutoHeader } from '../Common'
import { withAuthentication } from '../Session'

class GuestOnboardingPage extends React.Component {
   constructor({ match }) {
      super()
      this.type = match.params.type
      this.hash = match.params.hash
      this.state = { message: 'pending...', loading: true }
   }

   static getDerivedStateFromProps(nextProps, prevState) {
      console.log('get derived', nextProps)
      if (nextProps.authUser !== prevState.authUser) {
         return { authUser: nextProps.authUser }
      }

      return null
   }

   componentDidUpdate() {
      console.log('update', this.props.authUser)
      if (this.props.authUser) {
         this.validateInvitation()
      }
   }

   async validateInvitation() {
      try {
         console.log(this.props.authUser)
         //this.setState({ loading: true })
         const res = await Api.instance().get(
            `onboarding/${this.type}/${this.hash}`,
         )
         console.log(res)
         this.setState({ message: res.statusCode })
      } catch (err) {
         console.log(err)
      }
   }

   render() {
      return (
         <Container>
            <AutoHeader />
            <Container>{this.state.message}</Container>
         </Container>
      )
   }
}

export default compose(withRouter, withAuthentication)(GuestOnboardingPage)
