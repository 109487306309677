import { initializeApp, getApp } from 'firebase/app'
import { Analytics, getAnalytics } from 'firebase/analytics'
import {
   Auth,
   getAuth,
   OAuthProvider,
   createUserWithEmailAndPassword,
   signInWithEmailAndPassword,
   sendPasswordResetEmail,
   signInWithPopup,
   updatePassword,
   sendSignInLinkToEmail,
} from 'firebase/auth'
import {
   Firestore,
   getFirestore,
   FieldValue,
   //connectFirestoreEmulator,
   doc,
   getDoc,
} from 'firebase/firestore'
import {
   FirebaseStorage,
   getStorage,
   ref,
   getDownloadURL,
} from 'firebase/storage'
import { Messaging /* getMessaging, isSupported */ } from 'firebase/messaging'
import {
   RemoteConfig,
   getRemoteConfig,
   ensureInitialized,
   fetchAndActivate,
   getValue,
} from 'firebase/remote-config'
import { FirebaseConfig, BackendService } from '../../types'

class Firebase implements BackendService {
   useEmulator: boolean
   auth: Auth
   db: Firestore
   storage: FirebaseStorage
   messaging: Messaging | null = null
   remoteConfig: RemoteConfig
   analytics: Analytics
   FieldValue: any = null
   constructor(config: FirebaseConfig, useEmulator = false) {
      this.useEmulator = useEmulator
      initializeApp(config)
      this.auth = getAuth()
      this.db = getFirestore()
      this.storage = getStorage()
      if (useEmulator === true) {
         //console.log(`Using local emulator for authentication & storage`)
         //this.auth.useEmulator('http://localhost:9099')
         //connectFirestoreEmulator(this.db, 'localhost', 5000)
         //this.storage.useEmulator('localhost', 9199)
      }
      //this.messaging = isSupported() ? getMessaging() : null
      this.analytics = getAnalytics()
      this.remoteConfig = getRemoteConfig()
      this.remoteConfig.settings = {
         minimumFetchIntervalMillis:
            // refresh frequency for remote config
            process.env.NODE_ENV !== 'production' ? 1800000 : 3600000,
         fetchTimeoutMillis: 10000,
      }

      ensureInitialized(this.remoteConfig)
         .then(() => {
            console.debug('Firebase Remote Config is initialized')
            fetchAndActivate(this.remoteConfig)
         })
         .catch(err => {
            console.error('Firebase Remote Config failed to initialize', err)
         })

      this.FieldValue = FieldValue
   }

   bucket = (bucket: string) => getStorage(getApp(), `gs://${bucket}`)

   getMediaUrl = (path: string) => getDownloadURL(ref(this.storage, path))

   // *** Auth API ***
   createProvider = (type: string) => new OAuthProvider(type)

   signInWithPopup = async (provider: any) =>
      await signInWithPopup(this.auth, provider)

   doCreateUserWithEmailAndPassword = (email: any, password: any) =>
      createUserWithEmailAndPassword(this.auth, email, password)

   doSignInWithEmailAndPassword = (email: string, password: string) =>
      signInWithEmailAndPassword(this.auth, email, password)

   doSendSignInLinkkToEmail = (email: string, params: any) =>
      sendSignInLinkToEmail(this.auth, email, params)

   doSignOut = () => this.auth.signOut()

   doPasswordReset = (email: string) => sendPasswordResetEmail(this.auth, email)

   doPasswordUpdate = (password: string) => {
      if (this.auth.currentUser) {
         return updatePassword(this.auth.currentUser, password)
      }
   }

   config = (key: string) => getValue(this.remoteConfig, key)

   // *** Merge Auth and DB User API *** //
   onAuthUserListener = (next: any, fallback: any) =>
      this.auth.onAuthStateChanged((authUser: any) => {
         if (this.useEmulator !== true) {
            fetchAndActivate(this.remoteConfig)
         }
         if (authUser) {
            // Api.instance()
            //    .get('me')
            //    .then(async (res: any) => {
            //       const doc = res.data
            //       let role = null
            //       let dbUser: any = null
            //       if (!doc) {
            //          console.log(`incomplete user profile`)
            //          const { claims = {} } =
            //             (await this.auth.currentUser?.getIdTokenResult(true)) ||
            //             {}
            //          role = claims.userType
            //       } else {
            //          dbUser = doc
            //          role = dbUser.userType
            //       }

            //       if (!role) {
            //          return this.doSignOut()
            //       }
            this.user(authUser.uid)
               .then(async (doc: any) => {
                  let role = null
                  let dbUser: any = null
                  if (!doc.exists) {
                     console.log(`incomplete user profile`)
                     const { claims = {} } =
                        (await this.auth.currentUser?.getIdTokenResult(true)) ||
                        {}
                     role = claims.role
                  } else {
                     dbUser = doc.data()
                     role = dbUser.role
                  }

                  if (!role) {
                     return this.doSignOut()
                  }

                  this.role(role).then((role: any) => {
                     // merge auth and db user
                     authUser = {
                        uid: authUser.uid,
                        token: authUser.token,
                        email: authUser.email,
                        roleLabel: role.get('label'),
                        ...dbUser,
                     }
                     next(authUser)
                  })

                  // merge auth and db user
                  // authUser = {
                  //    uid: authUser.uid,
                  //    token: authUser.token,
                  //    refreshToken: authUser.refreshToken,
                  //    email: authUser.email,
                  //    ...dbUser,
                  //    role,
                  // }
               })
               .catch((e: any) =>
                  console.log(`Error fetching user data: ${e.message}`)
               )
         } else {
            fallback()
         }
      })

   // *** User API ***

   user = (uid: string) => getDoc(doc(this.db, `users/${uid}`))

   role = (uid: string) => getDoc(doc(this.db, `roles/${uid}`))
}

export default Firebase
