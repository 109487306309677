import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import downloadForm from './config/download-form'
import { withAuthentication } from '../Session'
import { UpdateFormWidget } from '../Widgets'

class DownloadModifyPage extends React.Component {
   render() {
      return (
         <UpdateFormWidget
            form={downloadForm}
            id={this.props.match.params.id}
            beforeSave={payload => {
               return {
                  ...payload,
                  ...(payload.file.isTempFile && {
                     downloadCount: 0,
                     downloadLast: null,
                  }),
               }
            }}
            {...this.props}
         />
      )
   }
}

export default compose(withRouter, withAuthentication)(DownloadModifyPage)
