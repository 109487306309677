import labels from '../../Common/translations'

export default {
   fields: {
      delay: {
         label: labels.fields.delay_days_complete_execution,
         mandatory: true,
         options: {
            defaultValue: '0',
            label: 'Days',
            labelPosition: 'right',
         },
      },
   },
   buttons: {
      submit: true,
      cancel: true,
      extras: [],
   },
   actions: {
      create: ({ uid, ...data }) => null,
      redirect: ['', () => window.location.reload(false)],
   },
   extras: [],
}
