import * as ROUTES from '../../constants/routes'

const origin = 'microsoft.com'

export default async ({ firebase, history }, _state, form) => {
   try {
      form.setState({ dimmed: true })
      const provider = firebase.createProvider(origin)
      const response = await firebase.signInWithPopup(provider)
      //console.log(response)
      if (response.additionalUserInfo.isNewUser === true) {
         form.setState({
            dimmerMessage: `Please wait while we set up your account`,
         })
         // wait 5 sec for cloud function to perform
         await new Promise(r => setTimeout(r, 5000))
      }

      history.push(ROUTES.HOME)
   } catch (e) {
      alert(e.message)
   }
}
