export default {
   buttons: {
      submit: {
         negative: true,
         icon: 'delete',
         label: 'form.delete',
      },
      cancel: false,
      extras: [],
   },
   actions: {
      redirect: ['', () => window.location.reload(false)],
   },
   extras: [],
}
