import React, { useState } from 'react'
import useFetch from '../hooks/fetch'
import {
   Container,
   Dimmer,
   Divider,
   Grid,
   Header,
   Loader,
   Segment,
   Statistic,
   Tab,
} from 'semantic-ui-react'
import { ErrorMessage } from '..'
import Card from './card'
import BarChartHistory from './barChart'
import { labelHelper } from '../../Helpers'
import labels from '../translations'
import { formatBytes } from './utils'
import LinechartHistory from './lineChart'
import Filter from './filter'

const totalCalculations = data => {
   let total = 0
   data.map(e => {
      total += parseInt(e.total)
      return null
   })
   return total
}
export default function DlStats({ ref_id, api_path, ...props }) {
   const { data = {}, error, loading, refetch } = useFetch({
      params: { ref: ref_id },
      api_path,
   })
   const [filter, setFilter] = useState(null)
   const {
      currentDownloads = null,
      history = null,
      total = [],
      currentMonthHistory = [],
      userStats = [],
   } = data?.data || {}

   const lb = new labelHelper(
      labels,
      props.authUser ? props.authUser.lang : labelHelper.lang
   )
   const onFilter = data => {
      setFilter(data)
      refetch(data)
   }
   const reset = () => {
      setFilter(null)
      refetch()
   }
   return (
      <Dimmer.Dimmable as={Segment} blurring dimmed={loading}>
         <Dimmer active={loading} inverted>
            <Loader size="massive" indeterminate>
               {lb._(labels.form.loading)}
            </Loader>
         </Dimmer>

         {error && <ErrorMessage content={error} />}

         {data && (
            <Container>
               {/* <Grid centered columns={1}>
                  <Grid.Row centered columns={1}>
                     <Grid.Column>
                      
                     </Grid.Column>
                  </Grid.Row>
               </Grid> */}

               <Grid centered columns={6}>
                  <Grid.Column>
                     <Statistic size={'tiny'}>
                        <Statistic.Label>
                           {lb._(labels.dlstats.fields.total)}
                        </Statistic.Label>
                        <Statistic.Value>
                           {formatBytes(
                              parseFloat(total.length > 0 ? total[0].total : 0)
                           )}
                        </Statistic.Value>
                     </Statistic>
                  </Grid.Column>
               </Grid>

               <Divider />
               <Filter lb={lb} onFilter={onFilter} reset={reset} />
               <Divider />
               <Header as="h3">
                  {lb._(labels.dlstats.kpis.currentDownloads)}
               </Header>

               <Grid columns="equal">
                  <Grid.Column verticalAlign="middle">
                     <Card
                        value={totalCalculations(currentDownloads)}
                        label={lb._(labels.dlstats.kpis.total)}
                     />
                  </Grid.Column>
                  {currentDownloads &&
                     currentDownloads.map((e, index) => (
                        <Grid.Column key={index} verticalAlign="middle">
                           <Card value={e.total} label={e.type} />
                        </Grid.Column>
                     ))}
               </Grid>

               <Divider />

               <Tab
                  panes={[
                     {
                        menuItem: lb._(labels.dlstats.kpis.currentMonthHistory),
                        render: () => (
                           <Tab.Pane>
                              <Header as="h3">
                                 {lb._(labels.dlstats.kpis.currentMonthHistory)}
                              </Header>
                              <Grid>
                                 <LinechartHistory
                                    data={currentMonthHistory}
                                    lb={lb}
                                    filter={filter}
                                 />
                              </Grid>
                           </Tab.Pane>
                        ),
                     },
                     {
                        menuItem: lb._(labels.dlstats.kpis.history),
                        render: () => (
                           <Tab.Pane>
                              <Header as="h3">
                                 {lb._(labels.dlstats.kpis.history)}
                              </Header>
                              <Grid>
                                 <BarChartHistory
                                    data={history}
                                    lb={lb}
                                    userStats={userStats}
                                 />
                              </Grid>
                           </Tab.Pane>
                        ),
                     },
                  ]}
               />
            </Container>
         )}
      </Dimmer.Dimmable>
   )
}
