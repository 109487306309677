import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import FormComponent from '../Form'
import { Container } from 'semantic-ui-react'
import userForm from './config/user-form'
import { withAuthentication } from '../Session'
import { AutoHeader } from '../Common'

class UserModifyPage extends FormComponent {
   constructor(props) {
      super(props)
      this.state = { item: null, loading: true }
   }

   componentDidMount() {
      userForm.actions.read(this.props.match.params.id).then(res => {
         this.setState({
            item: res.data,
            loading: false,
         })
      })
   }

   render() {
      if (!this.state.item) {
         return <div />
      }
      return (
         <Container>
            <AutoHeader />
            <FormComponent
               form={userForm}
               data={this.state.item}
               {...this.props}
            />
         </Container>
      )
   }
}

export default compose(withRouter, withAuthentication)(UserModifyPage)
