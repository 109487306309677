import React from 'react'
import { dureeFormat, dateFormat, download } from '../../Helpers'
import Api from '../../Api'
import * as ROUTES from '../../../constants/routes'
import { Container, Header } from 'semantic-ui-react'
import FormComponent from '../../Form'
import accessForm from './accesslist-form'
import { ROLES } from '../../../constants'
import labels from '../../Common/translations'
import * as prettyBytes from 'pretty-bytes'

export default {
   endpoint: 'videos/*/vectos',
   // where to find the parent id
   pid: 'video.ref',
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: {
      name: {
         label: labels.fields.name,
         accessor: s => <p className="tablebreakSpaces">{s.name}</p>,
      },
      company: {
         label: labels.fields.company,
         accessor: ({ company }) => `${company ? company.label : ''}`,
         hidden: ({ authUser }) => authUser.role === 'admin',
         options: {
            maxWidth: '15%',
         },
      },
      createdBy: {
         label: labels.fields.created_by,
         accessor: s => `${s.createdBy ? s.createdBy.label : ''}`,
         options: {
            maxWidth: '10%',
         },
      },
      duration: {
         label: labels.fields.length,
         accessor: ({ duration }) =>
            duration ? dureeFormat(duration * 1000, true) : '?',
         options: {
            maxWidth: '5%',
         },
      },
      size: {
         label: labels.fields.size,
         accessor: ({ files }) =>
            prettyBytes(files && files.data && parseInt(files.data.size || 0)),
         options: {
            maxWidth: '5%',
         },
      },
      createdAt: {
         label: labels.fields.created_at,
         accessor: ({ createdAt }) => dateFormat(createdAt),
         sortable: true,
      },
      shares: {
         label: labels.fields.shares,
         accessor: ({ shares }) => shares || 0,
         options: {
            maxWidth: '5%',
         },
      },
   },
   buttons: {
      show: {
         label: labels.fields.show,
         url: ROUTES.SCENE_SHOW,
         options: {
            icon: 'eye',
            positive: true,
         },
      },
      download: {
         disabled: ({ status }) => status !== 'completed',
         hidden: (data, { authUser }) => authUser.role !== ROLES.ROLE_ROOT,
         action: ({ status, files, name }) => {
            if (status === 'completed') {
               download(files.data, files.data.label || `${name}.zip`)
            }
         },
         label: labels.fields.downloads,
         options: {
            icon: 'download',
            primary: true,
         },
      },
      access: {
         disabled: data => data.status !== 'completed',
         // display the contextual access list form in the screen modal
         // update the update action to provide the matching path
         action: async ({ video, ...rowData }, props) => {
            accessForm.actions.update = ({ uid, ...data }) =>
               Api.instance().patch(`${video.ref}/vectos/${uid}`, data)

            // open modal with contextual form content
            props.parent.setState({
               open: true,
               content: (
                  <Container>
                     <Header as="h4" block>
                        {`Vecto ${rowData.name} (${rowData.company.label})`}
                     </Header>
                     <FormComponent
                        form={accessForm}
                        data={rowData}
                        {...props}
                     />
                  </Container>
               ),
            })
         },
         label: labels.fields.access_list,
         options: {
            icon: 'lock',
            primary: true,
         },
      },
      revecto: {
         label: labels.fields.revecto,
         options: {
            icon: 'redo',
            primary: true,
         },
         // passed params are current record data and page props
         action: ({ uid, video }) => {
            if (!video) return
            const uri = `/videos3d/${video.ref.split('/')[1]}/${uid}/revecto`

            Api.instance()
               .post(uri)
               .then(() => window.location.reload())
               .catch(err => err.message)
         },
         disabled: row => {
            return !(
               !row.files ||
               !row.files.data ||
               !row.files.mp4 ||
               !row.files.bson ||
               !row.files.json
            )
         },
      },
      delete: {
         label: labels.fields.delete,
         options: {
            icon: 'delete',
            negative: true,
         },
         // passed params are current record data and page props
         action: ({ uid, video }) => {
            const uri = video ? `${video.ref}/vectos/${uid}` : `vectos/${uid}`
            Api.instance()
               .delete(uri)
               .then(() => window.location.reload())
               .catch(err => err.message)
         },
      },
   },
   actions: {
      list: params => {
         return Api.instance().get('videos3d', params)
      },
   },
}
