import { dateFormat } from "../../Helpers";
import { ROLES_WITH_COMPANIES } from "../../../constants/roles";
import Api from "../../Api";
import labels from "../../Common/translations";

export default {
  endpoint: "jobs",
  defaultSorting: { field: "createdAt", order: "desc" },

  columns: {
    name: {
      label: labels.fields.name,
      accessor: (s) => s.name,
      format: (row) => <p className="tablebreakSpaces">{row.name}</p>,
      options: {
        maxWidth: "15%",
      },
    },
    type: {
      label: labels.fields.type,
      accessor: (s) => s.type,
      options: {
        maxWidth: "5%",
      },
    },
    company: {
      label: labels.fields.company,
      accessor: (s) => `${s.company ? s.company.label : ""}`,
      hidden: ({ authUser }) => !ROLES_WITH_COMPANIES.includes(authUser.role),

      format: (row) => (
        <p className="tablebreakSpaces">{`${
          row.company ? row.company.label : ""
        }`}</p>
      ),
      options: {
        maxWidth: "20%",
      },
    },

    sourceType: {
      label: labels.fields.sourceType,
      accessor: (s) => s.sourceType,
      options: {
        maxWidth: "5%",
      },
    },
    source: {
      label: labels.fields.source,
      accessor: (s) => s.source.label,
      options: {
        maxWidth: "5%",
      },
    },

    target: {
      label: labels.fields.target,
      accessor: (s) => s.target.label,
      options: {
        maxWidth: "5%",
      },
    },
    // speed: {
    //   label: labels.fields.speed,
    //   accessor: (s) => s.speed,
    //   options: {
    //     maxWidth: "5%",
    //   },
    // },
    createdAt: {
      label: labels.fields.created_at,
      accessor: (row) => dateFormat(row.createdAt),
      sortable: true,
      options: {
        maxWidth: "15%",
      },
    },
  },
  buttons: {
    // show: {
    //   label: labels.fields.show,
    //   url: ROUTES.ENVIRONMENTS_SHOW,
    //   options: {
    //     icon: "eye",
    //     positive: true,
    //   },
    // },
  },
  actions: {
    list: (params) => {
      return Api.instance().get("jobs", params);
    },
  },
};
