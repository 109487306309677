import React from 'react'
import { withRouter } from 'react-router-dom'
import { withFirebase } from '../Firebase'
import { compose } from 'recompose'
import FormComponent from '../Form'
import userForm from './config/user-form'
import { Container } from 'semantic-ui-react'
import { withAuthentication } from '../Session'
import { AutoHeader } from '../Common'

class UserCreatePage extends React.Component {
   render() {
      return (
         <Container>
            <AutoHeader />
            <FormComponent form={userForm} {...this.props} />
         </Container>
      )
   }
}

export default compose(
   withRouter,
   withFirebase,
   withAuthentication,
)(UserCreatePage)
