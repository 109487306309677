import React from 'react'
import { withAuthorization, withAuthentication } from '../Session'
import { compose } from 'recompose'
import { ROLES, ROUTES } from '../../constants'
import { Switch } from 'react-router-dom'
import InspectionShowPage from './show'
import InspectionCreatePage from './create'
import InspectionJobPage from './job'
import { Container, Divider, Grid, Icon, Menu, Tab } from 'semantic-ui-react'
import SearchComponent from '../Form/search'
import TableComponent from '../Table'
import list from './config/inspections-list'
import wlist from '../Workflow/config/workflow-list'
import search from './config/inspection-search'
import {
   AutoHeader,
   TranslatedButton,
   Page,
   MandatoryWarning,
   AutoLabel,
} from '../Common'
import { pageKey } from '../Helpers'
import { TabWidget } from '../Widgets'
import { labelHelper } from '../Helpers'
import labels from '../Common/translations'

const InspectionPage = () => (
   <div>
      <Switch>
         <Page
            exact
            path={ROUTES.INSPECTION}
            component={VideoList}
            title="screens.inspections"
         />
         <Page
            path={ROUTES.INSPECTION_SHOW}
            component={InspectionShowPage}
            title="screens.inspection_details"
         />
         <Page
            path={ROUTES.INSPECTION_CREATE}
            component={InspectionCreatePage}
            title="screens.inspection_create"
         />
         <Page
            path={ROUTES.INSPECTION_JOB}
            component={InspectionJobPage}
            title="screens.inspection_job"
         />
      </Switch>
   </div>
)

class InspectionListBase extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         loading: false,
         data: [],
         reset: false,
         perPage: 10,
         activeItem: undefined,
      }
      this.storeKey = `${pageKey()}_filters`
   }

   lb = new labelHelper(
      labels,
      this.props.authUser ? this.props.authUser.lang : labelHelper.lang
   )

   /**
    * Set or get page shared components filters
    */
   filters = (filters = null) => {
      if (filters) {
         console.log('Setting new filters', filters)
         sessionStorage.setItem(this.storeKey, JSON.stringify(filters))
         this.setState({ filters })
      } else {
         return sessionStorage.getItem(this.storeKey)
            ? JSON.parse(sessionStorage.getItem(this.storeKey))
            : undefined
      }
   }

   handleItemClick = (e, data) => {
      this.setState({ activeItem: data.name })
      sessionStorage.setItem('activeSubItemNavigation', data.name)
   }

   panes = [
      {
         key: 'pendingInspections',
         menuItem: this.lb._(labels.screens.pendingInspections),
         render: () => (
            <Tab.Pane key="pendingInspections">
               {this.shouldRender() ? (
                  <TableComponent
                     list={list}
                     filters={{
                        ...this.filters(),
                        status: ['completed', '!='],
                     }}
                     {...this.props}
                  />
               ) : (
                  <MandatoryWarning />
               )}
            </Tab.Pane>
         ),
      },
      {
         key: 'completedInspections',
         menuItem: this.lb._(labels.screens.completedInspections),
         render: () => (
            <Tab.Pane key="completedInspections">
               {this.shouldRender() ? (
                  <TableComponent
                     list={list}
                     filters={{ ...this.filters(), status: ['completed'] }}
                     {...this.props}
                  />
               ) : (
                  <MandatoryWarning />
               )}
            </Tab.Pane>
         ),
      },
   ]

   render() {
      const { activeItem = 'inspection' } = this.state

      return (
         <Container>
            <Container>
               <Grid columns="equal">
                  <Grid.Column>
                     <Menu vertical inverted color="grey">
                        <Menu.Item
                           name="inspection"
                           active={activeItem === 'inspection'}
                           onClick={this.handleItemClick}
                        >
                           <Icon name="searchengin" />
                           <AutoLabel labelKey="screens.inspection" />
                        </Menu.Item>

                        <Menu.Item
                           name="workflow"
                           active={activeItem === 'workflow'}
                           onClick={this.handleItemClick}
                        >
                           <Icon name="tasks" />
                           <AutoLabel labelKey="screens.workflow" />
                        </Menu.Item>
                     </Menu>
                     <AutoHeader key={`screens.${activeItem}`} />
                  </Grid.Column>
                  <Grid.Column textAlign="right">
                     {activeItem === 'inspection' && (
                        <TranslatedButton
                           link={ROUTES.INSPECTION_CREATE}
                           icon="search plus"
                           label="screens.inspection_create"
                        />
                     )}
                     {activeItem === 'workflow' && (
                        <TranslatedButton
                           link={ROUTES.WORKFLOW_CREATE}
                           icon="tasks"
                           label="screens.workflow_create"
                        />
                     )}
                  </Grid.Column>
               </Grid>
            </Container>
            {activeItem === 'inspection' && (
               <Container>
                  <SearchComponent
                     form={search}
                     data={this.filters()}
                     {...this.props}
                     propagate={filters => this.filters(filters)}
                  />
                  <Divider />
                  <TabWidget panes={this.panes} />
               </Container>
            )}
            {activeItem === 'workflow' && (
               <Container>
                  <SearchComponent
                     form={search}
                     data={this.filters()}
                     {...this.props}
                     propagate={filters => this.filters(filters)}
                  />
                  <Divider />
                  <TableComponent
                     list={wlist}
                     filters={{
                        ...this.filters(),
                     }}
                     {...this.props}
                  />{' '}
               </Container>
            )}
         </Container>
      )
   }

   /**
    * Should the content be rendered ?
    * if user is partner, they must choose a company
    */
   shouldRender() {
      return (
         (this.filters() && this.filters().company) ||
         [ROLES.ROLE_ROOT, ROLES.ROLE_ADMIN].includes(this.props.authUser.role)
      )
   }
}

const condition = authUser => authUser // && !!authUser.roles[ROLES.ADMIN]

const VideoList = withAuthentication(InspectionListBase)

export default compose(withAuthorization(condition))(InspectionPage)
