import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import {
   Container,
   Header,
   Button,
   Icon,
   Divider,
   Modal,
   Tab,
   List,
} from 'semantic-ui-react'
import FormComponent from '../Form'
import TableComponent from '../Table'
import vectosList from './config/vectos-list'
import mediasList from './config/medias-list'
import versionForm from './config/version-form'
import { AutoLabel, EventsList } from '../Common'
import { withAuthentication } from '../Session'
import { ROUTES } from '../../constants'
import { TabWidget } from '../Widgets'
import { labelHelper } from '../Helpers'
import labels from '../Common/translations'
import Api from '../Api'

const TreeIcons = {
   Project: 'folder',
   Dashboard: 'dashboard',
   Presentation: 'tv',
   Overlay: 'move',
}

class EnvironmentTreePage extends React.Component {
   constructor(props) {
      super(props)
      this.state = { open: false, tree: [] }
      this.goBack = this.goBack.bind(this)
      this.modalRef = React.createRef()
   }

   lb = new labelHelper(
      labels,
      this.props.authUser ? this.props.authUser.lang : labelHelper.lang
   )

   async componentDidMount() {
      const { pid, id } = this.props.match.params
      const path = `environments/${pid}/members/${id}`

      this.setState({ loading: true })
      const { data: item } = await versionForm.actions.read(
         this.props.match.params
      )

      const { data: tree } = await Api.instance().get(
         `environments/${pid}/versions/${id}/tree`
      )

      this.setState({
         item,
         loading: false,
         path,
         tree,
      })
   }

   goBack() {
      this.props.history.push(ROUTES.ENVIRONMENTS)
   }

   accessList = () => {
      this.setState({ open: true })
   }

   close = () => this.setState({ open: false })

   renderTree(tree = [], subtree = false) {
      return (
         <List celled size="medium">
            {tree.map(member => this.renderContainer(member))}
         </List>
      )
   }

   renderContainer(data) {
      return (
         <List.Item key={data.uid}>
            <List.Icon name={TreeIcons[data.type]} />
            <List.Content>
               <List.Header>{data.name}</List.Header>
               <List.Description>{data.type}</List.Description>
               {data.children && (
                  <List.List>
                     {data.children.map(child =>
                        this.renderContainer(child, 'question circle')
                     )}
                  </List.List>
               )}
            </List.Content>
         </List.Item>
      )
   }

   panes = [
      {
         key: 'tree',
         menuItem: this.lb._(labels.screens.environment_show.versionTree),
         render: () => (
            <Tab.Pane key="tree">{this.renderTree(this.state.tree)}</Tab.Pane>
         ),
      },
      {
         key: 'details',
         menuItem: this.lb._(labels.screens.detail),
         render: () => (
            <Tab.Pane key="details">
               <FormComponent
                  form={versionForm}
                  readOnly={true}
                  data={this.state.item}
                  {...this.props}
               />
            </Tab.Pane>
         ),
      },
      {
         key: 'scenes',
         menuItem: this.lb._(labels.screens.environment_show.usedVectos),
         render: () => (
            <Tab.Pane key="scenes">
               <TableComponent
                  title={
                     <AutoLabel labelKey="screens.environment_show.usedVectos" />
                  }
                  list={vectosList}
                  baseRef={`environments/${this.props.match.params.pid}`}
                  filters={{ version: [this.state.path, '==', 'ref'] }}
                  {...this.props}
               />
            </Tab.Pane>
         ),
      },
      {
         key: 'medias',
         menuItem: this.lb._(labels.screens.environment_show.usedMedias),
         render: () => (
            <Tab.Pane key="medias">
               <TableComponent
                  title={
                     <AutoLabel labelKey="screens.environment_show.usedMedias" />
                  }
                  list={mediasList}
                  baseRef={`environments/${this.props.match.params.pid}`}
                  filters={{ version: [this.state.path, '==', 'ref'] }}
                  {...this.props}
               />
            </Tab.Pane>
         ),
      },
      {
         key: 'event',
         menuItem: this.lb._(labels.fields.event),
         render: () => (
            <Tab.Pane key="event">
               <EventsList
                  labelKey="screens.relatedEvents"
                  hideObjectColumn={true}
                  condition={{
                     object: [this.state.path, '==', 'ref'],
                  }}
                  {...this.props}
               />
            </Tab.Pane>
         ),
      },
   ]

   render() {
      if (!this.state.item || !this.state.tree) {
         return <div />
      }

      const {
         item: { name },
      } = this.state
      return (
         <Container>
            <Container>
               <Header as="h1">{name}</Header>
               <Button primary icon labelPosition="left" onClick={this.goBack}>
                  <Icon name="left arrow" />
                  <AutoLabel labelKey="form.back" />
               </Button>
               <Divider hidden />
               <Container>
                  <Modal
                     open={this.state.open}
                     ref={this.modalRef}
                     closeIcon
                     content={this.state.content}
                     onClose={this.close}
                     closeOnEscape={false}
                     header={this.state.modalHeader}
                  />
               </Container>
               <TabWidget panes={this.panes} />
            </Container>
         </Container>
      )
   }
}

export default compose(withRouter, withAuthentication)(EnvironmentTreePage)
