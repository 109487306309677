import React from 'react'
import labels from './translations'
import { labelHelper } from '../Helpers'
import { withAuthentication } from '../Session'

/**
 * Generated an automatic label based on given key and user's lang
 */
class AutoLabel extends React.Component {
   constructor(props) {
      super(props)
      this.props = props
      this.lb = new labelHelper(
         labels,
         this.props.authUser ? this.props.authUser.lang : labelHelper.lang
      )
   }

   render() {
      return this.lb._(this.props.labelKey, this.props.params) || 'No Label'
   }
}

export default withAuthentication(AutoLabel)
