import React from 'react'
import { withAuthorization, withAuthentication } from '../Session'
import { compose } from 'recompose'
import { ROLES, ROUTES } from '../../constants'
import { Switch } from 'react-router-dom'
import VideoShowPage from './show'
import VideoCreatePage from './create'
import VideoJobPage from './job'
import VideoModifyPage from './modify'
import VideoVectoPage from './vecto'
import { Container, Divider, Grid, Modal, Tab } from 'semantic-ui-react'
import SearchComponent from '../Form/search'
import TableComponent from '../Table'
import list from './config/videos-list'
import jobsList from './config/videos-jobs-list'
import search from './config/video-search'
import { AutoHeader, TranslatedButton, Page, MandatoryWarning } from '../Common'
import { pageKey } from '../Helpers'
import { TabWidget } from '../Widgets'
import { labelHelper } from '../Helpers'
import labels from '../Common/translations'

const VideoPage = () => (
   <div>
      <Switch>
         <Page
            exact
            path={ROUTES.VIDEO}
            component={VideoList}
            title="screens.video360"
         />
         <Page
            path={ROUTES.VIDEO_SHOW}
            component={VideoShowPage}
            title="screens.video360_show.details"
         />
         <Page
            path={ROUTES.VIDEO_CREATE}
            component={VideoCreatePage}
            title="screens.video360_create"
         />
         <Page
            path={ROUTES.VIDEO_JOB}
            component={VideoJobPage}
            title="screens.video360_job"
         />
         <Page
            path={ROUTES.VIDEO_MODIFY}
            component={VideoModifyPage}
            title="screens.video360_modify"
         />
         <Page
            path={ROUTES.VIDEO_VECTO}
            component={VideoVectoPage}
            title="screens.video360_vecto"
         />
      </Switch>
   </div>
)

class VideoListBase extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         loading: false,
         data: [],
         reset: false,
         perPage: 10,
         open: false,
      }
      this.storeKey = `${pageKey()}_filters`
   }

   lb = new labelHelper(
      labels,
      this.props.authUser ? this.props.authUser.lang : labelHelper.lang
   )

   /**
    * Set or get page shared components filters
    */
   filters = (filters = null) => {
      if (filters) {
         console.log('Setting new filters', filters)
         sessionStorage.setItem(this.storeKey, JSON.stringify(filters))
         this.setState({ filters })
      } else {
         return sessionStorage.getItem(this.storeKey)
            ? JSON.parse(sessionStorage.getItem(this.storeKey))
            : undefined
      }
   }

   panes = [
      {
         key: 'completedVideos',
         menuItem: this.lb._(labels.screens.completedVideos),
         render: () => (
            <Tab.Pane key="completedVideos">
               {this.shouldRender() ? (
                  <TableComponent
                     list={list}
                     filters={{ ...this.filters(), status: ['completed'] }}
                     {...this.props}
                     parent={this} // give access to page context so button can change state and display modal
                  />
               ) : (
                  <MandatoryWarning />
               )}
            </Tab.Pane>
         ),
      },
      {
         key: 'pendingVideos',
         menuItem: this.lb._(labels.screens.pendingVideos),
         render: () => (
            <Tab.Pane key="pendingVideos">
               {this.shouldRender() ? (
                  <TableComponent
                     list={jobsList}
                     filters={{
                        status: ['pending,queued,converting,downloading', 'in'],
                        ...this.filters(),

                     }}
                     {...this.props}
                  />
               ) : (
                  <MandatoryWarning />
               )}
            </Tab.Pane>
         ),
      },
   ]

   render() {
      return (
         <Container>
            <Grid columns="equal">
               <Grid.Column>
                  <AutoHeader />
               </Grid.Column>
               <Grid.Column textAlign="right">
                  <TranslatedButton
                     link={ROUTES.VIDEO_CREATE}
                     icon="video"
                     label="screens.video360_create"
                  />
                  <TranslatedButton
                     link={ROUTES.VIDEO_JOB}
                     icon="plus"
                     label="screens.video360_job"
                  />
               </Grid.Column>
            </Grid>
            <Container>
               <SearchComponent
                  form={search}
                  data={this.filters()}
                  {...this.props}
                  propagate={filters => this.filters(filters)}
               />
            </Container>

            <Modal
               open={this.state.open}
               ref={this.modalRef}
               closeIcon
               content={this.state.content}
               onClose={() => this.setState({ open: false })}
               closeOnEscape={false}
               header="Default Access List Management"
            />
            <Divider />
            <TabWidget panes={this.panes} />
         </Container>
      )
   }

   /**
    * Should the content be rendered ?
    * if user is partner, they must choose a company
    */
   shouldRender() {
      return (
         (this.filters() && this.filters().company) ||
         [ROLES.ROLE_ROOT, ROLES.ROLE_ADMIN].includes(this.props.authUser.role)
      )
   }
}

const condition = authUser => authUser // && !!authUser.roles[ROLES.ADMIN]

const VideoList = withAuthentication(VideoListBase)

export default compose(withAuthorization(condition))(VideoPage)
