import React from 'react'
import { formatBytes } from './utils'
import { Statistic, Card } from 'semantic-ui-react'

export default function CardContainer({ label, value, size = 'mini' }) {
   return (
      <Card>
         <Card.Content>
            <Card.Header>{label}</Card.Header>
            <Card.Description>
               <Statistic size={size}>
                  <Statistic.Value>
                     {formatBytes(parseFloat(value))}
                  </Statistic.Value>
               </Statistic>
            </Card.Description>
         </Card.Content>
      </Card>
   )
}
