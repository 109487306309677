import labels from '../../Common/translations'

export default {
   pagesize: 15,
   defaultSorting: { field: 'lastname', order: 'desc' },
   columns: {
      email: {
         label: labels.fields.email,
         options: {
            maxWidth: '20%',
         },
      },
      firstname: {
         label: labels.fields.firstname,
         options: {
            maxWidth: '15%',
         },
      },
      lastname: {
         label: labels.fields.lastname,
         options: {
            maxWidth: '15%',
         },
      },
      company: {
         label: labels.fields.company,
         accessor: s => s.company && s.company.label,
         options: {
            maxWidth: '50%',
         },
      },
   },
   actions: {
      list: () => ({
         data: [],
      }),
   },
}
