import { dateFormat } from '../../Helpers'
import * as ROUTES from '../../../constants/routes'
import Api from '../../Api'
import labels from '../../Common/translations'

export default {
   endpoint: 'inspections',
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: {
      name: {
         label: labels.fields.name,
         accessor: s => `${s.name}`,
      },
      company: {
         label: labels.fields.company,
         accessor: s => `${s.company ? s.company.label : ''}`,
         hidden: ({ authUser }) => authUser.role === 'admin',
      },
      mode: {
         label: labels.fields.type,
         accessor: s => s.mode,
         options: {
            maxWidth: '25px',
         },
      },
      // resolution: {
      //    label: labels.fields.resolution,
      //    format: ({ width, height }) => (width ? `${width}x${height}` : ''),
      //    options: {
      //       maxWidth: '25px',
      //    },
      // },
      // size: {
      //    label: labels.fields.size,
      //    accessor: s => (s.size ? prettyBytes(parseInt(s.size)) : ''),
      //    options: {
      //       maxWidth: '25px',
      //    },
      // },
      createdAt: {
         label: labels.fields.created_at,
         format: row => dateFormat(row.createdAt),
         sortable: true,
         options: {
            maxWidth: '150px',
         },
      },
   },
   buttons: {
      show: {
         label: labels.fields.show,
         url: ROUTES.INSPECTION_SHOW,
         options: {
            icon: 'eye',
            positive: true,
         },
         disabled: data => data.pending === true,
      },
      modify: {
         label: labels.fields.modify,
         url: ROUTES.VIDEO_MODIFY,
         options: {
            icon: 'edit',
            primary: true,
         },
         disabled: data => data.status !== 'pending',
      },
      workflow: {
         label: labels.fields.job,
         url: ROUTES.INSPECTION_JOB,
         options: {
            icon: 'cog',
            color: 'orange',
         },
         disabled: data => data.status !== 'pending',
      },
   },
   actions: {
      list: params => Api.instance().get('inspections', params),
   },
}
