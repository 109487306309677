import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import FormComponent from '../Form'
import { Container, Grid, Divider, Button, Icon } from 'semantic-ui-react'
import sessionForm from './config/session-form'
import { withAuthentication } from '../Session'
import { AutoHeader, AutoLabel } from '../Common'

class SessionShowPage extends React.Component {
   constructor(props) {
      super(props)
      this.state = { item: null }
      this.sessionRef = `sessions/${this.props.match.params.id}`
      this.goBack = this.goBack.bind(this)
   }

   goBack() {
      this.props.history.goBack()
   }

   componentDidMount() {
      this.setState({ loading: true })
      sessionForm.actions.read(this.props.match.params.id).then(item => {
         this.setState({
            item: this.levelData(item),
            loading: false,
         })
      })
   }

   levelData = item => {
      const { user, company, env, meta, preferences, ...rest } = item

      return {
         user,
         company,
         ...env,
         ...rest,
         ...meta,
      }
   }

   render() {
      if (!this.state.item) {
         return false
      }

      return (
         <Container>
            <Grid columns="equal">
               <Grid.Column>
                  <AutoHeader />
               </Grid.Column>
               <Grid.Column textAlign="right">
                  <Button
                     primary
                     icon
                     labelPosition="left"
                     onClick={this.goBack}
                  >
                     <Icon name="left arrow" />
                     <AutoLabel labelKey="form.back" />
                  </Button>
               </Grid.Column>
            </Grid>
            <Divider hidden />
            <Fragment>
               <Container>
                  <FormComponent
                     form={sessionForm}
                     readOnly={true}
                     data={this.state.item}
                     {...this.props}
                  />
               </Container>
            </Fragment>
         </Container>
      )
   }
}

export default compose(withRouter, withAuthentication)(SessionShowPage)
