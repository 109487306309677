import { dateFormat } from '../../Helpers'
import * as ROUTES from '../../../constants/routes'
import Api from '../../Api'
import labels from '../../Common/translations'

export default {
   endpoint: 'workflows',
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: {
      name: {
         label: labels.fields.name,
         accessor: s => `${s.name}`,
      },
      key: {
         label: labels.fields.key,
         accessor: s => s.key,
         options: {
            maxWidth: '50px',
         },
      },
      engine: {
         label: labels.fields.engine,
         accessor: s => s.engine,
         options: {
            maxWidth: '50px',
         },
      },
      mode: {
         label: labels.fields.type,
         accessor: s => s.mode,
         options: {
            maxWidth: '25px',
         },
      },
      createdAt: {
         label: labels.fields.created_at,
         format: row => dateFormat(row.createdAt),
         sortable: true,
         options: {
            maxWidth: '150px',
         },
      },
   },
   buttons: {
      show: {
         label: labels.fields.show,
         url: ROUTES.INSPECTION_SHOW,
         options: {
            icon: 'eye',
            positive: true,
         },
         disabled: data => data.pending === true,
      },
      modify: {
         label: labels.fields.modify,
         url: ROUTES.VIDEO_MODIFY,
         options: {
            icon: 'edit',
            primary: true,
         },
         disabled: data => data.pending === true,
      },
   },
   actions: {
      list: params => Api.instance().get('workflows', params),
   },
}
