import React from 'react'
import { withAuthentication } from '../Session'
import * as ROUTES from '../../constants/routes'
import { Switch } from 'react-router-dom'
import MediaModifyPage from './modify'
import MediaShowPage from './show'
import MediaCreatePage from './create'
import { Container, Modal, Tab, Divider, Grid } from 'semantic-ui-react'
import { TabWidget } from '../Widgets'
import TableComponent from '../Table'
import SearchComponent from '../Form/search'
import search from './config/search'
import mediasList from './config/medias-list'
import { AutoHeader, MandatoryWarning, Page, TranslatedButton } from '../Common'
import SearchableList from '../Common/searchableList'
import { ROLES } from '../../constants'
import { labelHelper } from '../Helpers'
import labels from '../Common/translations'
import GridComponent from '../Grid'
import mediasGrid from './config/medias-grid'

const MediasPage = () => (
   <div>
      <Switch>
         <Page
            exact
            path={ROUTES.MEDIAS}
            component={MediasList}
            title="screens.medias"
         />
         <Page
            path={ROUTES.MEDIAS_CREATE}
            component={MediaCreatePage}
            title="screens.medias_create"
         />
         <Page
            path={ROUTES.MEDIAS_SHOW}
            component={MediaShowPage}
            title="screens.medias_show.details"
         />
         <Page
            path={ROUTES.MEDIAS_MODIFY}
            component={MediaModifyPage}
            title="screens.medias_show.details"
         />
      </Switch>
   </div>
)

class MediasListBase extends SearchableList {
   constructor(props) {
      super(props)
      this.state = {
         loading: true,
         open: false,
      }
      this.modalRef = React.createRef()
   }

   lb = new labelHelper(
      labels,
      this.props.authUser ? this.props.authUser.lang : labelHelper.lang
   )

   close = () => this.setState({ open: false })

   panes = [
      {
         key: 'completed',
         menuItem: this.lb._(labels.screens.completedMedias),
         render: () => (
            <Tab.Pane key="completed">
               {this.shouldRender() ? (
                  <TableComponent
                     list={mediasList}
                     {...this.props}
                     filters={{ ...this.filters(), status: ['completed'] }}
                     modal={this.modalRef}
                     parent={this}
                  />
               ) : (
                  <MandatoryWarning />
               )}
            </Tab.Pane>
         ),
      },
      {
         key: 'grid',
         menuItem: this.lb._(labels.screens.completedMediasGrid),
         render: () => (
            <Tab.Pane key="grid">
               {this.shouldRender() ? (
                  <GridComponent
                     list={mediasGrid}
                     {...this.props}
                     filters={{ ...this.filters(), status: ['completed'] }}
                     modal={this.modalRef}
                     parent={this}
                  />
               ) : (
                  <MandatoryWarning />
               )}
            </Tab.Pane>
         ),
      },
      {
         key: 'pending',
         menuItem: this.lb._(labels.screens.pendingMedias),
         render: () => (
            <Tab.Pane key="pending">
               {this.shouldRender() ? (
                  <TableComponent
                     list={mediasList}
                     {...this.props}
                     filters={{
                        ...this.filters(),
                        status: ['pending'],
                     }}
                     modal={this.modalRef}
                     parent={this}
                  />
               ) : (
                  <MandatoryWarning />
               )}
            </Tab.Pane>
         ),
      },
   ]

   render() {
      return (
         <Container>
            <Container>
               <Grid columns="equal">
                  <Grid.Column>
                     <AutoHeader />
                  </Grid.Column>
                  <Grid.Column textAlign="right">
                     {['root', 'partner', 'admin'].includes(
                        this.props.authUser.role
                     ) && (
                        <TranslatedButton
                           link={ROUTES.MEDIAS_CREATE}
                           icon="file"
                           label="screens.medias_create"
                           disabled={this.state.cantCreateCompanies}
                        />
                     )}
                  </Grid.Column>
               </Grid>
               <SearchComponent
                  form={search}
                  data={this.filters()}
                  {...this.props}
                  propagate={filters => this.filters(filters)}
               />
               <Modal
                  open={this.state.open}
                  ref={this.modalRef}
                  closeIcon
                  content={this.state.content}
                  onClose={this.close}
                  closeOnEscape={false}
                  header={this.state.modalHeader}
               />
            </Container>

            <Divider hidden />
            <TabWidget panes={this.panes} />
         </Container>
      )
   }

   /**
    * Should the content be rendered ?
    * if user is partner, they must choose a company
    */
   shouldRender() {
      return (
         (this.filters() && this.filters().company) ||
         [ROLES.ROLE_ROOT, ROLES.ROLE_ADMIN].includes(this.props.authUser.role)
      )
   }
}

const MediasList = withAuthentication(MediasListBase)

export default MediasPage
