import React from 'react'
import {
   Button,
   Dimmer,
   Segment,
   Loader,
   Confirm,
   Container,
   Grid,
   Divider,
} from 'semantic-ui-react'
import _ from 'lodash'
import { ErrorMessage } from '../Common'
import labels from '../Common/translations'
import { labelHelper, labelFormat } from '../Helpers'
import hash from 'object-hash'

export default class GridComponent extends React.Component {
   static PID_PLACEHOLDER = '-'
   columns = []
   constructor(props) {
      super(props)
      this.state = {
         loading: true,
         error: null,
         data: [],
         reset: false,
         perPage: this.props.list.pagesize || 9,
         currentSize: 'small',
         modalOpen: false,
         modalRefresh: false,
      }
      this.storeKey = `${this.props.list.endpoint}-${hash(
         JSON.stringify(this.props.list)
      )}`
      this.lb = new labelHelper(
         labels,
         this.props.authUser ? this.props.authUser.lang : labelHelper.lang
      )
   }

   storedPage = (page = null) => {
      if (page) {
         sessionStorage.setItem(`${this.storeKey}-page`, page)
      }

      return parseInt(sessionStorage.getItem(`${this.storeKey}-page`)) || 1
   }

   async componentDidMount() {
      // refresh stored query
      this.query(this.storedPage(), this.props.filters)
   }

   /**
    * Refresh query if filters are different from previous
    * @param {Object} filters previous filters object
    */
   componentDidUpdate({ filters }) {
      if (!_.isEqual(filters, this.props.filters)) {
         this.setState({ reset: true })
         this.query(1, this.props.filters)
      }
   }

   query = async (page = 1, filters = {}, sorting = []) => {
      if (this.props.data) {
         // quick and dirty way to display a list from a field
         return this.setState({
            data: this.props.data,
            max: this.props.data.length,
            reset: false,
            loading: false,
         })
      }

      this.setState({ loading: true, error: null })
      sessionStorage.setItem(`${this.storeKey}-page`, page)
      const q = {}
      _.map(filters, (val, key) => {
         if (val) {
            q[key] = val.ref ? [val.ref, '==', 'ref'] : val
         }
      })

      const params = {
         offset: (page - 1) * this.state.perPage,
         batch: this.state.perPage,
         q,
         fsort: sorting[0]
            ? sorting[0].selector
            : this.props.list.defaultSorting.field,
         osort: sorting[1] || this.props.list.defaultSorting.order,
      }

      try {
         this.props.list.actions
            .list(params, this.props)
            .then(async res => {
               // Convert key to label for columns having a 'values' key
               _.map(_.filter(this.columns, 'values'), column => {
                  _.map(res.data, row => {
                     row[`_${column.selector}`] = row[column.selector]
                     row[column.selector] = labelFormat(
                        row[column.selector],
                        column.values
                     )
                  })
               })

               // preprocess rendering (for medias retrieval)
               await Promise.all(
                  res.data.map(async (element, key) => {
                     res.data[key].rendered = await this.props.list.renderer(
                        element,
                        this.props,
                        this.state
                     )
                  })
               )

               this.setState({
                  data: res.data,
                  offset: params.offset,
                  batch: params.batch,
                  max: res.meta.count,
                  reset: false,
                  loading: false,
               })
            })
            .catch(err => this.renderError(err))
      } catch (err) {
         this.renderError(err)
      }
   }

   renderError = err => {
      const error = err.message
         ? `${this.lb._('form.errors.server_error')} ${err.message}`
         : err
      this.setState({ loading: false, error, data: [] })
   }

   /**
    * Set or get page shared components filters
    */
   filters = (filters = null) => {
      if (filters) {
         console.log('Setting new filters', filters)
         sessionStorage.setItem(this.storeKey, JSON.stringify(filters))
         this.setState({ filters, reset: true })
         this.query(1, filters)
      } else {
         return sessionStorage.getItem(this.storeKey)
            ? JSON.parse(sessionStorage.getItem(this.storeKey))
            : undefined
      }
   }

   render() {
      // slice data in preformated rows
      const rows = []
      const rowSize = this.props.list.columns || 3
      for (let i = 0; i < this.state.data.length; i += rowSize) {
         rows.push(this.state.data.slice(i, i + rowSize))
      }

      return (
         <Dimmer.Dimmable as={Segment} blurring dimmed={this.state.loading}>
            <Dimmer active={this.state.loading} inverted>
               <Loader size="massive" indeterminate>
                  {this.lb._(labels.form.loading)}
               </Loader>
            </Dimmer>
            {this.state.error && <ErrorMessage content={this.state.error} />}
            <Grid columns={rowSize} divided>
               {rows.map((row, index) => this.renderRow(row, index))}
            </Grid>
            {this.renderPagination()}
            <Confirm
               open={this.state.modalOpen}
               header={this.state.modalTitle}
               content={this.state.modalContent}
               cancelButton={this.lb._('form.cancel')}
               confirmButton={this.lb._('form.confirm')}
               onCancel={() => this.setState({ modalOpen: false })}
               onConfirm={() =>
                  this.state.modalConfirm instanceof Function &&
                  this.state.modalConfirm().then(() => {
                     this.setState({ modalOpen: false, reset: true }, () => {
                        if (this.state.modalRefresh === true) {
                           this.query(this.storedPage(), this.props.filters)
                        }
                     })
                  })
               }
            />
         </Dimmer.Dimmable>
      )
   }

   renderPagination(current = 9) {
      const label = `Médias par page `
      const sizesLabel = `Taille`
      const ranges = [9, 12, 15]
      const sizes = ['small', 'medium', 'big']

      //const offset = (this.storedPage() - 1) * this.state.perPage
      //const batch = this.state.perPage
      const { offset, batch, max, perPage, currentSize } = this.state
      return (
         <Container>
            <Divider />
            <Grid columns={3} divided>
               <Grid.Row>
                  <Grid.Column align="center">
                     {`${this.state.offset + 1}-${
                        offset + batch > max ? max : offset + batch
                     } sur ${max}`}
                     &nbsp;
                     <Button
                        icon="fast backward"
                        disabled={offset === 0}
                        onClick={() => this.query(1, this.props.filters)}
                     />
                     &nbsp;
                     <Button
                        icon="step backward"
                        disabled={offset === 0}
                        onClick={() =>
                           this.query(this.storedPage() - 1, this.props.filters)
                        }
                     />
                     &nbsp;
                     <Button
                        icon="step forward"
                        disabled={offset + batch > max}
                        onClick={() =>
                           this.query(this.storedPage() + 1, this.props.filters)
                        }
                     />
                     &nbsp;
                     <Button
                        icon="fast forward"
                        disabled={offset + batch > max}
                        onClick={() =>
                           this.query(
                              this.storedPage(
                                 Math.ceil(max / batch),
                                 this.props.filters
                              )
                           )
                        }
                     />
                  </Grid.Column>
                  <Grid.Column align="center">
                     {label}
                     {ranges.map(range => this.renderRange(range, perPage))}
                  </Grid.Column>
                  <Grid.Column align="center">
                     {sizesLabel}
                     &nbsp;
                     {sizes.map(size => this.renderSize(size, currentSize))}
                  </Grid.Column>
               </Grid.Row>
            </Grid>
         </Container>
      )
   }

   /**
    * Returns a button to change grid range value
    * @param {number} range
    * @param {number} perPage
    * @returns ReactElement
    */
   renderRange(range, perPage) {
      return (
         <Button
            key={`range${range}`}
            content={range}
            circular
            disabled={perPage === range}
            onClick={() => this.changeRange(range)}
         />
      )
   }

   /**
    * Returns a button to change media size value
    * @param {string} size
    * @param {string} currentSize
    * @returns ReactElement
    */
   renderSize(size, currentSize) {
      return (
         <Button
            key={`size${size}`}
            content={size}
            circular
            disabled={size === currentSize}
            onClick={() => this.changeSize(size)}
         />
      )
   }

   /**
    * Change range and refresh query
    * @param {number} perPage
    */
   changeRange(perPage) {
      this.setState({ perPage, reset: true }, () =>
         this.query(1, this.props.filters)
      )
   }

   changeSize(size) {
      this.setState({ size, reset: true }, () =>
         this.query(1, this.props.filters)
      )
   }

   renderRow(row, key) {
      return (
         <Grid.Row stretched key={`row${key}`}>
            {row.map(col => this.renderElement(col))}
         </Grid.Row>
      )
   }

   renderElement(element) {
      return <Grid.Column key={element.uid}>{element.rendered}</Grid.Column>
   }

   openModal(modalTitle, modalContent, modalConfirm, modalRefresh = false) {
      this.setState({
         modalOpen: true,
         modalTitle,
         modalContent,
         modalConfirm,
         modalRefresh,
      })
   }
}
