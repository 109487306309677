import React from 'react'
import { Dropdown } from 'semantic-ui-react'

export default class SelectMany extends React.Component {
   render() {
      const {
         placeholder,
         onChange,
         options,
         defaultValue,
         disabled,
         loading = true,
      } = this.props
      return (
         <Dropdown
            placeholder={placeholder}
            closeOnChange
            multiple
            selection
            search
            onChange={onChange}
            options={options}
            value={defaultValue}
            loading={loading && options.length === 0}
            disabled={disabled}
         />
      )
   }
}
