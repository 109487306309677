import React from 'react'
import { Link } from 'react-router-dom'
import { withFirebase } from '../Firebase'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import * as ROUTES from '../../constants/routes'
import FormComponent from '../Form'
import { Container } from 'semantic-ui-react'
import { AutoLabel, AutoHeader } from '../Common'
import passwordForm from './password-form'

const PasswordForgetPage = () => (
   <div>
      <PasswordForgetForm />
   </div>
)

class PasswordForgetFormBase extends React.Component {
   onSubmit = data => {
      this.props.firebase
         .doPasswordReset(data.email)
         .then(() => {
            this.props.history.push(ROUTES.SIGN_IN)
         })
         .catch(error => {
            return error.message
         })
   }

   render() {
      return (
         <Container>
            <AutoHeader />
            <FormComponent
               form={passwordForm}
               onSubmit={this.onSubmit}
               {...this.props}
            />
         </Container>
      )
   }
}

const PasswordForgetLink = () => (
   <p>
      <Link to={ROUTES.PASSWORD_FORGET}>
         <AutoLabel labelKey="form.password.forget" />
      </Link>
   </p>
)

export default PasswordForgetPage

const PasswordForgetForm = compose(
   withFirebase,
   withRouter,
)(PasswordForgetFormBase)

export { PasswordForgetForm, PasswordForgetLink }
