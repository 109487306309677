import * as hd from 'humanize-duration'
import { labelHelper } from '.'

const formatter = hd.humanizer({
   language: labelHelper.lang,
   round: true,
   conjunction: ' ',
   languages: {
      [labelHelper.lang]: {
         s: () => 'sec',
         m: () => 'min',
         h: () => 'h',
      },
   },
})

export const dureeFormat = (duration, short = false) => {
   if (duration === undefined) {
      return 'N/A'
   }
   return short
      ? `${Math.floor(duration / 60000)}:${Math.round(
           (duration % 60000) / 1000
        )}`
      : formatter(duration, {
           // language: labelHelper.lang,
           units: duration > 3600000 ? ['h', 'm'] : ['m', 's'],
        })
}
