import Api from '../../Api'
import labels from '../../Common/translations'
import { dateFormat, labelFormat } from '../../Helpers'
import vectoStatus from '../../../models/vecto-status'
export default {
   endpoint: 'videos',
   pagesize: 10,
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: {
      name: {
         label: labels.fields.name,
         accessor: s => `${s.name}`,
      },
      company: {
         label: labels.fields.company,
         accessor: s => `${s.company ? s.company.label : ''}`,
         hidden: ({ authUser }) => authUser.role === 'root',
      },
      status: {
         label: labels.fields.status,
         accessor: s => labelFormat(s.status, vectoStatus),
      },
      createdAt: {
         label: labels.fields.created_at,
         format: row => dateFormat(row.createdAt),
         sortable: true,
      },
   },
   buttons: {
   },
   actions: {
      list: params => Api.instance().get('videos360', params),
   },
}
