import React from 'react'
import { withFirebase } from '../Firebase'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import * as ROUTES from '../../constants/routes'
import { BaaS } from '../..'

class SignOutPage extends React.Component {
   componentDidMount() {
      if (BaaS === 'firebase') {
         localStorage.removeItem('authUser')
         sessionStorage.clear()
         this.props.firebase.doSignOut()
      }

      window.location.replace(ROUTES.SIGN_IN)
   }

   render() {
      return <div>Log out in progress</div>
   }
}

export default compose(withRouter, withFirebase)(SignOutPage)
