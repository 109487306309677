import { useEffect, useState } from 'react'
import Api from '../../Api'

const useFetch = ({ api_path, params }) => {
   const [data, setData] = useState(null)
   const [error, setError] = useState('')
   const [loading, setLoaded] = useState(true)
   const fetchData = async (data = {}) => {
      setLoaded(true)
      try {
         const response = await Api.instance().get(api_path, {
            ...params,
            ...data,
         })

         setData(response)
         setLoaded(false)
      } catch (error) {
         setLoaded(false)

         setError(error.message)
      } finally {
         setLoaded(false)
      }
   }
   useEffect(() => {
      fetchData()
   }, [])

   return { data, error, loading, refetch: fetchData }
}

export default useFetch
