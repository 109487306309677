import { dateFormat } from "../../Helpers";
import Api from "../../Api";
import { ROUTES } from "../../../constants";
import labels from "../../Common/translations";
import React from "react";

const specialUsages = {
  "": "",
  icon: labels.fields.specialUsageValues.icon["fr"],
  preview: labels.fields.specialUsageValues.preview["fr"],
  overlayIcon: labels.fields.specialUsageValues.overlayIcon["fr"],
};

export default {
  endpoint: "medias",
  pagesize: 1000,
  defaultSorting: { field: "createdAt", order: "desc" },
  columns: {
    media: {
      label: labels.fields.media,
      accessor: ({ media }) => (
        <p className="tablebreakSpaces">
          {`${media.name} (${media.company.label})`}
        </p>
      ),
      options: {
        minWidth: "30%",
      },
    },
    creator: {
      label: labels.fields.version,
      accessor: ({ version }) => `${version.createdBy.label}`,
    },
    specialUsage: {
      label: labels.fields.specialUsage,
      accessor: ({ media }) => specialUsages[media.specialUsage || ""],
    },
    revision: {
      label: labels.fields.revision,
      accessor: ({ version }) => version.revision,
      options: {
        maxWidth: "5%",
      },
    },
    createdAt: {
      label: labels.fields.latest_updated,
      accessor: (s) => dateFormat(s.createdAt),
    },
  },
  buttons: {
    show: {
      label: labels.fields.show,
      url: ({ media }) =>
        ROUTES.MEDIAS_SHOW_TAB_SHARES.replace(":id", media.guid),
      options: {
        icon: "eye",
        positive: true,
      },
    },
  },
  actions: {
    list: (params, props) => {
      console.log({ params, props });
      return Api.instance().get(`${props.baseRef}/medias`, params);
    },
  },
};
