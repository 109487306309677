import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import FormComponent from '../../Form'
import { Container } from 'semantic-ui-react'
import companyForm from '../config/company-form'
import groupForm from '../config/group/create-form'
import { withAuthentication } from '../../Session'
import { AutoHeader } from '../../Common'

/**
 * Group creation page for admin (bound company is their own)
 */
class CompanyGroupCreatePage extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         loading: true,
         item: null,
      }

      this.baseRef = `companies/${this.props.match.params.id}`
   }

   async componentDidMount() {
      const { data: item } = await companyForm.actions.read(this.baseRef)
      this.setState({
         item,
         loading: false,
      })
   }

   render() {
      if (this.state.loading === true) {
         return <div />
      }

      const { name: label } = this.state.item
      return (
         <Container>
            <AutoHeader labelKey="screens.company_group_create" />
            <Container>
               <FormComponent
                  form={groupForm}
                  {...this.props}
                  company={{ label, ref: this.baseRef }}
               />
            </Container>
         </Container>
      )
   }
}

export default compose(withRouter, withAuthentication)(CompanyGroupCreatePage)
