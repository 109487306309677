import React from 'react'
import { Message } from 'semantic-ui-react'
import { labelHelper } from '../Helpers'
import labels from './translations'

export default class ErrorMessage extends React.Component {
   constructor(props) {
      super(props)
      this.props = props
      this.lb = new labelHelper(
         labels,
         this.props.authUser ? this.props.authUser.lang : labelHelper.lang,
      )
   }

   render() {
      // const key =
      //    typeof this.props.labelKey !== String
      //       ? String(this.props.labelKey)
      //       : this.props.labelKey
      return (
         <Message
            negative
            icon="warning circle"
            header={this.lb._('errors.error')}
            content={this.props.content}
         />
      )
   }
}
