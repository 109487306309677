import { downloadUrl, dureeFormat, dateFormat } from '../../Helpers'
import Api from '../../Api'
import labels from '../../Common/translations'

export default {
   endpoint: 'videos/*/vectos',
   pagesize: 10,
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: {
      name: {
         label: labels.fields.name,
         accessor: s => `${s.name}`,
      },
      duration: {
         label: labels.fields.length,
         accessor: s => dureeFormat(s.duration * 1000),
      },
      createdAt: {
         label: labels.fields.created_at,
         accessor: s => dateFormat(s.createdAt),
      },
   },
   buttons: {
      download: {
         disabled: data => data.status !== 'completed',
         action: data => {
            if (data.status === 'completed') {
               window.open(downloadUrl(`${data.video.ref}-vecto-${data.uid}`))
            }
         },
         label: labels.fields.downloads,
         options: {
            icon: 'download',
            primary: true,
         },
      },
   },
   actions: {
      list: params => Api.instance().get('videos3d', params),
   },
}
