import * as ROUTES from '../../../constants/routes'
import Api from '../../Api'
import React from 'react'
import { Card, Icon, Image, Popup, List } from 'semantic-ui-react'
import labels from '../../Common/translations'
import * as prettyBytes from 'pretty-bytes'
import { dateFormat } from '../../Helpers'

export default {
   endpoint: 'medias',
   pagesize: 9,
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: 3,
   renderer: async (data, { firebase }, state) => {
      let url
      if (data.host === 'aws') {
         url = data.thumbRef256
      } else {
         if (data.thumbRef256) {
            url = await firebase.getMediaUrl(data.thumbRef256)
         } else if (data.thumbRef128) {
            url = await firebase.getMediaUrl(data.thumbRef128)
         } else if (data.filethumb) {
            url = await firebase.getMediaUrl(data.filethumb)
         } else {
            if (data.contentType === 'application/pdf') {
               url = await firebase.getMediaUrl('pdf_file.png')
            } else {
               url = await firebase.getMediaUrl('unknown.png')
            }
         }
      }
      return (
         <Popup
            trigger={
               <Card key={data.uid} align="center">
                  <Image src={url} size={state.size} wrapped ui={false} />
                  <Card.Content>
                     <Card.Header>
                        {data.name.length > 28
                           ? `${data.name.substring(0, 25)}...`
                           : data.name}
                     </Card.Header>
                     <Card.Content extra>
                        <Icon name="factory" />
                        {data.company.label}
                     </Card.Content>
                  </Card.Content>
               </Card>
            }
         >
            <Popup.Content>
               <List>
                  <List.Item>
                     <List.Icon name="file" />
                     <List.Content>{data.contentType}</List.Content>
                  </List.Item>
                  <List.Item>
                     <List.Icon name="weight" />
                     <List.Content>
                        {prettyBytes(parseInt(data.size || 0))}
                     </List.Content>
                  </List.Item>
                  <List.Item>
                     <List.Icon name="user" />
                     <List.Content>{data.createdBy.label}</List.Content>
                  </List.Item>
                  <List.Item>
                     <List.Icon name="wait" />
                     <List.Content>
                        {dateFormat(data.createdAt)} CET
                     </List.Content>
                  </List.Item>
                  <List.Item>
                     <List.Icon name="clock outline" />
                     <List.Content>
                        {dateFormat(data.updatedAt)} CET
                     </List.Content>
                  </List.Item>
                  <List.Item>
                     <List.Icon name="cloud" />
                     <List.Content>{data.host || 'gcp'}</List.Content>
                  </List.Item>
                  <List.Item>
                     <List.Icon name="share alternate" />
                     <List.Content>{data.shares || 0}</List.Content>
                  </List.Item>
                  <List.Item>
                     <List.Icon name="tag" />
                     <List.Content>
                        {data.tags ? data.tags.join(' | ') : '-'}
                     </List.Content>
                  </List.Item>
               </List>
            </Popup.Content>
         </Popup>
      )
   },
   buttons: {
      show: {
         label: labels.fields.show,
         url: ROUTES.ENVIRONMENTS_SHOW,
         options: {
            icon: 'eye',
            positive: true,
         },
      },
   },
   actions: {
      list: (params, props) =>
         Api.instance().get(`${props.baseRef}/medias`, params),
   },
}
