import React from 'react'
import { withAuthorization, withAuthentication } from '../Session'
import { compose } from 'recompose'
import { ROLES, ROUTES } from '../../constants'
import { Switch } from 'react-router-dom'
import Video3dRenamePage from './rename'
import Video3dJobPage from './job'
import SceneShowPage from './show'
import { Container, Modal, Tab, Divider } from 'semantic-ui-react'
import TableComponent from '../Table'
import SearchComponent from '../Form/search'
import search from './config/search'
import completedScenesList from './config/completed-scenes-list'
import progressScenesList from './config/progress-scenes-list'
import failedScenesList from './config/failed-scenes-list'
import { AutoLabel, MandatoryWarning, Page } from '../Common'
import SearchableList from '../Common/searchableList'
import { labelHelper } from '../Helpers'
import labels from '../Common/translations'
import { TabWidget } from '../Widgets'

const ScenesPage = () => (
   <div>
      <Switch>
         <Page
            exact
            path={ROUTES.SCENES}
            component={ScenesList}
            title="screens.video3d"
         />
         <Page
            path={ROUTES.SCENE_RENAME}
            component={Video3dRenamePage}
            title="screens.video3d_page_rename"
         />
         <Page
            path={ROUTES.SCENE_JOB}
            component={Video3dJobPage}
            title="screens.video3d_job"
         />
         <Page
            path={ROUTES.SCENE_SHOW}
            component={SceneShowPage}
            title="screens.video3d_show"
         />
      </Switch>
   </div>
)

class ScenesListBase extends SearchableList {
   constructor(props) {
      super(props)
      this.state = {
         loading: false,
      }
   }

   lb = new labelHelper(
      labels,
      this.props.authUser ? this.props.authUser.lang : labelHelper.lang
   )

   panes = [
      {
         key: 'completedScenes',
         menuItem: this.lb._(labels.screens.completedScenes),
         render: () => (
            <Tab.Pane key="completedScenes">
               {this.shouldRender() ? (
                  <TableComponent
                     title={<AutoLabel labelKey="screens.completedScenes" />}
                     list={completedScenesList}
                     filters={{
                        ...this.filters(),
                        status: ['completed'],
                     }}
                     parent={this} // give access to page context so button can change state and display modal
                     {...this.props}
                  />
               ) : (
                  <MandatoryWarning />
               )}
            </Tab.Pane>
         ),
      },
      {
         key: 'progressScenes',
         menuItem: this.lb._(labels.screens.progressScenes),
         render: () => (
            <Tab.Pane key="progressScenes">
               {this.shouldRender() ? (
                  <TableComponent
                     title={<AutoLabel labelKey="screens.progressScenes" />}
                     list={progressScenesList}
                     filters={{
                        ...this.filters(),
                        status: [
                           ['completed', 'timeout', 'ko', 'deleted'],
                           'not-in',
                        ],
                     }}
                     {...this.props}
                  />
               ) : (
                  <MandatoryWarning />
               )}
            </Tab.Pane>
         ),
      },
      {
         key: 'failedScenes',
         menuItem: this.lb._(labels.screens.failedScenes),
         render: () => (
            <Tab.Pane key="failedScenes">
               {this.shouldRender() ? (
                  <TableComponent
                     title={<AutoLabel labelKey="screens.failedScenes" />}
                     list={failedScenesList}
                     filters={{
                        ...this.filters(),
                        status: [['ko', 'timeout']],
                     }}
                     {...this.props}
                  />
               ) : (
                  <MandatoryWarning />
               )}
            </Tab.Pane>
         ),
      },
   ]

   render() {
      return (
         <Container>
            <SearchComponent
               form={search}
               data={this.filters()}
               {...this.props}
               propagate={filters => this.filters(filters)}
            />
            <Modal
               open={this.state.open}
               ref={this.modalRef}
               closeIcon
               content={this.state.content}
               onClose={() => this.setState({ open: false })}
               closeOnEscape={false}
               header="Default Access List Management"
            />
            <Divider hidden />
            <TabWidget panes={this.panes} />
         </Container>
      )
   }

   /**
    * Should the content be rendered ?
    * if user is partner, they must choose a company
    */
   shouldRender() {
      return (
         (this.filters() && this.filters().company) ||
         [ROLES.ROLE_ROOT, ROLES.ROLE_ADMIN].includes(this.props.authUser.role)
      )
   }
}

const condition = authUser => authUser // && !!authUser.roles[ROLES.ADMIN]

const ScenesList = withAuthentication(ScenesListBase)

export default compose(withAuthorization(condition))(ScenesPage)
