import Api from '../../Api'
import * as ROUTES from '../../../constants/routes'
import { dateFormat } from '../../Helpers'
import labels from '../../Common/translations'

export default {
   endpoint: 'users',
   pagesize: 10,
   defaultSorting: { field: 'deletedAt', order: 'desc' },
   columns: {
      email: {
         label: labels.fields.email,
         accessor: s => `${s.email}`,
      },
      company: {
         label: labels.fields.company,
         accessor: s => `${s.company ? s.company.label : ''}`,
         hidden: ({ authUser }) => authUser.role !== 'root',
      },
      role: {
         label: labels.fields.role,
         values: {
            source: () => Api.selectValues('roles'),
         },
      },
      deletedAt: {
         label: labels.fields.deleted_at,
         format: row => dateFormat(row.deletedAt),
      },
      deletedBy: {
         label: labels.fields.deleted_by,
         accessor: s => `${s.deletedBy ? s.deletedBy.label : ''}`,
      },
   },
   buttons: {
      show: {
         label: labels.fields.show,
         url: ROUTES.USER_SHOW,
         options: {
            icon: 'eye',
            positive: true,
         },
      },
   },
   actions: {
      list: (params = { q: {} }) => {
         params.q.status = ['deleted']
         return Api.instance().get('users', params)
      },
   },
}
