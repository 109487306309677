import React from 'react'
import FormComponent from '../Form'
import { Container, Dimmer, Loader } from 'semantic-ui-react'
import { AutoHeader } from '../Common'

export default class UpdateFormWidget extends React.Component {
   constructor(props) {
      super(props)
      this.state = { item: null, loading: true }
   }
   componentDidMount() {
      this.setState({ loading: true })
      this.props.form.actions.read(this.props.id).then(res => {
         this.setState({
            item: res.data,
            loading: false,
         })
      })
   }

   render() {
      if (!this.state.item) {
         return (
            <Dimmer active={this.state.loading} page inverted>
               <Loader indeterminate size="massive"></Loader>
            </Dimmer>
         )
      }

      return (
         <Container>
            <AutoHeader />
            <Container>
               <FormComponent
                  form={this.props.form}
                  data={this.state.item}
                  dimmed={this.state.loading}
                  {...this.props}
               />
            </Container>
         </Container>
      )
   }
}
