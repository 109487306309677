import FormComponent from '../../Form'
import Api from '../../Api'
import labels from '../../Common/translations'
import TextEditor from '../../Form/fields/textEditor'

export default {
   fields: {
      name: {
         label: labels.fields.name,
         mandatory: true,
      },
      version: {
         label: labels.fields.version,
         mandatory: false,
         options: {
            prefix: 'v',
         },
      },
      notes: {
         type: FormComponent.TEXTEDITOR,
         label: labels.fields.content,
         options: {
            languages: {
               fr: labels.fields.fr,
               en: labels.fields.en,
               es: labels.fields.es,
            },
            toolbar: TextEditor.TOOLBAR_MEDIUM,
         },
      },
      file: {
         type: 'file',
         label: labels.fields.file,
         mandatory: true,
         options: {
            folder: 'downloads',
            mimes: [
               'application/exe',
               'application/x-msdownload',
               'application/zip',
            ],
         },
         observers: [
            {
               // This observer resets the values of two stats fields
               // whenever the file is changed
               observable: 'file',
               action: data => {
                  if (
                     data.file &&
                     data.file.isTempFile &&
                     data.downloadCount > 0
                  ) {
                     return {
                        formData: {
                           downloadCount: 0,
                           downloadLast: null,
                        },
                     }
                  }
               },
            },
            {
               observable: 'file',
               action: data => {
                  if (data.file && data.file.isTempFile) {
                     if (data.file.label.substring(0, 10) === 'TotalYmage') {
                        let version = ''
                        let subParts = []
                        const name = data.file.label.substring(
                           0,
                           data.file.label.length - 4
                        )

                        const ext = data.file.label.split('.').pop()

                        console.log(name, ext)
                        switch (ext) {
                           case 'exe':
                              subParts = name.split('_')
                              version = subParts[1]
                              break
                           case 'zip':
                              subParts = name.split('-')
                              version = subParts[1]

                              break
                           default:
                              break
                        }
                        return {
                           formData: {
                              version,
                              name,
                           },
                        }
                     }
                  }
               },
            },
         ],
      },
      accessible: {
         label: labels.fields.accessible,
         type: FormComponent.CHECKBOX,
         options: {
            defaultValue: false,
         },
      },
      downloadCount: {
         label: labels.fields.downloads,
         disabled: true,
      },
      downloadLast: {
         label: labels.fields.latest_download,
         type: FormComponent.DATE,
         disabled: true,
      },
   },
   buttons: {
      cancel: true,
      submit: true,
      delete: true,
      extras: [],
   },
   actions: {
      create: data => Api.instance().post('downloads/', data),
      read: uid => Api.instance().get(`downloads/${uid}`),
      update: ({ uid, ...data }) =>
         Api.instance().patch(`downloads/${uid}`, data),
      delete: ({ uid }) => Api.instance().delete(`downloads/${uid}`),
   },
   extras: [],
}
