import React from "react";
import { withRouter } from "react-router-dom";
import { withAuthentication } from "../Session";
import { compose } from "recompose";
import videoShow from "./config/video3d-show";
import FormComponent from "../Form";
import TableComponent from "../Table";
import { Container } from "semantic-ui-react";
import form from "./config/job-form";
import { AutoHeader } from "../Common";
import { labelHelper } from "../Helpers";
import labels from "../Common/translations";

class VectoJobPage extends React.Component {
   constructor(props) {
      super(props);
      this.state = {};
      this.goBack = this.goBack.bind(this);
      this.path = null;
   }

   lb = new labelHelper(
      labels,
      this.props.authUser ? this.props.authUser.lang : labelHelper.lang
   );

   goBack() {
      this.props.history.goBack();
   }

   async componentDidMount() {
      this.setState({ loading: true });
      const { id, pid } = this.props.match.params;
      if (pid !== TableComponent.PID_PLACEHOLDER) {
         // Video3d is a child of a known Video360
         this.path = `${pid.replace(".", "/")}/vectos/${id}`;
      } else {
         // Video3d is legacy element without a known parent
         this.path = `vectos/${id}`;
      }
      console.log("params", id, pid, this.path);
      const { data: item } = await videoShow.actions.read(this.path);
      console.log(item);
      this.setState({
         item,
         loading: false,
         id,
         pid,
      });
   }

   render() {
      if (!this.state.item) {
         return <div />;
      }

      return (
         <Container>
            <AutoHeader />

            <Container>
               <FormComponent
                  form={form}
                  {...this.props}
                  vecto={this.state.item}
                  vectoPath={this.path}
               />
            </Container>
         </Container>
      );
   }
}

export default compose(withRouter, withAuthentication)(VectoJobPage);
