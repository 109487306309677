import FormComponent from '../../Form'
import * as ROUTES from '../../../constants/routes'
import Api from '../../Api'
import labels from '../../Common/translations'

export default {
   fields: {
      name: {
         type: FormComponent.GROUP,
         fields: {
            email: {
               type: FormComponent.EMAIL,
               label: labels.fields.email_login,
               mandatory: true,
               searchable: true,
               hidden: true,
               options: {
                  autoComplete: 'email',
               },
            },
            lastname: {
               label: labels.fields.lastname,
               mandatory: true,
               searchable: true,
               options: {
                  autoComplete: 'family-name',
               },
            },
            firstname: {
               label: labels.fields.firstname,
               mandatory: true,
               searchable: true,
               options: {
                  autoComplete: 'given-name',
               },
            },
         },
      },
      password: {
         label: labels.fields.password,
         type: FormComponent.PASSWORD,
         mandatory: true,
         options: {
            minLength: 6,
         },
      },
      termsAgreement: {
         type: FormComponent.CHECKBOX,
         label: labels.form.agreement,
         mandatory: true,
         options: {
            version: 1,
         },
      },
   },
   buttons: {
      submit: {
         icon: 'lightning',
         label: labels.fields.activate_account,
      },
      extras: [],
   },
   actions: {
      read: uid => Api.instance().get(`users/${uid}`),
      update: (uid, data) => Api.instance().patch('users/' + uid, data),
      redirect: ROUTES.SIGN_OUT,
   },
   extras: [],
}
