import React, { useState } from 'react'
import DatePicker from 'react-multi-date-picker'
import { Button, Container, Form } from 'semantic-ui-react'
import styled from 'styled-components'

const StyledSearchForm = styled.div`
   display: flex;
   justify-content: flex-start;
   align-items: end;
`

const ButtonContainer = styled.div`
   display: flex;
   justify-content: end;
   height: 50px;
   margin-left: 10px;
`

export default function Filter({ lb, onFilter, reset }) {
   const [value, setValue] = useState([])
   const [from, to] = value

   const onSubmit = () => {
      onFilter({ from: from?.toString(), to: to?.toString() })
   }
   const onReset = () => {
      setValue([])
      reset()
   }
   return (
      <Container>
         <StyledSearchForm>
            <Form widths="equal">
               <Form.Field>
                  <label>{lb._('dlstats.fields.date')}:</label>
                  <DatePicker
                     range
                     value={value}
                     onChange={d => setValue(d)}
                     style={{
                        height: '50px',
                     }}
                  />
               </Form.Field>
            </Form>
            <ButtonContainer>
               <Button primary disabled={value.length === 0} onClick={onSubmit}>
                  {lb._('form.filtre')}
               </Button>

               <Button onClick={onReset}>{lb._('fields.reset')}</Button>
            </ButtonContainer>
         </StyledSearchForm>
      </Container>
   )
}
