import { dureeFormat, dateFormat } from '../../Helpers'
import Api from '../../Api'
import * as ROUTES from '../../../constants/routes'
import labels from '../../Common/translations'
import React from 'react'

export default {
   endpoint: 'videos/*/vectos',
   // where to find the parent id
   pid: 'video.ref',
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: {
      name: {
         label: labels.fields.name,
         accessor: s => <p className="tablebreakSpaces">{s.name}</p>,
      },
      company: {
         label: labels.fields.company,
         accessor: ({ company }) => `${company ? company.label : ''}`,
         hidden: ({ authUser }) => authUser.role === 'admin',
         options: {
            minWidth: '50px',
         },
      },
      createdBy: {
         label: labels.fields.created_by,
         accessor: s => `${s.createdBy ? s.createdBy.label : ''}`,
      },
      duration: {
         label: labels.fields.length,
         accessor: ({ duration }) =>
            duration ? dureeFormat(duration * 1000, true) : '?',
         options: {
            maxWidth: '20px',
         },
      },
      createdAt: {
         label: labels.fields.created_at,
         accessor: ({ createdAt }) => dateFormat(createdAt),
         sortable: true,
      },
   },
   buttons: {
      show: {
         label: labels.fields.shares,
         url: ROUTES.SCENE_SHOW,
         options: {
            icon: 'eye',
            positive: true,
         },
      },
      revecto: {
         label: labels.fields.revecto,
         options: {
            icon: 'redo',
            primary: true,
         },
         // passed params are current record data and page props
         action: ({ uid, video }) => {
            if (!video) return
            const uri = `/videos3d/${video.ref.split('/')[1]}/${uid}/revecto`

            Api.instance()
               .post(uri)
               .then(() => window.location.reload())
               .catch(err => err.message)
         },
      },
      delete: {
         label: labels.fields.delete,
         options: {
            icon: 'delete',
            negative: true,
         },
         // passed params are current record data and page props
         action: ({ uid, video }) => {
            const uri = video ? `${video.ref}/vectos/${uid}` : `vectos/${uid}`
            Api.instance()
               .delete(uri)
               .then(() => window.location.reload())
               .catch(err => err.message)
         },
      },
   },
   actions: {
      list: params => {
         return Api.instance().get('videos3d', params)
      },
   },
}
