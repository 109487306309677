import React from 'react'
import { dateFormat } from '../../../Helpers'
import Api from '../../../Api'
import { Container, Header } from 'semantic-ui-react'
import FormComponent from '../../../Form'
import TableComponent from '../../../Table'
import shareForm from '../share-form'
import membersList from '../company-group-members-list'
import { AutoLabel } from '../../../Common'
import labels from '../../../Common/translations'
import { ROUTES } from '../../../../constants'

export default {
   endpoint: 'companies/*/groups',
   pid: 'company.ref',
   pagesize: 10,
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: {
      name: {
         label: labels.fields.name,
      },
      // members: {
      //    label: {
      //       fr: 'Membres',
      //       en: 'Members',
      //    },
      // },
      createdAt: {
         label: labels.fields.created_at,
         accessor: s => dateFormat(s.createdAt),
      },
      createdBy: {
         label: labels.fields.created_by,
         accessor: s => s.createdBy.label,
      },
   },
   buttons: {
      modify: {
         label: labels.fields.modify,
         url: ROUTES.COMPANY_GROUP_MODIFY,
         options: {
            icon: 'edit',
            primary: true,
         },
      },
      list: {
         label: labels.fields.members,
         options: {
            icon: 'list alternate',
         },
         action: async ({ uid, name }, { parent, company }) => {
            membersList.actions.list = params =>
               Api.instance().get(
                  `companies/${company.uid}/groups/${uid}/members`,
                  params
               )
            parent.setState({
               open: true,
               modalHeader: (
                  <Header as="h2" block>
                     <AutoLabel labelKey="fields.members" />
                  </Header>
               ),
               content: (
                  <TableComponent
                     title={name}
                     list={membersList}
                     pagination={false}
                  />
               ),
            })
         },
      },
      share: {
         // display the contextual access list form in the screen modal
         // update the update action to provide the matching path
         action: async ({ uid, name, company }, props) => {
            shareForm.actions.create = data =>
               Api.instance().post(`${props.baseRef}/guests`, {
                  group: {
                     ref: `groups/${uid}`,
                     label: name,
                  },
                  ...data,
                  company,
                  location: document.location,
               })

            // open modal with contextual form content
            props.parent.setState({
               open: true,
               modalHeader: props.modalHeader,
               content: (
                  <Container>
                     <Header as="h4" block>
                        <AutoLabel
                           labelKey="screens.guests_invite_detail"
                           params={[name]}
                        />
                     </Header>
                     <FormComponent form={shareForm} {...props} />
                  </Container>
               ),
            })
         },
         label: labels.fields.share,
         options: {
            icon: 'share alternate',
            primary: true,
         },
      },
      delete: {
         label: labels.fields.group_delete,
         options: {
            icon: 'delete',
            negative: true,
         },
         action: ({ uid, company }) => {
            Api.instance()
               .delete(`${company.ref}/groups/${uid}`)
               .then(() => {
                  window.location.reload()
               })
               .catch(err => alert(`Unable to delete group: ${err.message}`))
         },
      },
   },
   actions: {
      list: (params, props) =>
         Api.instance().get(`${props.baseRef}/groups`, params),
   },
}
