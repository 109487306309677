import React from 'react'
import {
   XAxis,
   YAxis,
   CartesianGrid,
   Tooltip,
   ResponsiveContainer,
   BarChart,
   Bar,
} from 'recharts'
import { formatBytes } from './utils'
import labels from '../translations'

import moment from 'moment'
import _ from 'lodash'

const getListDays = (from, to) => {
   var dates = []

   var currDate = moment(from).startOf('day')
   var lastDate = moment(
      to ||
         moment(from)
            .add(30, 'days')
            .format('YYYY-MM-DD')
   ).startOf('day')

   dates.push({ day: currDate.format('YYYY-MM-DD') })

   while (currDate.add(1, 'days').diff(lastDate) < 0) {
      dates.push({ day: currDate.clone().format('YYYY-MM-DD') })
   }
   dates.push({ day: lastDate.format('YYYY-MM-DD') })
   return dates
}
export default function LinechartHistory({ data, lb, filter }) {
   const getDaysArrayCurrentMonth = () => {
      var days = filter
         ? getListDays(filter.from, filter.to)
         : getListDays(
              moment()
                 .startOf('month')
                 .format('YYYY-MM-DD'),
              moment().format('YYYY-MM-DD')
           )

      return days
   }
   const formaterData = () => {
      const groupBy = _.groupBy(
         [
            ...data,
            ...getDaysArrayCurrentMonth(_.orderBy(data, ['day', 'asc'])),
         ],
         e => e.day
      )

      return _.orderBy(
         Object.keys(groupBy).map(key => {
            return {
               day: groupBy[key][0].day,
               total: groupBy[key][0].total || 0,
            }
         }),
         ['day'],
         ['asc']
      )
   }
   const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
         return (
            <div
               className="custom-tooltip"
               style={{
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  padding: '12px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
               }}
            >
               <p className="label">{`${lb._(
                  labels.dlstats.title
               )} : ${formatBytes(payload[0].value * 1024)}`}</p>

               <p>{label}</p>
            </div>
         )
      }

      return null
   }

   return (
      <ResponsiveContainer width="100%" height={400}>
         <BarChart
            width={500}
            height={300}
            data={formaterData().map(e => ({
               name: e.day,
               dl: parseFloat(
                  parseFloat(parseFloat(e.total) / 1024).toFixed(2)
               ),
            }))}
            margin={{
               top: 20,
               right: 30,
               left: 20,
               bottom: 5,
            }}
         >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
               dataKey="name"
               tickFormatter={e => {
                  return moment(e).format('DD')
               }}
            />
            <YAxis />
            <Tooltip
               // formatter={value => formatBytes(value * 1024)}
               content={<CustomTooltip />}
            />
            {/* <Legend /> */}
            <Bar dataKey={'dl'} stackId={'dl'} fill="#8884d8" />
         </BarChart>
      </ResponsiveContainer>
   )
}
