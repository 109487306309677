import Api from '../../../Api'
import * as ROUTES from '../../../../constants/routes'
import { ROLES } from '../../../../constants'
import FormComponent from '../../../Form'
import CreditStatus from '../../../../models/credit-status'
import labels from '../../../Common/translations'

export default {
   fields: {
      company: {
         label: labels.fields.company,
         type: FormComponent.SELECT,
         mandatory: true,
         disabled: ({ authUser }) =>
            ROLES.ROLES_WITH_COMPANIES.includes(authUser.role),
         hidden: ({ authUser }) =>
            !ROLES.ROLES_WITH_COMPANIES.includes(authUser.role),
         values: {
            from: 'state',
            prop: 'companyValues',
            collection: 'companies',
            source: () =>
               Api.selectValues('companies', {
                  fsort: 'name',
               }),
         },
         return: FormComponent.FORMAT_OBJECT,
         options: {
            defaultValue: ({ company }) =>
               company.ref
                  ? company
                  : {
                       ref: `companies/${company.uid}`,
                       label: company.name,
                    },
         },
      },
      createdBy: {
         label: labels.fields.created_by,
         mandatory: true,
         hidden: true,
         options: {
            defaultValue: (props, state) => ({
               ref: `users/${props.authUser.uid}`,
               label: `${props.authUser.firstname} ${props.authUser.lastname}`,
            }),
         },
      },
      type: {
         type: FormComponent.SELECT,
         label: labels.fields.credit_type,
         values: CreditStatus,
         mandatory: true,
      },
      quantity: {
         type: FormComponent.NUMBER,
         label: labels.fields.quantity_add_remove,
         mandatory: true,
      },
      comment: {
         label: labels.fields.comment,
      },
   },
   buttons: {
      delete: false,
      submit: true,
      cancel: true,
      extras: [],
   },
   actions: {
      create: data => {
         Api.instance().post(`${data.company.ref}/purchases`, data)
      },
      read: (pid, uid) => Api.instance().get(`${pid}/purchases/${uid}`),
      redirect: ROUTES.COMPANY,
   },
   extras: [],
}
