import React from 'react'
import {
   BarChart,
   Bar,
   XAxis,
   YAxis,
   CartesianGrid,
   Tooltip,
   Legend,
   ResponsiveContainer,
} from 'recharts'
import { formatBytes } from './utils'
import labels from '../translations'

import moment from 'moment'
import _ from 'lodash'
const formaterData = data => {
   let totalUnit = 0
   let grpBy = _.groupBy(data, e => e.month)

   let formatedData = Object.keys(grpBy).map(key => {
      let total = 0
      let tmp = {}
      grpBy[key].map(e => {
         tmp[e.type] = parseFloat(e.total)

         total += parseFloat(e.total)
         return null
      })
      if (total > totalUnit) totalUnit = total
      return { month: key, total, ...tmp }
   })
   const groupBy = _.groupBy(
      [
         ...formatedData,
         ...(() =>
            Array.from(Array(12)).map((e, i) => ({
               month: moment([moment().year(), i]).format('YYYY-MM'),
            })))(),
      ],
      e => e.month
   )

   return {
      data: _.orderBy(
         Object.keys(groupBy).map(key => {
            return groupBy[key][0]
         }),
         ['month'],
         ['asc']
      ),
      unit: Math.floor(Math.log(totalUnit) / Math.log(1024)),
   }
}
export default function BarChartHistory({ data, lb }) {
   const { data: dataF, unit } = formaterData(data)
   const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
         return (
            <div
               className="custom-tooltip"
               style={{
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  padding: '12px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
               }}
            >
               <p className="label">
                  {`${lb._(labels.dlstats.fields.total)} `}:
                  <b>{' ' + formatBytes(payload[0].payload.total)}</b>{' '}
               </p>

               {payload.length > 1 &&
                  payload.map(e => (
                     <p>
                        <b style={{ color: e.fill }}>{e.dataKey}</b>:
                        {' ' + formatBytes(e.payload[e.dataKey])}
                     </p>
                  ))}
            </div>
         )
      }

      return null
   }

   return (
      <ResponsiveContainer width="100%" height={400}>
         <BarChart
            width={500}
            height={300}
            data={dataF}
            margin={{
               top: 20,
               right: 30,
               left: 20,
               bottom: 5,
            }}
         >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
               dataKey="month"
               tickFormatter={e => {
                  return moment(e).format('MM')
               }}
            />
            <YAxis
               tickFormatter={e => {
                  return parseFloat(e / Math.pow(1024, unit)).toFixed(0)
               }}
            />
            <Tooltip
               formatter={value => formatBytes(value)}
               content={<CustomTooltip />}
            />
            <Legend />
            {/* <Bar dataKey={'total'} stackId={'total'} data fill="#2185d0" /> */}

            <Bar dataKey="video360" stackId="total" fill="#8ecae6" />
            <Bar dataKey="video3d" stackId="total" fill="#023047" />
            <Bar dataKey="medias" stackId="total" fill="#ffb703" />
            <Bar dataKey="environments" stackId="total" fill="#bc6c25" />
         </BarChart>
      </ResponsiveContainer>
   )
}
