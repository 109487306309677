import { dateFormat } from '../../../Helpers'
import Api from '../../../Api'
import CreditStatus from '../../../../models/credit-status'
import labels from '../../../Common/translations'

export default {
   endpoint: 'companies/*/purchases',
   pid: 'company.ref',
   pagesize: 10,
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: {
      quantity: {
         label: labels.fields.quantity,
         accessor: ({ quantity }) =>
            quantity > 0 ? `+${quantity}` : `-${quantity}`,
      },
      type: {
         label: labels.fields.type,
         values: CreditStatus,
      },
      createdAt: {
         label: labels.fields.created_at,
         accessor: s => dateFormat(s.createdAt),
      },
      createdBy: {
         label: labels.fields.by,
         accessor: s => s.createdBy.label,
      },
   },
   actions: {
      list: (params, props) =>
         Api.instance().get(`${props.baseRef}/purchases`, params),
   },
}
