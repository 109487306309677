import labels from '../Common/translations'

export default {
   fields: {
      email: {
         label: {
            fr: 'Adresse email',
            en: 'Email Address',
         },
         mandatory: true,
         options: {
            autoComplete: 'username',
         },
      },
   },
   buttons: {
      submit: {
         label: {
            fr: 'Réinitialiser mon mot de passe',
            en: 'Reset My Password',
         },
         icon: 'sign in',
      },
      delete: false,
      cancel: false,
      extras: [],
   },
   actions: {},
   extras: {
      mode: 'login',
      dimmerMessage: labels.form.password.reset,
   },
}
