import FormComponent from '../../Form'
import labels from '../../Common/translations'

export default {
   fields: {
      email: {
         label: labels.fields.email,
         type: FormComponent.EMAIL,
         mandatory: true,
         options: {
            defaultValue: [],
         },
      },
   },
   buttons: {
      submit: true,
      extras: [],
   },
   actions: {
      create: data => null,
   },
   extras: [],
}
