import React from 'react'
import { withRouter } from 'react-router-dom'
import { withAuthentication } from '../Session'
import { compose } from 'recompose'
import FormComponent from '../Form'
import { Container, Modal } from 'semantic-ui-react'
import jobDefinitionForm from './config/job-definition-form'
import { AutoHeader } from '../Common'

class InspectionCreatePage extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         diagOpen: false,
      }
   }

   render() {
      return (
         <Container>
            <AutoHeader />
            <Container>
               <FormComponent form={jobDefinitionForm} {...this.props} />
            </Container>
         </Container>
      )
   }
}

const styles = {
   container: {
      padding: 5,
      margin: '10px',
   },
}

export default compose(withRouter, withAuthentication)(InspectionCreatePage)
