import React from 'react'
import { pageKey } from '../Helpers'

export default class SearchableList extends React.Component {
   constructor(props) {
      super(props)
      this.storeKey = `${pageKey()}_filters`
   }

   /**
    * Set or get page shared components filters
    */
   filters = (filters = null) => {
      if (filters) {
         console.log(`Setting new filters for ${this.storeKey}`, filters)
         sessionStorage.setItem(this.storeKey, JSON.stringify(filters))
         this.setState({ filters })
      } else {
         return sessionStorage.getItem(this.storeKey)
            ? JSON.parse(sessionStorage.getItem(this.storeKey))
            : undefined
      }
   }
}
