import React from 'react'
import labels from './translations'
import { labelHelper, pageKey } from '../Helpers'
import { Header, Message, Divider } from 'semantic-ui-react'
import { withAuthentication } from '../Session'

/**
 * Generated an automatic header based on current path and user's lang
 */
class AutoHeader extends React.Component {
   constructor(props) {
      super(props)
      this.props = props
      this.state = { dismissed: false }
      this.lb = new labelHelper(
         labels,
         this.props.authUser ? this.props.authUser.lang : labelHelper.lang,
      )
      this.path = pageKey()
   }

   render() {
      const key = this.props.labelKey || this.path.join('_')
      return (
         <div>
            <Header as="h1">
               {this.lb._(`screens.${key}`, this.props.params)}
            </Header>
            {labels.screens_info[key] &&
               this.props.info !== false &&
               this.state.dismissed !== true && (
                  <div>
                     <Message
                        icon="info circle"
                        list={this.lb._(`screens_info.${key}`).split('\n')}
                        color="blue"
                        onDismiss={() => this.setState({ dismissed: true })}
                     />
                     <Divider horizontal />
                  </div>
               )}
         </div>
      )
   }
}

export default withAuthentication(AutoHeader)
