import Api from '../../Api'
import * as ROUTES from '../../../constants/routes'
import FormComponent from '../../Form'
import statusValues from '../../../models/vecto-status'
import labels from '../../Common/translations'

export default {
   fields: {
      base: {
         type: FormComponent.GROUP,
         fields: {
            mode: {
               label: labels.fields.vectorization_mode,
               values: [
                  {
                     key: 'fast',
                     label: labels.fields.fast_mode,
                  },
                  {
                     key: 'accurate.video',
                     label: labels.fields.accurate_mode_video,
                  },
                  {
                     key: 'accurate.image',
                     label: labels.fields.accurate_mode_image,
                  },
               ],
               options: {
                  defaultValue: 'accurate',
               },
               type: FormComponent.SELECT,
            },
            status: {
               label: labels.fields.status,
               type: FormComponent.SELECT,
               values: statusValues,
            },
         },
      },
      parent: {
         type: FormComponent.GROUP,
         fields: {
            video: {
               label: labels.fields.video,
               return: 'object',
               options: {
                  href: ({ data }) =>
                     `/video360/show/${data.video.ref.split('/').pop()}`,
               },
            },
            company: {
               label: labels.fields.company,
               return: 'object',
            },
            createdBy: {
               label: labels.fields.created_by,
            },
         },
      },
      offsets: {
         type: FormComponent.GROUP,
         fields: {
            framerate: {
               label: labels.fields.framerate,
               type: FormComponent.NUMBER,
               options: {
                  label: { basic: true, content: 'fps' },
                  labelPosition: 'right',
               },
            },
            offsetStart: {
               label: labels.fields.start_offset,
               type: FormComponent.NUMBER,
               options: {
                  label: { basic: true, content: 'sec' },
                  labelPosition: 'right',
                  defaultValue: '0',
               },
            },
            duration: {
               label: labels.fields.length,
               mandatory: true,
               type: FormComponent.NUMBER,
               options: {
                  label: { basic: true, content: 'sec' },
                  labelPosition: 'right',
               },
            },
            offsetEnd: {
               label: labels.fields.end_offset,
               type: FormComponent.NUMBER,
               options: {
                  label: { basic: true, content: 'sec' },
                  labelPosition: 'right',
               },
            },
         },
      },
      timestamps: {
         type: FormComponent.GROUP,
         fields: {
            createdAt: {
               type: FormComponent.DATE,
               label: labels.fields.created_at,
            },
            updatedAt: {
               type: FormComponent.DATE,
               label: labels.fields.updated_at,
            },
         },
      },
   },
   buttons: {
      extras: [],
   },
   actions: {
      read: uid => Api.instance().get(uid),
      redirect: ROUTES.SCENES,
   },
   extras: [],
}
