import Api from '../../../Api'
import labels from '../../../Common/translations'
import FormComponent from '../../../Form'

export default {
   fields: {
      company: {
         label: labels.fields.company,
         type: FormComponent.SELECT,
         mandatory: true,
         hidden: true,
         values: {
            from: 'state',
            prop: 'companyValues',
            collection: 'companies',
            source: () =>
               Api.selectValues('companies', {
                  fsort: 'name',
               }),
         },
         return: FormComponent.FORMAT_OBJECT,
         options: {
            defaultValue: ({ company }) =>
               company.ref
                  ? company
                  : {
                       ref: `companies/${company.uid}`,
                       label: company.name,
                    },
         },
      },
      name: {
         label: labels.fields.name,
         mandatory: true,
      },
      comment: {
         label: labels.fields.comment,
      },
   },
   buttons: {
      delete: false,
      submit: true,
      cancel: true,
      extras: [],
   },
   actions: {
      update: ({ uid, ...data }) =>
         Api.instance().patch(`${data.company.ref}/groups/${uid}`, data),
      read: (pid, uid) => Api.instance().get(`${pid}/groups/${uid}`),
      //redirect: ROUTES.COMPANY,
   },
   extras: [],
}
