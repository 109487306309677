import FormComponent from '../../Form'
import Api from '../../Api'
import TextEditor from '../../Form/fields/textEditor'
import labels from '../../Common/translations'

export default {
   fields: {
      name: {
         label: labels.fields.name,
         mandatory: true,
      },
      type: {
         label: labels.fields.type,
         type: FormComponent.SELECT,
         values: [
            {
               key: 'email',
               label: labels.fields.email_model,
            },
         ],
      },
      action: {
         label: labels.fields.action,
         type: FormComponent.SELECT,
         values: {
            from: 'state',
            prop: 'actionValues',
            collection: 'templatesActions',
            source: () => Api.selectValues('templatesActions', {}),
         },
         return: FormComponent.FORMAT_DEFAULT,
         mandatory: true,
      },
      subject: {
         type: FormComponent.TEXTEDITOR,
         label: labels.fields.subject,
         options: {
            languages: {
               fr: labels.fields.fr,
               en: labels.fields.en,
               es: labels.fields.es,
            },
            toolbar: TextEditor.TOOLBAR_MINIMAL,
         },
      },
      content: {
         type: FormComponent.TEXTEDITOR,
         label: labels.fields.content,
         options: {
            languages: {
               fr: labels.fields.fr,
               en: labels.fields.en,
               es: labels.fields.es,
            },
            toolbar: TextEditor.TOOLBAR_MEDIUM,
         },
      },
   },
   buttons: {
      cancel: true,
      submit: true,
      delete: true,
      extras: [],
   },
   actions: {
      create: data => Api.instance().post('templates', data),
      read: uid => Api.instance().get(`templates/${uid}`),
      update: ({ uid, ...data }) =>
         Api.instance().patch(`templates/${uid}`, data),
      delete: ({ uid }) => Api.instance().delete(`templates/${uid}`),
   },
   extras: [],
}
