import React from 'react'
import { AutoLabel } from '.'
import TableComponent from '../Table'
import { dateFormat, labelFormat } from '../Helpers'
import { DateTime } from 'luxon'
import Api from '../Api'
import eventStatus from '../../models/event-status'

const defaultEventsList = {
   pagesize: 30,
   defaultSorting: { field: 'createdAt', order: 'desc' },
   columns: {
      createdAt: {
         label: {
            fr: 'Date',
            en: 'Date',
         },
         accessor: ({ createdAt }) =>
            dateFormat(createdAt, DateTime.DATETIME_MED_WITH_SECONDS),
      },
      type: {
         hidden: props => props.hideObjectColumn,
         label: {
            fr: `Type`,
            en: 'Type',
         },
      },
      object: {
         hidden: props => props.hideObjectColumn,
         label: {
            fr: 'Objet',
            en: 'Object',
         },
         accessor: ({ object }) => object.label,
      },
      event: {
         label: {
            fr: `Evénement`,
            en: 'Event',
         },
         accessor: s => labelFormat(s.event, eventStatus),
      },
      user: {
         label: {
            fr: 'Utilisateur',
            en: 'User',
         },
         accessor: ({ user }) => user.label,
         hidden: props => props.hideUserColumn,
      },
      company: {
         label: {
            fr: 'Société',
            en: 'Company',
         },
         accessor: ({ company }) => company.label,
      },
      args: {
         label: {
            fr: 'Données complémentaires',
            en: 'Supplemental Data',
         },
         accessor: ({ args }) => args && JSON.parse(args),
      },
   },
   actions: {
      list: (params, { condition }) => {
         if (condition) {
            params.q = condition
         }
         return Api.instance().get('events', params)
      },
   },
}
export default class EventsList extends React.Component {
   render() {
      return (
         <TableComponent
            title={<AutoLabel labelKey={this.props.labelKey} />}
            list={this.props.eventsList || defaultEventsList}
            {...this.props}
         />
      )
   }
}
