import Api from '../../Api'
import * as ROUTES from '../../../constants/routes'
import labels from '../../Common/translations'

export default {
   fields: {
      newname: {
         label: labels.fields.new_name,
         mandatory: true,
      },
   },
   buttons: {
      submit: true,
      delete: false,
      cancel: true,
      extras: [],
   },
   actions: {
      read: uid => Api.instance().get(uid),
      update: ({ uid, ...data }) => Api.instance().patch(uid, data),
      redirect: ROUTES.SCENES,
   },
   extras: [],
}
