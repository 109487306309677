import Api from '../../Api'
import { dateFormat } from '../../Helpers'
import labels from '../../Common/translations'

export default {
   endpoint: 'users',
   pagesize: 10,
   defaultSorting: { field: 'email', order: 'asc' },
   columns: {
      email: {
         label: labels.fields.email,
         accessor: s => `${s.email}`,
      },
      company: {
         label: labels.fields.company,
         accessor: s => `${s.company ? s.company.label : ''}`,
         hidden: ({ authUser }) => authUser.role !== 'root',
      },
      createdAt: {
         label: labels.fields.invitation_date,
         format: row => dateFormat(row.createdAt),
      },
      role: {
         label: labels.fields.role,
         values: {
            source: () => Api.selectValues('roles', { readOnly: true }),
         },
      },
   },
   buttons: {
      reinvite: {
         label: labels.fields.resend_mail,
         options: {
            icon: 'mail',
            warning: true,
         },
         action: (data, { firebase }) => {
            firebase.auth
               .sendSignInLinkToEmail(data.email, {
                  url: `${window.location.origin}/onboarding/${data.uid}-${data.onboardingKey}`,
                  handleCodeInApp: true,
               })
               .then(() => alert('Invite email was successfully resent'))
               .catch(err =>
                  alert(`Invitation email resent failed: ${err.message}`)
               )
         },
      },
      delete: {
         label: labels.fields.user_delete,
         options: {
            icon: 'delete',
            negative: true,
         },
         action: ({ uid }) => {
            Api.instance()
               .delete(`users/${uid}`)
               .then(() => {
                  window.location.reload()
               })
               .catch(err =>
                  alert(`Unable to delete invited user: ${err.message}`)
               )
         },
      },
   },
   actions: {
      list: (params = { q: {} }) => {
         params.q.status = ['pending']
         return Api.instance().get('users', params)
      },
   },
}
