import React from 'react'
import { Message } from 'semantic-ui-react'
import { labelHelper } from '../Helpers'
import labels from './translations'

export default class InfoMessage extends React.Component {
   constructor(props) {
      super(props)
      this.props = props
      this.lb = new labelHelper(
         labels,
         this.props.authUser ? this.props.authUser.lang : labelHelper.lang
      )
   }

   render() {
      if (Array.isArray(this.props.content)) {
         const [header, ...list] = this.props.content
         return (
            <Message positive icon="info circle" header={header} list={list} />
         )
      } else {
         return (
            <Message
               positive
               icon="info circle"
               header={this.props.title || this.lb._('Information')}
               content={this.props.content}
            />
         )
      }
   }
}
