export default [
   {
      key: 'users',
      label: {
         fr: 'Sièges Utilisateur',
         en: 'User Seats',
      },
   },
   {
      key: 'maxActive',
      label: {
         fr: 'Sièges Actifs',
         en: 'Active Seats',
      },
   },
   {
      key: 'minutes',
      label: {
         fr: 'Minutes de vidéo',
         en: 'Minutes of Video',
      },
   },
]
